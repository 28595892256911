<template>
	<div class="task-index__comments-label">
		<h3 class="task-index__comments-name">Комментарии</h3>
		<span class="task-index__comments-number" v-if="comments && comments.length > 0">{{comments.length}}</span>
	</div>
</template>

<script>
  export default {
    name: "TaskCommentsTitle",
	  props: {
      comments: Array
	  }
  }
</script>

<style>

</style>