const SpentService = {
  spent(seconds) {
    let hours = 0;
    let minutes = 0;

    if (!seconds) {
      return '';
    }

    hours = Math.floor(seconds / (60 * 60));
    minutes = Math.ceil((seconds - hours * 3600) / 60);

    const info = [];
    if (hours) {
      info.push(`${hours} час.`);
    }
    if (minutes) {
      if (minutes < 10 && hours) {
        minutes = "0"+minutes;
      }
      info.push(`${minutes} мин.`);
    }
    return info.join(' ');
  },
  fullHoursSpent(seconds) {
    if (!seconds) {
      return '';
    }

    return (seconds / 60 / 60).toFixed(2);
  },
};

export default SpentService;