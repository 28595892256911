<template>
    <div class="time-project" :class="{'time-project': opened}" v-if="project">
        <div class="time-project__container" @click="opened = !opened">
            <div class="time-project__info">
                <div class="time-project__name">
                    {{ project.name }}
                </div>
            </div>

            <div class="time-project__container-right">
              <a href="javascript:void(0)" class="time-project__download" @click="downloadCsv">
                ⬇ CSV
              </a>
              <div class="time-project__spent">
                  {{ spent }}
              </div>
            </div>
        </div>

        <div class="time-project__workers" v-if="info && info.by_workers && opened">
            <TimeProjectWorker
                    v-for="workerInfo in info.by_workers"
                    :key="workerInfo.worker_id"
                    :info="workerInfo"
                    :opened="opened"
                    :worker-id="workerInfo.worker_id"
                    :spent="workerInfo.spent"/>
        </div>
    </div>
</template>

<script>
  import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';

  import SpentService from '../../../services/spent.service';
  import TimeProjectWorker from './TimeProjectWorker.vue';

  export default {
    name: "TimeProject",
    components: {
      TimeProjectWorker,
    },
    props: {
      info: Object
    },
    data() {
      return {
        opened: false,
      }
    },
    computed: {
      ...mapGetters({
        getProjectById: 'project/getProjectById',
        getWorkerById: 'worker/getWorkerById'
      }),
      project() {
        return this.info.project_id ? this.getProjectById(this.info.project_id) : null;
      },
      spent() {
        if (this.info && this.info.spent) {
            return SpentService.spent(this.info.spent);
        }
        return '';
      },
    },
    methods: {
      downloadCsv() {
        console.log(this.info);
        const lines = [];

        lines.push(`"${this.project.name}", Общее время, "${SpentService.fullHoursSpent(this.info.spent).toString().replace('.', ',')}"`);

        this.info.by_workers.forEach((workerInfo) => {
          const worker = this.getWorkerById(workerInfo.worker_id);
          lines.push(`"${worker.name}", Общее время, "${SpentService.fullHoursSpent(workerInfo.spent).toString().replace('.', ',')}"`);

          workerInfo.by_tasks.forEach((task) => {
            lines.push(`, "${task.name}", "${SpentService.fullHoursSpent(task.spent).toString().replace('.', ',')}"`);
          });
        });

        const link = document.createElement("a");
        link.href = 'data:text/csv,' + lines.join("\n");
        link.download = this.project.name + '.csv';
        link.click();
      },
    }
  }
</script>

<style>

</style>