<template>
  <div class="task-options-workers-search">
    <input type="text"
           placeholder="Найти участника..."
           class="task-options-workers-search__input"
           ref="searchInput"
           @input="$emit('input', $event.target.value)"
    >
  </div>
</template>

<script>
  export default {
    name: "TaskOptionsWorkersSearch",
    props: {
      autofocus: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        value: ''
      }
    },
    methods: {

    },
    mounted() {
      if (this.autofocus) {
        this.$refs.searchInput.focus();
      }
    }
  }
</script>

<style lang="scss">
  .task-options-workers-search {
    width: 100%;
    padding: 0 24px;

    &__input {
      width: 100%;
      border: none;
      border-bottom: 1px solid #EAECF2;
      padding: 10px 27px;
      color: #6C707B;
      font-size: 15px;
    }
  }
</style>