<template>
  <svg width="11px" height="9px" viewBox="0 0 11 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
    <title>Line</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Все-задачи-Copy-7" transform="translate(-1221.000000, -388.000000)" fill-rule="nonzero" stroke="#FF234C" stroke-width="2">
        <g id="Group-21" transform="translate(975.000000, 240.000000)">
          <g id="Group-10" transform="translate(0.000000, 103.000000)">
            <polyline id="Line" points="247 49 250.520199 52 256 46"></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IconCheckRed"
  }
</script>

<style>

</style>