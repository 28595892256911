<template>
  <AppLayout>
    <div class="kanban-layout">
      <div class="kanban-layout__head">
        <div class="kanban-layout__title">
          <a href="#" class="kanban-layout__opener"
               @click.prevent="handleOpener"
               v-if="!sidebarIsOpen"
          >
            <IconArrowGrey/>
          </a>
          <slot name="title"/>
        </div>
        <div class="kanban-layout__options">
          <slot name="options"/>
        </div>
      </div>
      <div class="kanban-layout__subhead">
        <div class="kanban-layout__tabs">
          <slot name="tabs"/>
        </div>
        <div class="kanban-layout__filters">
          <slot name="filters"/>
        </div>
      </div>
      <div class="kanban-layout__kanban">
        <slot name="kanban"/>
      </div>
      <div class="kanban-layout__loading">
        <slot name="loading">
          <Loading :isLoading="loading"></Loading>
        </slot>
      </div>
      <div class="kanban-layout__modal">
        <slot name="modal"/>
      </div>
    </div>
  </AppLayout>
</template>

<script>
  import AppLayout from "./AppLayout.vue";
  import IconArrowGrey from "../icons/IconArrowGrey.vue";
  import Loading from "../components/ui/Loading.vue";

  import { mapState, mapMutations } from "vuex";

  export default {
    name: "KanbanLayout",
    components: {
      IconArrowGrey,
      AppLayout,
      Loading,
    },
    computed: {
      ...mapState([
        'sidebarIsOpen',
        'loading'
      ]),
    },
    methods: {
      ...mapMutations({
        openSidebar: 'openSidebar'
      }),
      handleOpener() {
        this.openSidebar();
      }
    },
  }
</script>

<style lang="scss">

</style>