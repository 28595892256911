import ApiService from './api.service';
import { AuthenticationError } from './auth.service';

const UserService = {
  async getUser() {
    const requestData = {
      method: 'get',
      url: '/api/me',
    };
    try {
      return ApiService.request(requestData);
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.details)
    }
  },

  storeSettings(settings) {
    window.localStorage.setItem('USER_SETTINGS', JSON.stringify(settings));
  },

  getSettings() {
    const rawSettings = window.localStorage.getItem('USER_SETTINGS');
    let settings = {};
    if (rawSettings) {
      settings = JSON.parse(rawSettings);
    }
    return {
      showAllColumns: settings.showAllColumns ? settings.showAllColumns : false
    };
  },
};

export default UserService;