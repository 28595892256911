<template>
  <KanbanLayout>
    <h2 slot="title">
      Чеклист
    </h2>

    <Tabs
      slot="tabs"
    />

    <div slot="kanban">
        <TheMyToDoList/>
    </div>
  </KanbanLayout>
</template>

<script>
  import KanbanLayout from "../layouts/KanbanLayout.vue";
  import Tabs from "../components/app/index/Tabs.vue";
  import TheMyToDoList from '../components/app/todoes/TheMyToDoList.vue';
  export default {
    name: "TheMyToDoes",
    components: {
      TheMyToDoList,
      KanbanLayout,
      Tabs,
    },
  }
</script>

<style>

</style>