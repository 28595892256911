<template>
    <div class="log-item">
        <div class="log-item__avatar" v-if="worker">
            <TheAvatar :worker="worker"/>
        </div>
        <div class="log-item__data">
            <div class="log-item__description">
                <span class="log-item__worker-name" v-if="worker">
                    {{worker.name}}
                </span>
                <span class="log-item__text">
                    {{text}}
                </span>
                <router-link class="log-item__entity" :to="entityRoute" target="_blank">
                    {{entity}}
                </router-link>
                <span class="log-item__from-to" v-if="fromTo">
                    {{fromTo}}
                </span>
            </div>
            <div class="log-item__time">
                {{event.time}}
            </div>
            <div class="log-item__bubble" v-if="bubble">
                {{bubble}}
            </div>
            <div class="log-item__file" v-if="file">
                <File :name="file.name" :path="file.url" :size="file.size"/>
            </div>
        </div>
    </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import TheAvatar from "../ui/TheAvatar.vue";
  import File from "../ui/File.vue";

  export default {
    name: "LogItem",
    components: {File, TheAvatar},
    props: {
      event: {
        type: Object,
        required: true
      },
    },
    computed: {
      ...mapGetters({
        getWorkerById: 'worker/getWorkerById',
        getProjectById: 'project/getProjectById',
        getStatusName: 'project/getStatusName',
        getText: 'log/text'
      }),
      worker() {
        if (this.event.worker_id) {
          return this.getWorkerById(this.event.worker_id);
        }
        return null;
      },
      text() {
        let subType = 0;
        if (this.event.type === 10 && !this.event.status_from) {
          subType = 1;
        }
        if (this.event.type === 30 && !this.event.performer_id_from) {
          subType = 1;
        }
        if (this.event.type === 60 && !this.event.project_id_from) {
          subType = 1;
        }
        return this.getText(this.event.type, subType)
      },
      entity() {
        return this.event.task.name;
      },
      entityRoute() {
        return {name: 'task', params: {taskId: this.event.task_id}};
      },
      bubble() {
        if (this.event.data && this.event.data.text) {
          return this.event.data.text;
        }
        return null;
      },
      file() {
        if (this.event.type === 50) {
          return this.event.data;
        }
        return null;
      },
      fromTo() {
        let from = '';
        let to = '';

        if (this.event.type === 10) {
          from = this.getStatusNameVerb(this.event.data.status_from);
          to = this.getStatusNameVerb(this.event.data.status_to);
        }

        if (this.event.type === 30) {
          from = this.getWorkerName(this.event.data.performer_id_from);
          to = this.getWorkerName(this.event.data.performer_id_to);
        }

        if (this.event.type === 60) {
          from = this.getProjectName(this.event.data.project_id_from);
          to = this.getProjectName(this.event.data.project_id_to);
        }

        if (from) {
          return `${from} > ${to}`;
        }
        return to;
      }
    },
    mounted() {

    },
    methods: {
      getWorkerName(id) {
        if (!id) {
          return '';
        }
        const worker = this.getWorkerById(id);
        return worker ? worker.name : '';
      },
      getProjectName(id) {
        if (!id) {
          return '';
        }
        const project = this.getProjectById(id);
        return project ? project.name : '';
      },
      getStatusNameVerb(id) {
        if (!id) {
          return '';
        }
        return this.getStatusName(id)
      },
    }
  }
</script>

<style>

</style>