import ApiService from './api.service';

const ChecklistTemplateService = {
  async all() {
    const requestData = {
      url: '/api/checklist/template/all',
      method: 'get',
    };
    return ApiService.request(requestData);
  },
  async get(id) {
    const requestData = {
      url: `/api/checklist/template/${id}`,
      method: 'get',
    };
    return ApiService.request(requestData);
  },
  async create({ checklist, items }) {
    const requestData = {
      url: '/api/checklist/template/create',
      method: 'post',
      data: {
        checklist,
        items,
      },
    };
    return ApiService.request(requestData);
  },
  async update(id, { checklist, items }) {
    const requestData = {
      url: `/api/checklist/template/update/${id}`,
      method: 'put',
      data: {
        checklist,
        items,
      },
    };
    return ApiService.request(requestData);
  },
  async delete(id) {
    const requestData = {
      url: `/api/checklist/template/delete/${id}`,
      method: 'delete',
    };
    return ApiService.request(requestData);
  },
};

export default ChecklistTemplateService;