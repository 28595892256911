<template>
  <div class="credentials">
    <div class="credentials__heading">
      Доступы
    </div>

    <a href="javascript:void(0);" class="credentials__add" @click.prevent="showAdd">
        <span class="credentials__add-icon">
          <img src="/static/images/svg/white-plus.svg" alt="">
        </span>
      <span class="credentials__add-title">Добавить</span>
    </a>

    <ul class="credentials__list" :class="{_adding: isAdding || isEditing }">
      <li class="credentials__item" :id="item.id" v-for="item in credentials">
        <div class="credentials__item-heading">
          <div class="credentials__item-name">
            {{ item.name }}
          </div>

          <div class="credentials__item-buttons">
            <a href="javascript:void(0);"
               class="credentials__edit"
               @click.stop.prevent="edit(item)"
            >
              <span class="credentials__edit-icon">
                <img src="/static/images/svg/pen.svg" alt="">
              </span>
            </a>

            <a href="javascript:void(0);"
               class="credentials__remove"
               @click.stop.prevent="remove(item.id)"
            >
              <span class="credentials__remove-icon">
                <img src="/static/images/svg/plus.svg" alt="">
              </span>
            </a>
          </div>
        </div>

        <div class="credentials__item-main">
          <div class="credentials__item-value credentials__host"
               v-show="item.host"
               @click="copy(item.host, $event)"
               style="z-index: 1"
          >
            Host: {{ item.host }}
            <div class="credentials__item-value-notice-done"></div>
            <div class="credentials__item-value-notice-error"></div>
          </div>

          <div class="credentials__item-value credentials__url"
               v-show="item.url"
               @click="copy(item.url, $event)"
               style="z-index: 2"
          >
            Ссылка: {{ item.url }}
            <div class="credentials__item-value-notice-done"></div>
            <div class="credentials__item-value-notice-error"></div>
          </div>

          <div class="credentials__item-value credentials__login"
               v-show="item.login"
               @click="copy(item.login, $event)"
               style="z-index: 3"
          >
            Логин: {{ item.login }}
            <div class="credentials__item-value-notice-done"></div>
            <div class="credentials__item-value-notice-error"></div>
          </div>

          <div class="credentials__item-value credentials__password"
               v-show="item.password"
               @click="copy(item.password, $event)"
               style="z-index: 4"
          >
            Пароль: {{ item.password }}
            <div class="credentials__item-value-notice-done"></div>
            <div class="credentials__item-value-notice-error"></div>
          </div>

          <div class="credentials__item-value credentials__text"
               v-show="item.text"
               @click="copy(item.text, $event)"
               style="z-index: 5"
          >
            Описание: {{ item.text }}
            <div class="credentials__item-value-notice-done"></div>
            <div class="credentials__item-value-notice-error"></div>
          </div>
        </div>
      </li>

      <div class="credentials__create-form" v-if="isAdding || isEditing">
        <div class="credentials__create-area-wrapper">
          <textarea-autosize
              :value="field.value"
              :placeholder="field.title"
              @input.native="field.method"
              autofocus
              :rows="field.rows"
              class="credentials__create-area-item"
              :class="[`credentials__create-form-${field.type}`, {_errors: errors[field.type] && errors[field.type].length}]"
              :id="`credential-${field.type}`"
              v-for="field in formCredentialFields"
          />
        </div>

        <div class="credentials__create-form-footer">
          <a href="javascript:void(0);"
             class="credentials__create-form-cancel"
             @click.prevent="cancelAdd"
          >
            <img src="/static/images/svg/cross-dark-grey.svg" alt="">
          </a>

          <a href="javascript:void(0);" class="credentials__create-form-submit" @click="save">
            <span class="credentials__create-form-submit-title">
              СОХРАНИТЬ
            </span>
          </a>
        </div>
      </div>

    </ul>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ProjectCredentials',
  props: {
    project: Object
  },
  data() {
    return {
      isAdding: false,
      isEditing: false,
      editingCredentialId: null,
      formCredentialFields: {
        name: {
          title: 'Название',
          value: '',
          method: this.onInputName,
          rows: 1,
          type: 'name'
        },
        host: {
          title: 'Host',
          value: '',
          method: this.onInputHost,
          rows: 1,
          type: 'host'
        },
        url: {
          title: 'Ссылка',
          value: '',
          method: this.onInputUrl,
          rows: 1,
          type: 'url'
        },
        login: {
          title: 'Логин',
          value: '',
          method: this.onInputLogin,
          rows: 1,
          type: 'login'
        },
        password: {
          title: 'Пароль',
          value: '',
          method: this.onInputPassword,
          rows: 1,
          type: 'password'
        },
        text: {
          title: 'Описание',
          value: '',
          method: this.onInputText,
          rows: 3,
          type: 'text'
        },
      },
      errors: {
        name: [],
      }
    }
  },
  computed: {
    credentials() {
      return this.project.credentials;
    },
    formatedFormCredentialFields() {
      return Object.fromEntries(Object.values(this.formCredentialFields).map(field => [field.type, field.value]));
    },
  },
  methods: {
    ...mapActions('project', [
        'createCredential',
        'updateCredential',
        'deleteCredential'
    ]),
    onInputName(e) {
      this.formCredentialFields.name.value = e.target.value;
    },
    onInputHost(e) {
      this.formCredentialFields.host.value = e.target.value;
    },
    onInputUrl(e) {
      this.formCredentialFields.url.value = e.target.value;
    },
    onInputLogin(e) {
      this.formCredentialFields.login.value = e.target.value;
    },
    onInputPassword(e) {
      this.formCredentialFields.password.value = e.target.value;
    },
    onInputText(e) {
      this.formCredentialFields.text.value = e.target.value;
    },
    showAdd() {
      this.isAdding = true;
    },
    cancelAdd() {
      this.isAdding = false;
      this.isEditing = false;
      this.clearFormCredentialFields();
      this.clearErrors();
    },
    save() {
      if (!this.formCredentialFields.name.value) {
        this.errors.name = ['Обязательно для заполнения'];
        return;
      }
      if (this.isAdding) {
        this.create();
      } else if (this.isEditing) {
        this.update();
      }
      this.cancelAdd();
      this.clearFormCredentialFields();
      this.clearErrors();
    },
    create() {
      this.createCredential({
        projectId: this.project.id,
        credential: this.formatedFormCredentialFields,
      });
    },
    update() {
      this.updateCredential({
        projectId: this.project.id,
        credential: Object.assign({id: this.editingCredentialId}, this.formatedFormCredentialFields),
      });
    },
    edit(credential) {
      this.editingCredentialId = credential.id;
      this.fillFormCredentialFields(credential);
      this.isEditing = true;
    },
    remove(id) {
      this.deleteCredential({projectId: this.project.id, credentialId: id});
    },
    fillFormCredentialFields(credential) {
      this.formCredentialFields.name.value = credential.name;
      this.formCredentialFields.host.value = credential.host;
      this.formCredentialFields.url.value = credential.url;
      this.formCredentialFields.login.value = credential.login;
      this.formCredentialFields.password.value = credential.password;
      this.formCredentialFields.text.value = credential.text;
    },
    clearFormCredentialFields() {
      Object.values(this.formCredentialFields).forEach(field => field.value = '');
      this.editingCredentialId = null;
    },
    clearErrors() {
      this.errors = [];
    },
    copy(text, event) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text)
            .then(() => {
              event.target.classList.add('_copied');
              console.log('Text copied to clipboard');

              setTimeout(() => {
                event.target.classList.remove('_copied');
              }, 1000);
            })
            .catch(err => {
              event.target.classList.add('_copy-error');
              console.error('Error in copying text: ', err);
              setTimeout(() => {
                event.target.classList.remove('_copy-error');
              }, 1000);
            });
      } else {
        console.log('"navigator.clipboard" requires a secure origin — either HTTPS or localhost!');
        event.target.classList.add('_copy-error');
        setTimeout(() => {
          event.target.classList.remove('_copy-error');
        }, 1000);
      }
    }
  }
};
</script>

<style lang="scss">

</style>