<template>
  <svg width="36px" height="40px" viewBox="0 0 36 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
    <title>Group 20</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Artboard" transform="translate(-114.000000, -85.000000)">
        <g id="Group-20" transform="translate(114.000000, 85.000000)">
          <path d="M0.5,0.5 L0.5,39.5 L35.5,39.5 L35.5,8.67740291 L27.4130879,0.5 L0.5,0.5 Z" id="Rectangle-Copy" stroke="#EAECF2" fill="#FFFFFF"></path>
          <path d="M12.5161133,22.8847656 L9.47607422,22.8847656 L9.47607422,26 L7.59082031,26 L7.59082031,18.1796875 L12.8222656,18.1796875 L12.8222656,19.6352539 L9.47607422,19.6352539 L9.47607422,21.4345703 L12.5161133,21.4345703 L12.5161133,22.8847656 Z M15.6152344,26 L13.7353516,26 L13.7353516,18.1796875 L15.6152344,18.1796875 L15.6152344,26 Z M18.8110352,24.5498047 L22.0927734,24.5498047 L22.0927734,26 L16.9257812,26 L16.9257812,18.1796875 L18.8110352,18.1796875 L18.8110352,24.5498047 Z M27.737793,22.6860352 L24.7729492,22.6860352 L24.7729492,24.5498047 L28.2749023,24.5498047 L28.2749023,26 L22.8876953,26 L22.8876953,18.1796875 L28.2856445,18.1796875 L28.2856445,19.6352539 L24.7729492,19.6352539 L24.7729492,21.2841797 L27.737793,21.2841797 L27.737793,22.6860352 Z" id="FILE" fill="#C1C4D3" fill-rule="nonzero"></path>
          <polygon id="Rectangle" fill="#EAECF2" points="27 0 36 9 27 9"></polygon>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IconFile"
  }
</script>

<style>

</style>