<template>
    <div class="timeline-worker__day timeline-days__day" :data-day="day.name">
        <div class="timeline-worker__hours timeline-days__hours">
            <div class="timeline-days__hour timeline-head__hour" v-for="(hour, key) in day.hours" :key="key"></div>
        </div>
        <TimelineWorkerDayTask v-for="(dayTask, key) in dayTasks" :key="key" :task="dayTask.task" :offset="dayTask.offset" :width="dayTask.width"/>
    </div>
</template>

<script>
  import TimelineWorkerDayTask from './TimelineWorkerDayTask.vue';

  export default {
    name: "TimelineWorkerDay",
    components: {
      TimelineWorkerDayTask,
    },
    props: {
      day: Object,
      tasks: Array,
    },
    methods: {
      calcInHours(milliseconds) {
        const hoursRaw = milliseconds / (1000 * 60 * 60);
        return Math.round(hoursRaw * 10) / 10;
      }
    },
    computed: {
      dayTasks() {
        const tasks = [];
        this.tasks.forEach((task) => {
          if (task.start_time >= this.day.start_time && task.start_time <= this.day.end_time) {
            const startOfTheDay = new Date(this.day.start_time);
            const startOfTask = new Date(task.start_time);

            const startOffset = this.calcInHours(startOfTask.getTime() - startOfTheDay.getTime());
            const width = Math.round(task.task.estimate / 6) / 10;

            tasks.push({
              task: task,
              offset: startOffset,
              width: width
            })
          }
        });
        console.log(tasks);
        return tasks;
      }
    },
  }
</script>

<style>

</style>