<template>
  <div>
    <ul class="task-index__attachments-list images-list" v-if="images.length > 0">
      <TaskImagesItem v-for="image in images" :key="image.id"
                      :file="image"
      />
    </ul>
    <ul class="task-index__files-list files-list" v-if="docs.length>0">
      <TaskDocsItem v-for="doc in docs" :key="doc.id"
                    :file="doc"
      />
    </ul>
  </div>
</template>

<script>
  import TaskImagesItem from "./TaskImagesItem.vue";
  import TaskDocsItem from "./TaskDocsItem.vue";
  import { mapGetters } from "vuex";

  export default {
    name: "TaskFilesList",
    components: {TaskDocsItem, TaskImagesItem},

    computed: {
      ...mapGetters('task', [
        'docs',
        'images',
      ]),
    },
  }
</script>

<style lang="scss">
  .files-list {
    position: relative;
  }
</style>