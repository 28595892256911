<template>
  <KanbanLayout>
    <h2 slot="title">
      {{project.name}}
    </h2>

    <ProjectTabs
      :id="project.id"
      active="project"
      slot="tabs"
    />

    <!-- Proxy slots -->
    <slot slot="options" name="options"></slot>
    <slot slot="filters" name="filters"></slot>
    <slot slot="kanban" name="kanban"></slot>
    <slot slot="modal" name="modal"></slot>
  </KanbanLayout>
</template>

<script>
  import ProjectTabs from '../components/app/project/ProjectTabs.vue';
  import KanbanLayout from "../layouts/KanbanLayout.vue";
  import ProjectContainer from "../containers/ProjectContainer.vue";

  export default {
    name: "ProjectLayout",
    components: {
      ProjectContainer,
      ProjectTabs,
      KanbanLayout
    },
    props: {
      project: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style>

</style>