<template>
    <div class="TheFilter">
      <div class="TheFilter__time TheFilter__check">
        <input type="checkbox" id="showArchive" v-model="isTimeEstimateModel" @change="changeIsTimeEstimate">
        <label for="showArchive" class="TheFilter__label">
          Оценка времени
        </label>
      </div>
        <div class="TheFilter__archive TheFilter__check">
            <input type="checkbox" id="showArchive" v-model="isArchiveModel" @change="changeIsArchive">
            <label for="showArchive" class="TheFilter__label">
                Архив
            </label>
        </div>
    </div>
</template>

<script>
  import { mapState, mapGetters, mapActions } from "vuex";

  export default {
    data() {
      return {
        isArchiveModel: false,
        isTimeEstimateModel: false,
      }
    },
    name: "ArchiveFilter",
    computed: {
      ...mapState({
        currentProject: 'project/currentProject'
      }),
      ...mapGetters({
        filters: 'filter/filters',
      })
    },
    methods: {
      ...mapActions({
        setIsArchive: 'filter/setIsArchive',
        setIsTimeEstimate: 'filter/setIsTimeEstimate',
        fetchTasks: 'project/fetchTasks',
      }),
      changeIsArchive() {
        this.setIsArchive(this.isArchiveModel);
        this.fetchTasks({id: this.$route.params.projectId, filters: this.filters})
      },
      changeIsTimeEstimate() {
        this.setIsTimeEstimate(this.isTimeEstimateModel);
        this.fetchTasks({id: this.$route.params.projectId, filters: this.filters})
      },
    },
    mounted() {
      this.isArchiveModel = this.filters.archive;
    }
  }
</script>

<style lang="scss">

</style>