<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "TasksContainer",
    computed: {
      ...mapGetters('project', [
        'workerTasks'
      ]),
      ...mapGetters('filter', [
        'filters'
      ])
    },
    props: {
      projectId: {
        type: Number,
        required: false
      },
      type: {
        type: String,
        required: false
      }
    },
    data() {
      return {
        interval: null,
        currentProjectId: this.projectId
      }
    },
    methods: {
      ...mapActions({
        fetchAllTasks: 'project/fetchAllTasks',
        refreshAllTasks: 'project/refreshAllTasks',
        
        fetchAllProjectTasks: 'project/fetchAllProjectTasks',
        refreshAllProjectTasks: 'project/refreshAllProjectTasks',

        fetchAllSupportTasks: 'project/fetchAllSupportTasks',
        refreshAllSupportTasks: 'project/refreshAllSupportTasks',

        fetchTasks: 'project/fetchTasks',
        refreshTasks: 'project/refreshTasks',

        clearTasks: 'project/clearTasks',
      }),

      onUpdateStatus() {
        this.fetch();
      },

      refresh() {
        if (this.currentProjectId) {
          this.refreshTasks({id: this.currentProjectId, filters: this.filters});
        } else {
          if (this.type === 'support') {
            this.refreshAllSupportTasks()
            return;
          }
          if (this.type === 'project') {
            this.refreshAllProjectTasks()
            return;
          }
          this.refreshAllTasks();
        }
      },

      fetch() {
        if (this.currentProjectId) {
          this.fetchTasks({id: this.currentProjectId, filters: this.filters});
        } else {
          if (this.type === 'support') {
            this.fetchAllSupportTasks()
            return;
          }
          if (this.type === 'project') {
            this.fetchAllProjectTasks()
            return;
          }
          this.fetchAllTasks();
        }
      },

      init() {
        this.clearTasks();
        this.fetch();
      }
    },

    created() {
      this.init();
      this.interval = setInterval(() => {
        this.refresh();
      }, 30 * 1000);
    },

    beforeDestroy() {
      clearInterval(this.interval);
    },

    render() {
      return this.$scopedSlots.default({
        tasks: this.workerTasks
      })
    },

    watch: {
      projectId(newValue) {
        this.currentProjectId = newValue;
        this.init();
      }
    }
  }
</script>