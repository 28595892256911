<template>
  <div class="task-options-tags">
    <div class="task-options-tags__top">
      <span class="task-options-tags__title">Метки</span>
      <a href="javascript:void(0)" class="task-options-tags__icon" @click.prevent="handleClick">
        <IconCrossGrey/>
      </a>
    </div>
    <div class="task-options-tags__search">
      <TaskOptionsTagsSearch/>
    </div>
    <div class="task-options-tags__list">
      <TaskOptionsTagsList/>
    </div>
    <div class="task-options-tags__button" @click.prevent="handleClickButton">
      <Button :wide="true" >Создайте новую метку</Button>
    </div>
  </div>
</template>

<script>
  import TaskOptionsTagsSearch from "./TaskOptionsTagsSearch.vue";
  import TaskOptionsTagsList from "./TaskOptionsTagsList.vue";
  import IconCrossGrey from "../../../icons/IconCrossGrey.vue";
  import Button from "../../ui/Button.vue";

  export default {
    name: "TaskOptionsTags",
    components: {Button, IconCrossGrey, TaskOptionsTagsList, TaskOptionsTagsSearch},
    methods: {
      handleClick() {
        this.$emit('crossClicked')
      },
      handleClickButton() {
        this.$emit('buttonClicked')
      }
    }
  }
</script>

<style lang="scss">
  .task-options-tags {
    padding-bottom: 20px;
    position: absolute;
    min-height: 200px;
    width: 100%;
    background-color: #ffffff;
    z-index: 3;
    box-shadow: 0 20px 30px 0 rgba(32, 38, 47, 0.09);

    &__title {
      color: rgba(12, 34, 68, 0.32);
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.07em;
      font-weight: 500;
    }

    &__top {
      padding: 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__icon {
      display: block;
      padding: 13px;
      margin-right: -13px;
    }

    &__search {
      margin-bottom: 15px;
    }

    &__list {
      margin-bottom: 15px;
    }

    &__button {
      padding: 0 24px;
    }
  }
</style>