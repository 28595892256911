<template>
    <div class="task__bottom">
        <div class="task__wrapper">
            <div class="task__wrapper-left">
                <div class="task__wrapper-datetime" v-if="task.deadline || task.support || task.isSupportTask">
                    <span class="task__icon-alert" v-if="isExpired">
                      <img src="/static/images/svg/alert.svg" alt="">
                    </span>
                    <span class="task__icon-support" v-if="task.support || task.isSupportTask">
                        <img src="/static/images/svg/support.svg" alt="">
                    </span>
                    <div class="task__datetime" v-if="task.deadline || task.start_at"
                         :class="{'alert': isExpired, 'pause': isPaused, 'support': !isExpired && task.support}"
                    >
                      <template v-if="task.start_at && isPaused">
                        ⏸️ {{dateTimeStart}}
                      </template>
                      <template v-if="task.deadline && task.start_at && isPaused"> - </template>
                      <template v-if="task.deadline">{{dateTime}}</template>
                    </div>
                </div>
                <div class="task__messang" v-if="task.comments_count">
                <span class="task__messang-icon">
                    <img src="/static/images/svg/dialog.svg" alt="">
                </span>
                    <span class="task__messang-number">{{task.comments_count}}</span>
                </div>
                <div class="task__notes" v-if="task.files_count">
                <span class="task__notes-icon">
                    <img src="/static/images/svg/clip.svg" alt="">
                </span>
                    <span class="task__notes-number">{{task.files_count}}</span>
                </div>
            </div>
            <div class="task__wrapper-user" v-if="!hidePerformer && performer">
                <TheAvatar :worker="performer" :small="true" />
            </div>
        </div>
    </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import TheAvatar from "../ui/TheAvatar.vue";

  export default {
    name: "TaskBottom",
    components: {
      TheAvatar
    },
    props: {
      task: Object,
      isNewTask: Boolean,
      hidePerformer: Boolean
    },
    data() {
      return {
        isExpired: false,
        isPaused: false
      }
    },
    computed: {
      ...mapGetters({
        getWorkerById: 'worker/getWorkerById'
      }),
      dateTime() {
        if (this.task.deadline !== undefined) {
          return this.$moment(this.task.deadline).calendar(null, {
            sameDay: '[Сегодня]',
            lastDay: '[Вчера]',
            lastWeek: 'D MMMМ',
            nextDay: '[Завтра]',
            nextWeek: 'D MMMM',
            sameElse: 'D MMMM'
          });
        }
      },
      dateTimeStart() {
        if (this.task.start_at !== undefined) {
          return this.$moment(this.task.start_at).calendar(null, {
            sameDay: '[Сегодня]',
            lastDay: '[Вчера]',
            lastWeek: 'D MMMМ',
            nextDay: '[Завтра]',
            nextWeek: 'D MMMM',
            sameElse: 'D MMMM'
          });
        }
      },
      performer() {
        if (this.task.performer_id) {
          return this.getWorkerById(this.task.performer_id);
        }
        return null;
      },
    },
    mounted() {
      if (this.task.deadline && this.$moment(this.task.deadline).isBefore(Date.now())) {
        this.isExpired = true;
      }
      if (this.task.start_at && this.$moment(this.task.start_at).isAfter(Date.now())) {
        this.isPaused = true;
      }
    }
  }
</script>

<style>

</style>