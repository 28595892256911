<template>
    <div class="auth-wrapper">
        <slot>

        </slot>
    </div>
</template>

<script>
  export default {
    name: "AuthLayout"
  }
</script>

<style lang="scss">
    .auth-wrapper {
        display: flex;
        width: 100vw;
        justify-content: center;
        align-items: center;
        background: {
            image: url('/static/images/base/tidalwaves.jpg');
            position: center;
            size: cover;
            repeat: no-repeat;
        }
    }
</style>