<template>
  <ul class="kanban__list column-list">
    <li class="column-list__item" v-for="status in tasks" :key="status.status">
      <ProjectKanbanStatusItem
        :status=status
        @updatedStatus="onUpdateStatus"
      />
    </li>
  </ul>
</template>

<script>
  import ProjectKanbanStatusItem from './ProjectKanbanStatusItem.vue';
  
  export default {
    name: "ProjectKanbanStatusList",
    components: {ProjectKanbanStatusItem},
    props: {
      tasks: Array,
    },
    methods: {
      onUpdateStatus() {
        this.$emit('updateStatus');
      }
    }
  }
</script>

<style lang="scss">

</style>