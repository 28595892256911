<template>
  <div class="select">
    <div class="select__top">
      <h3 class="select__title">{{title}}</h3>
      <a href="#" class="select__closer" @click.prevent="$emit('close')">
        <IconCrossSmall/>
      </a>
    </div>
    <div class="select__search">
      <BaseSearch
              :placeholder="placeholder"
              :autofocus="true"
              @input="onInput"
              @enter="onEnter"
      />
    </div>
    <vue-simplebar>
      <ul class="select__list select-list" v-if="options">
        <li class="select-list__item" v-for="option in filteredOptions" :key="option.id">
          <div class="select-item" @click.prevent="handleClickOption(option.id)">
            <slot name="option"
                  :option="option"
                  :selected="value"
                  :isActive="isActive(option.id)"
            >
            </slot>
          </div>
        </li>
      </ul>
    </vue-simplebar>
  </div>
</template>

<script>
  import BaseSearch from "./BaseSearch.vue";
  import IconCrossSmallGrey from "../../../icons/IconCrossSmallGrey.vue";
  import IconCrossSmall from "../../../icons/IconCrossSmall.vue";
  import Matcher from "../../../../common/matcher";

  export default {
    name: "BaseSelect",
    components: {IconCrossSmall, IconCrossSmallGrey, BaseSearch},
    props: {
      value: {
        required: true,
      },
      options: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      placeholder: {
        type: String,
        default: ''
      },
    },
    computed: {
      filteredOptions() {
        return this.options.filter((option) => {
          return Matcher.match(option.name, this.searchInput);
        })
      }
    },
    data() {
      return {
        searchInput: ''
      }
    },
    methods: {
      onInput(value) {
        this.searchInput = value.toLowerCase();
      },
      handleClickOption(optionId) {
        let value;
        if (!this.isActive(optionId)) {
          value = optionId;
        } else {
          value = null;
        }
        this.$emit('input', value);
      },
      isActive(optionId) {
        return optionId == this.value;
      },
      onEnter() {
        const firstOption = this.filteredOptions[0];
        if (firstOption) {
          this.handleClickOption(firstOption.id);
        }
      }
    },
    mounted() {

    }
  }
</script>

<style>

</style>