import moment from 'moment';
import TimeService from '../../services/time.service';

moment.locale('ru');

const dayBefore = moment().set({hour:0,minute:0,second:0,millisecond:0}).format('YYYY-MM-DD HH:mm:ss');
const now = moment().format('YYYY-MM-DD HH:mm:ss');

const timeModule = {
  namespaced: true,
  state: {
    byWorkers: [],
    byProjects: [],
    fromDateWorkers: dayBefore,
    toDateWorkers: now,
    fromDateProjects: dayBefore,
    toDateProjects: now,
    loading: false,
  },
  getters: {
    getByWorker(state) {
      console.log(state.byWorkers);
      return workerId => state.byWorkers.find(workerData => workerData.worker_id === workerId);
    },
  },
  actions: {
    async fetchWorkers({ commit, state }) {
      commit('setLoading', true);
      try {
        const response = await TimeService.getByWorkers(
          state.fromDateWorkers,
          state.toDateWorkers,
        );
        commit('setByWorkers', response.data.data);
      } catch (e) {
        console.log(e);
      }
      commit('setLoading', false);
    },
    async fetchProjects({ commit, state }) {
      commit('setLoading', true);
      try {
        const response = await TimeService.getByProjects(
          state.fromDateProjects,
          state.toDateProjects,
        );
        commit('setByProjects', response.data.data);
      } catch (e) {
        console.log(e);
      }
      commit('setLoading', false);
    },
  },

  mutations: {
    setFromDateWorkers(state, value) {
      state.fromDateWorkers = value;
    },
    setToDateWorkers(state, value) {
      state.toDateWorkers = value;
    },
    setFromDateProjects(state, value) {
      state.fromDateProjects = value;
    },
    setToDateProjects(state, value) {
      state.toDateProjects = value;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setByWorkers(state, data) {
      state.byWorkers = data;
    },
    setByProjects(state, data) {
      state.byProjects = data;
    },
  },
};

export default timeModule;