<template>
  <KanbanLayout>
    <h2 slot="title">
      Таймлайн
    </h2>

    <Tabs
      slot="tabs"
    />

    <div slot="kanban">
      <div class="timeline-container">
        <TimelineContainer v-slot="{timelines, days}">
          <div class="panel">
            <div class="panel__body">

              <vue-simplebar class="panel__scroll panel__scroll_headless">
                <div class="panel__container">
                  <TimelineHead :days="days"/>
                  <TimelineWorker v-for="(timeline, key) in timelines" :key="key" :timeline="timeline" :days="days"/>
                </div>
              </vue-simplebar>
            </div>
          </div>
        </TimelineContainer>
      </div>
    </div>
  </KanbanLayout>
</template>

<script>
  import KanbanLayout from "../layouts/KanbanLayout.vue";
  import Tabs from "../components/app/index/Tabs.vue";
  import TimelineContainer from "../containers/TimelineContainer.vue";
  import TimelineHead from '../components/app/timeline/TimelineHead.vue';
  import TimelineWorker from '../components/app/timeline/TimelineWorker.vue';

  export default {
    name: "TheManager",
    components: {
      KanbanLayout,
      Tabs,
      TimelineContainer,
      TimelineHead,
      TimelineWorker
    },
  }
</script>

<style>

</style>