<template>
  <div class="tags-add-input">
    <input type="text" placeholder="Наименование" v-model="tagName">
  </div>
</template>

<script>
  export default {
    name: "TagsCreatorInput",
    computed: {
      tagName: {
        get() {
          return this.$store.state.tag.tag.tagName;
        },
        set(value) {
          this.$store.commit('tag/updateName', value)
        }
      }
    }
  }
</script>

<style lang="scss">
  .tags-add-input {
    input[type="text"] {
      width: 100%;
      padding: 9px 13px;
      font-size: 15px;
      color: #6C707B;
    }
  }
</style>