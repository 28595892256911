<template>
    <div class="pipeline-form project-subpage" v-if="!loading">
        <div class="pipeline-form__head">
            <router-link :to="{name: 'project_pipelines', params: {projectId: project.id}}" class="pipeline-form__back">
                &larr;
            </router-link>
            <h1 class="pipeline-form__title">
                <span v-if="id">
                    Редактирование пайплайна
                </span>
                <span v-else>
                    Создание пайплайна
                </span>
            </h1>
        </div>

        <div class="pipeline-form__name">
            <input type="text" v-model="pipeline.name" class="pipeline-form__name-input" placeholder="Наименование">
        </div>


        <div class="pipeline-form__preview">
            <button class="pipeline-form__preview-open" @click="previewOpened = !previewOpened">
                Предпросмотр 🔎
            </button>
            <div class="pipeline-view-container" v-if="previewOpened">
                <PipelineView :pipeline="this.pipeline" :tasks="this.items"/>
            </div>
        </div>

        <div class="pipeline-form__list">
            <div class="pipeline-list">
                <div class="pipeline-list__items">
                    <div class="pipeline-list__item" v-for="(item, key) in items" :id="item.id" :data-item-id="item.id">
                        <Item :item="item"
                              :canDelete="key !== 0"
                              :allowed-next-items="taskIds"
                              @delete="removeItem(item.id, key)"
                              @addNext="addAfter(item)"
                              @changeNext="onChangeNext"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="pipeline-form__actions">
            <button type="submit" class="pipeline-form__submit" @click="save">
                Запустить 🚀
            </button>
        </div>
    </div>
    <Loading v-else/>
</template>

<script>
import Item from "./Item";
import ProjectContainer from "../../../containers/ProjectContainer";
import ProjectLayout from "../../../layouts/ProjectLayout";
import PipelineService from "../../../services/pipeline.service";
import PipeService from "../../../services/pipe.service";
import Loading from "../../ui/Loading";
import arrowCreate, { DIRECTION, HEAD } from 'arrows-svg';
import PipelineView from "./PipelineView";

export default {
  name: "TheForm",
  components: {
    PipelineView,
    Item,
    ProjectContainer,
    ProjectLayout,
    Loading
  },
  props: {
    project: Object,
    id: {
      required: false,
      type: Number
    }
  },
  data() {
    return {
      previewOpened: false,
      loading: true,
      maxItem: 0,
      pipeline: {
        id: 0,
        name: ''
      },
      items: [],
      arrows: []
    }
  },
  mixins: [

  ],
  computed: {
    taskIds() {
      return this.items.map((item) => item.id);
    }
  },
  methods: {
    orderItems() {
      this.items = PipeService.flatLayers(this.items);
      this.$nextTick(() => {
        this.renderArrows();
      })
    },
    clearArrows() {
      this.arrows.forEach((arrow) => {
        arrow.clear();
      })
    },
    renderArrows() {

      this.clearArrows();
      if (this.loading) {
        return;
      }
      this.$nextTick(() => {
        this.items.forEach((item) => {
          item.next.forEach((nextId) => {
            this.renderArrow(item.id, nextId);
          })
        });
      });
    },
    getPositionById(id) {
      let stepNum = 0;
      this.items.forEach((item, key) => {
        if (item.id === id) {
          stepNum = key;
        }
      });
      return stepNum;
    },
    renderArrow(from, to) {
      const fromElement = document.querySelector(`[data-item-id="${from}"]`);
      const toElement = document.querySelector(`[data-item-id="${to}"]`);

      const fromPosition = this.getPositionById(from);
      const toPosition = this.getPositionById(to);

      let diff = Math.abs(fromPosition - toPosition);
      if (diff < 1) {
        diff = 1;
      }
      let fromTranslation = [-2 * diff,0];
      let toTranslation = [-2 * diff,0];

      const arrow = arrowCreate({
        from: {
          translation: fromTranslation,
          direction: DIRECTION.LEFT,
          node: fromElement,
        },
        to: {
          translation: toTranslation,
          direction: DIRECTION.TOP_LEFT,
          node: toElement,
        },
        head: {
          func: HEAD.THIN,
          size: 5
        }
      })
      arrow.node.firstElementChild.setAttribute('data-arrow-pipeline-form-id', this.pipeline.id);
      this.arrows.push(arrow);
      document.body.appendChild(arrow.node);
    },
    getDefaultItem(name) {
      return {
        id: 'n' + this.idGenerate(),
        name: name || '',
        performer_id: null,
        status: 10,
        next: []
      }
    },
    idGenerate() {
      this.maxItem++;
      return this.maxItem;
    },
    removeItem(id, index) {
      const targetItem = this.items[index];

      // Чистим висячие ссылки

      // Найти все элементы на которые ссылался данный элемент
      const nextElementsIds = this.items
        .filter((x) => targetItem.next.indexOf(x.id) > -1)
        .map((x) => x.id);

      // Найти все элементы, которые ссылались на данный элемент
      const prevElements = this.items
        .filter((x) => x.next.indexOf(targetItem.id) > -1);

      // Для каждого элемента, который ссылался на данный элемент (prevElements)
      prevElements.forEach((prevElement) => {
        // добавляем в next id каждого элемента из nextElementsIds
        prevElement.next = [...prevElement.next, ...nextElementsIds];

        // и удаляем targetItem.id
        prevElement.next = prevElement.next.filter((x) => x !== targetItem.id)
      });

      this.items.splice(index, 1);
      this.orderItems();
    },
    addAfter(item) {
      const nextItem = this.getDefaultItem();
      nextItem.next = item.next;
      item.next = [nextItem.id];

      this.items.push(nextItem);
      this.orderItems();

      this.$nextTick(() => {
        document.querySelector(`[data-item-id="${nextItem.id}"] textarea`).focus();
      })
    },
    onChangeNext() {
      this.orderItems();
    },
    validate() {
      let hasErrors = false;
      this.items.forEach((item) => {
        if (!item.performer) {
          hasErrors = true;
          this.$notify({
            group: 'foo',
            title: 'Не указан исполнитель'
          })
        }
      })
      return !hasErrors;
    },
    async save() {
      try {
        if (this.id) {
          const response = await PipelineService.update(this.project.id, this.pipeline.id, {
            tasks: this.items,
            pipeline: this.pipeline
          });
        } else {
          const response = await PipelineService.create(this.project.id, {
            tasks: this.items,
            pipeline: this.pipeline
          });
        }
        this.$router.push({name: 'project_pipelines', params: {projectId: this.project.id}});
      } catch (e) {
        console.log(e);
        this.$notify({
          group: 'foo',
          title: 'Ошибка сохранения пайплайна'
        })
      }
    },
    async init() {
      this.loading = true;
      if (this.id) {
        try {
          const response = await PipelineService.get(this.project.id, this.id);
          this.pipeline = response.data.data;
          this.items = this.pipeline.tasks;
          this.orderItems();
        } catch (e) {
          console.log(e);
          this.$notify({
            group: 'foo',
            title: 'Ошибка получения пайплайна'
          })
        }
      } else {
        this.items.push(this.getDefaultItem());
      }
      this.loading = false;
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.clearArrows();
  },
}
</script>

<style lang="scss">
.pipeline-form {
  &__head {
    color: #fff;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }

  &__inner {
    padding: 0 41px;
    color: #fff;
  }

  &__back {
    opacity: 0.5;
    margin-right: 10px;
    font-size: 30px;
  }

  &__actions {
    margin-top: 20px;
  }

  &__name {
    margin-bottom: 20px;
  }

  &__name-input {
    padding: 5px;
    width: 100%;
  }

  &__preview {
    margin-top: 15px;
    margin-bottom: 20px;
  }
}
</style>