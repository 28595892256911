<template>
    <svg width="10px" height="10px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
        <title>Group 3</title>
        <desc>Created with Sketch.</desc>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Все-задачи-Copy-2" transform="translate(-663.000000, -504.000000)" fill="#FFFFFF">
                <g id="Group-17-Copy" transform="translate(587.000000, 171.000000)">
                    <g id="Group-16" transform="translate(76.000000, 329.000000)">
                        <g id="Group-3" transform="translate(0.000000, 4.000000)">
                            <g id="Group-12">
                                <g id="Group-11">
                                    <rect id="Rectangle" x="4" y="0" width="2" height="10"></rect>
                                    <rect id="Rectangle" transform="translate(5.000000, 5.000000) rotate(-90.000000) translate(-5.000000, -5.000000) " x="4" y="8.8817842e-16" width="2" height="10"></rect>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
  export default {
    name: "IconWhitePlus"
  }
</script>

<style>

</style>