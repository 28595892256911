import ApiService from './api.service';

const tagService = {
  async getAll() {
    const requestData = {
      url: '/api/tags',
      method: 'get',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async store(tag) {
    const requestData = {
      url: '/api/tags',
      method: 'post',
      data: {
        tag,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};

export default tagService;