const PipeService = {
  // Общие методы
  // Находим элементы, на которые никто не ссылается - будет плоский массив
  findRootNodes(items) {
    const rootNodes = [];
    const nextLinks = [];
    items.forEach((item) => {
      nextLinks.push(...item.next);
    });
    items.forEach((item) => {
      if (nextLinks.indexOf(item.id) !== -1) {
        return;
      }
      rootNodes.push(item);
    });
    return rootNodes;
  },

  // Найти следующие элементы
  findNextNodes(prev, items) {
    return items.filter(item => prev.next.indexOf(item.id) !== -1);
  },

  // Строим плоский пайп
  // Сортируем по порядку
  sortPipe(items) {
    // Находим элементы, на которые никто не ссылается - будет плоский массив
    // Идем по массиву, для каждого из них ищем следующие элементы по next, добавляем их по очереди
    const result = [];
    const rootNodes = this.findRootNodes(items);
    rootNodes.forEach((item) => {
      this.add(item, result);
      this.handleBranch(items, item, result);
    });

    // Ищем тех кого не добавили
    const handledLinks = [];
    result.forEach((item) => {
      handledLinks.push(item.id);
    });
    items.forEach((item) => {
      if (handledLinks.indexOf(item.id) !== -1) {
        return;
      }
      this.add(item, result);
      this.handleBranch(items, item, result);
    });
    return result;
  },

  handleBranch(items, prev, result) {
    items.forEach((item) => {
      if (prev.next.indexOf(item.id) === -1) {
        return;
      }
      this.add(item, result);
      this.handleBranch(items, item, result);
    });
  },

  add(item, result) {
    if (!result.find((x) => x.id === item.id)) {
      result.push(item);
    }
  },

  // Отсортированный список
  flatLayers(nodes) {
    const layers = this.makeLayers(nodes);
    const resultData = [];
    let maxStep = 0;
    layers.forEach((layer) => {
      layer.forEach((item) => {
        if (item.step > maxStep) {
          maxStep = item.step;
        }
      });
    });
    let currentStep = 0;
    while (maxStep >= currentStep) {
      layers.forEach((layer) => {
        layer.forEach((item) => {
          if (item.step === currentStep) {
            resultData.push(item.node);
          }
        });
      });
      currentStep += 1;
    }
    return resultData;
  },
  // Строим послойную картинку
  // [
  //    [
  //       {
  //        step: 1,
  //        node: {id: 1, next: [2], ...}
  //       },
  //       {
  //        step: 2,
  //        node: {id: 2, next: [], ...}
  //       }
  //    ],
  // ...
  // ]
  layers: [],
  // Основной метод
  makeLayers(nodes) {
    this.layers = [];
    const rootNodes = this.findRootNodes(nodes);
    rootNodes.forEach((rootNode) => {
      const layer = this.getNextLayer();
      this.processLayerItem(rootNode, nodes, layer, 0);
    });
    return this.layers;
  },
  // Добавить слой и получить его номер
  getNextLayer() {
    this.layers.push([]);
    return this.layers.length - 1;
  },
  // Обойти все слои, поискать там эту ноду
  isAlreadyAddedNode(node) {
    let alreadyAdded = false;
    this.layers.forEach((layer) => {
      layer.forEach((layerItem) => {
        if (layerItem.node.id === node.id) {
          alreadyAdded = true;
        }
      });
    });
    return alreadyAdded;
  },
  // Добавляем элемент в слой
  // Если такой элемент уже существует в одном из других слоев
  // - ничего не добавляем и возвращаем false
  // Если элемент успешно добавлен
  // - возвращаем true
  pushItemToLayer(node, layerNum, step) {
    if (this.isAlreadyAddedNode(node)) {
      return false;
    }
    this.layers[layerNum].push({
      step,
      node,
    });
    return true;
  },
  // Обрабатываем ноду
  processLayerItem(node, nodes, layerNum, step) {
    if (!this.pushItemToLayer(node, layerNum, step)) {
      return;
    }
    let addedChildNodes = 0;
    this.findNextNodes(node, nodes).forEach((nextNode) => {
      // Если нода уже есть в каком-либо слое - ничего не делаем
      if (this.isAlreadyAddedNode(nextNode)) {
        return;
      }
      // Наткнулись на развилку - добавляем еще слой
      if (addedChildNodes > 0) {
        layerNum = this.getNextLayer();
      }
      this.processLayerItem(nextNode, nodes, layerNum, step + 1);
      addedChildNodes += 1;
    });
  },
};
export default PipeService;