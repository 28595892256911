<template>
  <div class="task__executors-select">
    <BaseSelect
            title="Исполнитель"
            name="Исполнитель"
            :value="task.performer_id"
            :options="activeWorkers"
            @input="onInput"
            @close="onCloseEditing">
      <template v-slot:option="{option, isActive}">
        <a href="javascript:void(0)" class="select-item__link">
          <div class="select-item__avatar">
            <TheAvatar
                    :worker="option"
                    small/>
          </div>
          <div class="select-item__name">
            {{option.name}}
          </div>
          <div class="select-item__icon" v-if="isActive">
            <IconCheckRed/>
          </div>
        </a>
      </template>
    </BaseSelect>
  </div>
</template>

<script>
  import BaseSelect from "../../ui/multiselect/BaseSelect.vue";
  import BaseMultiselect from "../../ui/multiselect/BaseMultiselect.vue";
  import BaseMultiselectItem from "../../ui/multiselect/BaseMultiselectItem.vue";
  import TheAvatar from "../../ui/TheAvatar.vue";
  import IconCheckRed from "../../../icons/IconCheckRed.vue";
  import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';

  export default {
    name: "TaskOptionsPerformer",
    components: {IconCheckRed, TheAvatar, BaseMultiselectItem, BaseMultiselect, BaseSelect},
    computed: {
      ...mapState('worker', [
        'workers'
      ]),
      ...mapState('task', [
        'task'
      ]),
      ...mapGetters('filter', [
        'filters'
      ]),
      ...mapGetters('worker', [
        'activeWorkers'
      ])
    },
    data() {
      return {
        selected: []
      }
    },
    methods: {
      ...mapActions({
        storeExecutors: 'task/storeExecutors',
        fetchTasks: 'project/fetchTasks'
      }),
      ...mapMutations({
        clearTask: 'task/clearTask',
      }),
      onCloseEditing() {
        this.close();
      },
      onInput(value) {
        this.$store.dispatch('task/storePerformerId', value);
        this.close();
      },
      close() {
        this.$emit('close');
      }
    },
    mounted() {
      this.selected = this.task.executors;
    }
  }
</script>

<style lang="scss">

</style>