<template>
  <button class="button"
        :class="{button_wide: wide, button_alert: primary, button_transparent: transparent, button_big: big}"
        @click="handleClick"
  >
    <slot></slot>
  </button>
</template>

<script>
  export default {
    name: "Button",
    props: {
      wide: Boolean,
      primary: Boolean,
      transparent: Boolean,
      big: Boolean
    },
    methods: {
      handleClick() {
        this.$emit('click');
      }
    }
  }
</script>

<style lang="scss">

</style>