import ApiService from './api.service';

const WorkerService = {
  async getAllWorkers() {
    const requestData = {
      url: '/api/workers',
      method: 'get',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async getUserWorker(userId) {
    const requestData = {
      url: '/api/worker',
      method: 'get',
      params: {
        userId,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async storeAvatar() {
    const requestData = {
      url: 'api/workers/file',
      method: 'post',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async storeColor({ workerId, color }) {
    const requestData = {
      url: '/api/workers/color',
      method: 'post',
      data: {
        workerId,
        color,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.error(e);
      return false;
    }
  },
};

export default WorkerService;