<template>
  <svg width="15px" height="17px" viewBox="0 0 15 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
    <title>Group 8</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Все-задачи-Copy" transform="translate(-32.000000, -105.000000)" stroke="#FFFFFF" stroke-width="2">
        <g id="Group-22" transform="translate(-1.000000, -6.000000)">
          <g id="Group-18">
            <g id="Group-6" transform="translate(34.000000, 110.000000)">
              <g id="Group-8" transform="translate(0.000000, 2.000000)">
                <circle id="Oval" cx="5.5" cy="5.5" r="5.5"></circle>
                <path d="M9.4,10.4166667 L12.6,14.1666667" id="Line-2" stroke-linecap="square"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IconSearchWhite"
  }
</script>

<style>

</style>