<template>
  <svg width="12px" height="13px" viewBox="0 0 12 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
    <title>Group 23</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
      <g id="Все-задачи-Copy-3" transform="translate(-1148.000000, -624.000000)" stroke="#5E5F61" stroke-width="2">
        <g id="Group-17-Copy-2" transform="translate(887.000000, 171.000000)">
          <g id="Group-23" transform="translate(267.192388, 460.192388) rotate(-225.000000) translate(-267.192388, -460.192388) translate(260.692388, 453.692388)">
            <path d="M0.00830078125,7 L12.0021973,7" id="Line-3"></path>
            <path d="M5.98950195,0.967895508 L5.98950195,12.9210205" id="Line-3"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IconCrossDarkGrey"
  }
</script>

<style lang="scss">

</style>