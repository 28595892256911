<template>
  <div class="task-index__description">
    <div class="task-index__description-head">
      <h3 class="task-index__description-label">Описание</h3>
      <a href="#" class="task-index__description-link button" @click.prevent="handleChange" v-if="task.text">Изменить</a>
    </div>
    <p class="task-index__description-text" v-if="!editable" v-html="textEditor"></p>
    <div class="task-index__editable" v-if="editable">
      <wysiwyg
        placeholder="Введите подробное описание задачи..."
        v-model="textEditor"
        @input="input"
        autofocus
      >
      </wysiwyg>
      <a href="javascript:void(0)" class="task-index__button button button_alert" @click.prevent="handleSave">Сохранить</a>
      <a href="javascript:void(0)" class="task-index__button button" @click="handleCancel">Отменить</a>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters} from 'vuex';
  import debounce from 'lodash/debounce';

  export default {
    name: "TaskDescription",
    computed: {
      ...mapGetters('task',[
        'text',
        'task'
      ])
    },
    data() {
      return {
        editable: false,
        textEditor: '<p>Hi</p>'
      }
    },
    methods: {
      handleChange() {
        this.editable = true;
      },
      handleSave() {
        this.editable = false;
        const payload = {task: this.task, text: this.textEditor};
        this.$store.dispatch('task/updateDescription', payload);
      },
      handleCancel() {
        this.textEditor = this.text;
        this.editable = false;
      },
      input: debounce(function () {
        this.$store.dispatch('task/updateDescription', {task: this.task, text: this.textEditor});
      }, 300)
    },
    mounted() {
      this.textEditor = this.text;
      this.editable = !this.text;
    },
    watch: {
      textEditor() {
        if (this.editable) {
          this.input();
        }
      }
    }
  }
</script>

<style lang="scss">
  /*.task-index__editable {*/
    /*textarea {*/
      /*font-family: 'Roboto';*/
      /*width: 100%;*/
      /*height: 264px;*/
      /*border: 1px solid #DDE1EA;*/
      /*resize: none;*/
      /*font-size: 15px;*/
      /*color: #6C707B;*/
      /*padding: 12px 14px 16px 14px;*/
      /*margin-bottom: 12px;*/
      /*-webkit-font-smoothing: antialiased;*/
      /*-moz-osx-font-smoothing: grayscale;*/
    /*}*/
  /*}*/
  .editr {
    margin-bottom: 15px;
  }
  .editr--content {
    font-size: 15px;
    color: #6C707B;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 60px;

    &::before {
      font-size: 15px;
    }
  }
</style>