<template>
    <div class="subtask">
        <div class="subtask__main">
            <div class="subtask__status" :class="'subtask__status_' + this.task.status">
                {{ statusVerbose }}
            </div>

            <router-link class="subtask__name" target="_blank" :to="{name: 'task', params: {taskId: task.id}}">
                {{ task.name }}
            </router-link>

            <div class="subtask__bottom">
                <TaskBottom :task="task" :hidePerformer="true"></TaskBottom>
            </div>
        </div>

        <div class="subtask__performer">
            <div class="subtask__performer-select" @click.prevent="openSelectPerformer">
                <TheAvatar :worker="task.performer" v-if="task.performer_id" class="avatar_small"/>

                <IconBigPlus v-else/>
            </div>

            <div class="subtask__performer-selector" v-if="performerSelectOpened">
                <BaseSelect
                        title="Исполнитель"
                        name="Исполнитель"
                        :value="task.performer_id"
                        :options="activeWorkers"
                        @close="closeSelectPerformer"
                        @input="changePerformer">

                    <template v-slot:option="{option, isActive}">
                        <a href="javascript:void(0)" class="select-item__link">
                            <div class="select-item__avatar">
                                <TheAvatar
                                        :worker="option"
                                        small/>
                            </div>
                            <div class="select-item__name">
                                {{option.name}}
                            </div>
                            <div class="select-item__icon" v-if="isActive">
                                <IconCheckRed/>
                            </div>
                        </a>
                    </template>
                </BaseSelect>
            </div>

        </div>
    </div>
</template>

<script>
import StatusService from "../../../services/status.service";
import TaskBottom from "../../parts/TaskBottom.vue";
import IconBigPlus from "../../../icons/IconBigPlus.vue";
import TheAvatar from "../../ui/TheAvatar";
import BaseSelect from "../../ui/multiselect/BaseSelect.vue";
import IconCheckRed from "../../../icons/IconCheckRed.vue";

import {mapActions, mapGetters} from "vuex";
import TaskService from "../../../services/task.service";

export default {
  name: "TaskSubtasksItem",
  props: {
    task: Object,
  },
  data() {
    return {
      performerSelectOpened: false
    }
  },
  components: {
    TheAvatar,
    TaskBottom,
    IconBigPlus,
    IconCheckRed,
    BaseSelect
  },
  computed: {
    ...mapGetters('worker', [
      'activeWorkers'
    ]),
    statusVerbose() {
      return StatusService.verboseStatus(this.task.status);
    },
  },
  methods: {
    ...mapActions('task', [
      'refreshTask'
    ]),
    openSelectPerformer() {
        this.performerSelectOpened = true;
    },
    closeSelectPerformer() {
      this.performerSelectOpened = false;
    },
    async changePerformer(id) {
      await TaskService.storePerformerId(this.task.id, id);
      this.performerSelectOpened = false;
      this.refreshTask();
    }
  }
}
</script>

<style lang="scss">
.subtask {
  display: grid;
  grid-template-columns: 1fr 70px;

  &__performer {
    display: flex;
    justify-content: flex-end;
  }

  &__performer-select {
    cursor: pointer;
  }

  &__status {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 1px 5px;
    background: #F1F3F8;
    border-radius: 9px;
    color: #9499A4;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
  }

  &__name {
    display: block;
    margin-top: 6px;
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__performer {
    position: relative;
  }

  &__performer-selector {
    position: absolute;
    top: 0;
    right: 0;
    width: 270px;
    background: #FFFFFF;
    border: 1px solid #EAECF2;
    box-shadow: 0px 10px 20px rgba(44, 67, 101, 0.100524);
    z-index: 1000;
  }
}
</style>