import Vue from 'vue';
import TimelineService from '../../services/timeline.service';

const timelineModule = {
  namespaced: true,
  state: {
    timelines: [],
    from: null,
    to: null,
    days: [],
  },
  actions: {
    async fetchTimeline({ dispatch, rootState }) {
      rootState.loading = true;
      await dispatch('refreshTimeline');
      rootState.loading = false;
    },

    async refreshTimeline({ commit }) {
      try {
        const response = await TimelineService.getTimeline();
        commit('setTimeline', response.data.data);
        return true;
      } catch (e) {
        throw new Error(e);
      }
    },
  },

  mutations: {
    setTimeline(state, data) {
      state.timelines = data.timelines;
      state.from = Vue.prototype.$moment(data.start_date);
      state.to = Vue.prototype.$moment(data.end_date);
      state.days = data.days;
    },
  },
};

export default timelineModule;