import ApiService from './api.service';

const LoadService = {
  async getLog(projectId) {
    let url = '/api/loading';
    if (projectId) {
      url = `/api/${projectId}/loading`;
    }
    const requestData = {
      url,
      method: 'get',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};

export default LoadService;