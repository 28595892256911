<template>
    <li class="sidebar__category" :class="{'sidebar__category_collapsed': !isOpened}" >
        <a class="sidebar__title" @click.prevent="toggle" href="javascript:void(0);">
            <span class="sidebar__group-name">
                {{group.name}}
            </span>
            <span class="sidebar__group-arrow">
                <img src="/static/images/svg/arrow-down-sm.svg" alt="">
            </span>
        </a>
        <SidebarGroupsProjectsList :projects="group.projects"/>
    </li>
</template>

<script>
  import SidebarGroupsProjectsList from "./SidebarGroupsProjectsList.vue";
  import LocalSettings from '../../../services/local.service'

  export default {
    name: "SidebarGroupsItem",
    components: {SidebarGroupsProjectsList},
    props: {
      group: Object
    },
    computed: {
      isOpened() {
        if (this.$store.state.project.searchInputProject.length > 0) {
          return true;
        }
        return this.opened;
      }
    },
    data() {
      return {
        opened: LocalSettings.getObject(`MENU_OPENED_${this.group.id}`, true)
      }
    },
    methods: {
      toggle() {
        this.opened = !this.opened;
        LocalSettings.setObject(`MENU_OPENED_${this.group.id}`, this.opened)
      }
    }
  }
</script>

<style scoped>

</style>