<template>
    <div class="loading panel">
        <div class="panel__head">
            <div class="panel__head-content">
                <div class="panel__caption">
                    <div class="panel__title">
                        Сотруднки
                    </div>
                </div>
                <div class="panel__filter">

                </div>
            </div>
        </div>
        <div class="panel__body">
            <vue-simplebar class="panel__scroll">
                <div class="panel__container">
                    <div class="loading__groups">
                        <LoadingGroup
                                class="loading__group"
                                v-for="(group, key) in byGroups"
                                :key="key"
                                :group="group">
                        </LoadingGroup>
                    </div>
                </div>
            </vue-simplebar>
        </div>
    </div>
</template>

<script>
  import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
  import LoadingGroup from "./LoadingGroup.vue";

  export default {
    name: "TheLoading",
    components: { LoadingGroup },
    props: {
      projectId: {
        type: Number,
        required: false
      },
    },
    computed: {
      ...mapGetters({
        byGroups: 'load/byGroups'
      }),
    },
    data() {
      return {
        openedFilter: false,
        filtered: [],
      }
    },
    mounted() {
      this.fetchLoading(this.projectId);
      this.fetchWorkers();
      this.filtered = this.filterTypes;
    },
    methods: {
      ...mapActions({
        fetchLoading: 'load/fetchLoading',
        refreshLoading: 'load/refreshLoading',
        fetchWorkers: 'worker/fetchWorkersIfNotLoaded',
      }),
      ...mapMutations('log', [
        'setFilter'
      ]),
      toggleFilter() {
        this.openedFilter = !this.openedFilter;
      },
      changedFilter() {
        this.setFilter(this.filtered);
        this.fetchLoading(this.projectId);
      },
      loadMore() {
        this.more();
      }
    }
  }
</script>

<style>

</style>