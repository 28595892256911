<template>
  <ProjectContainer v-slot="{project}">
    <ProjectLayout :project="project">
      <div slot="kanban" v-if="project.id">
        <TheProjectChecklistForm :project="project" :id="parseInt($route.params.checklistId, 10)"/>
      </div>
    </ProjectLayout>
  </ProjectContainer>
</template>

<script>
  import ProjectContainer from "../containers/ProjectContainer.vue";
  import ProjectLayout from "../layouts/ProjectLayout.vue";
  import TheProjectChecklistForm from "../components/app/checklist/project/TheProjectChecklistForm.vue";

  export default {
    name: "TheProjectChecklist",
    components: {
      TheProjectChecklistForm,
      ProjectContainer,
      ProjectLayout
    },
  }
</script>

<style>

</style>