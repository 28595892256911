<template>
    <svg width="9px" height="9px" viewBox="0 0 9 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
        <title>Group 23</title>
        <desc>Created with Sketch.</desc>
        <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Все-задачи-Copy-6" transform="translate(-622.000000, -884.000000)" fill="#C1C4D3" fill-rule="nonzero">
                <g id="Group-17" transform="translate(401.000000, 876.000000)">
                    <g id="Group-11">
                        <g id="Group-23" transform="translate(225.363961, 12.363961) rotate(-315.000000) translate(-225.363961, -12.363961) translate(219.363961, 7.363961)">
                            <path d="M4.98950195,4 L4.98950195,0 L6.98950195,0 L6.98950195,4 L11.0092773,4 L11.0092773,6 L6.98950195,6 L6.98950195,10 L4.98950195,10 L4.98950195,6 L0.989501953,6 L0.989501953,4 L4.98950195,4 Z" id="Combined-Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
  export default {
    name: "IconCrossSmall"
  }
</script>

<style>

</style>