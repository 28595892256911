<template>
    <div class="loading-group">
        <a class="loading-group__name" href="javascript:void(0);">
            <span class="loading-group__name-text">
                {{group.name}}
            </span>
            <span class="loading-group__name-icon">
                <img src="/static/images/svg/arrow-down-sm.svg" alt="">
            </span>
        </a>
        <div class="loading-group__workers">
            <LoadingGroupWorker
                v-for="worker in group.workers"
                :key="worker.id"
                :worker="worker"
            ></LoadingGroupWorker>
        </div>
    </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import LoadingGroupWorker from './LoadingGroupWorker.vue';

  export default {
    name: "LoadingGroup",
    components: {
      LoadingGroupWorker
    },
    props: {
      group: {
        type: Object,
        required: true
      }
    },
  }
</script>

<style>

</style>