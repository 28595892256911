<template>
  <svg width="18px" height="13px" viewBox="0 0 18 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
    <title>Combined Shape 3</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" fill-opacity="0.2">
      <g id="Все-задачи-Copy-3" transform="translate(-34.000000, -424.000000)" fill="#FFFFFF" fill-rule="nonzero">
        <g id="Group-22" transform="translate(-1.000000, -6.000000)">
          <g id="Group-18">
            <g id="Group-24" transform="translate(0.000000, 358.000000)">
              <g id="Combined-Shape-3" transform="translate(35.000000, 72.000000)">
                <path d="M2,9.18292536 L6.27953547,4.79365821 L10.2955543,6.66735496 L14.723255,2 L2,2 L2,9.18292536 Z M3.02164777,11 L16,11 L16,3.56013108 L10.7552269,9.08878344 L6.72046453,7.20634179 L3.02164777,11 Z M18,0 L18,13 L0,13 L0,0 L18,0 Z" id="Combined-Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IconBackground"
  }
</script>

<style>

</style>