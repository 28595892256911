import TokenService from '../../services/token.service';
import { AuthService, AuthenticationError } from '../../services/auth.service';
import router from '../../router/index';

const authModule = {
  namespaced: true,
  state: {
    authenticating: false,
    accessToken: TokenService.getToken(),
    authenticationErrorCode: 0,
    authenticationMessage: '',
  },
  getters: {},
  actions: {
    async login({ commit }, { email, password }) {
      commit('loginRequest');
      try {
        const data = await AuthService.login(email, password);
        commit('loginSuccess', data.data.jwt);
        commit('user/setUser', data.data, { root: true });
        router.push({ name: 'projects' });
      } catch (e) {
        if (e instanceof AuthenticationError) {
          commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
        }
      }
    },
  },
  mutations: {
    loginRequest(state) {
      state.authenticating = true;
    },
    loginSuccess(state, token) {
      state.authenticating = false;
      state.accessToken = token;
    },
    loginError(state, { errorCode, errorMessage }) {
      state.authenticating = false;
      state.authenticationErrorCode = errorCode;
      state.authenticationMessage = errorMessage;
    },
  },
};

export default authModule;