<template>
    <div class="log panel panel_light log_light panel_embedded log_embedded">
        <div class="panel__head">
            <div class="panel__head-content">
                <div class="panel__caption">
                    <div class="panel__title">
                        Лог изменений
                    </div>
                </div>
                <div class="panel__filter">
                    <a href="javascript:void(0);" class="panel__filter-link" @click.prevent="toggleFilter">
                        Фильтр ({{ filtered.length }})
                    </a>
                    <div class="panel__filter-info" v-if="openedFilter">
                        <div class="panel__filter-item" v-for="(text, status) in names">
                            <input
                                    type="checkbox"
                                    v-model="filtered"
                                    :id="'status_' + status"
                                    :value="status"
                                    class="panel__filter-checkbox"
                                    @change="changedFilter"/>

                            <label :for="'status_' + status" class="panel__filter-checkbox-label">
                                {{ text }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel__body">
            <div class="panel__container">
                <div class="log__date log__date_narrow" v-for="date in byDates" :key="date.name">
                    <div class="log__date-name log__date-name_narrow">
                        {{ date.name }}
                    </div>
                    <div class="log__date-items">
                        <LogItem
                                v-for="event in date.events"
                                :event="event"
                                class="log__item log-item_light"
                                :key="event.id"
                        />
                    </div>
                </div>

                <div class="log__load-more-wrapper" v-if="hasNext">
                    <a href="javascript:void(0);" class="log__load-more button" @click.prevent="loadMore">
                        Загрузить еще &darr;
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions, mapState, mapMutations} from 'vuex';
import LogItem from "../../parts/LogItem.vue";

export default {
  name: "TaskLog",
  components: {
    LogItem,
  },
  props: {
    taskId: {
      type: Number,
      required: true
    },
  },
  computed: {
    ...mapGetters({
      byDates: 'logTask/byDates',
      hasNext: 'logTask/hasNext'
    }),
    ...mapState('logTask', [
      'filterTypes',
      'names'
    ]),
  },
  data() {
    return {
      openedFilter: false,
      filtered: [],
    }
  },
  mounted() {
    this.fetchLoading(this.taskId);
    this.fetchWorkers();
    this.filtered = this.filterTypes;
  },
  methods: {
    ...mapActions({
      fetchLoading: 'logTask/fetchLoading',
      refreshLoading: 'logTask/refreshLoading',
      more: 'logTask/more',
      fetchWorkers: 'worker/fetchWorkersIfNotLoaded',
    }),
    ...mapMutations('logTask', [
      'setFilter'
    ]),
    toggleFilter() {
      this.openedFilter = !this.openedFilter;
    },
    changedFilter() {
      this.setFilter(this.filtered);
      this.fetchLoading(this.taskId);
    },
    loadMore() {
      this.more();
    }
  }
}
</script>

<style lang="scss">

</style>