<template>
  <div>
    <ul class="sidebar__categories">
      <FavoritesGroup :projects="projects"
                       v-if="projects.length"
      />
      <SidebarGroupsItem v-for="group in groups" :key="group.id"
                         :group="group" v-if="isVisible(group)"
      />
    </ul>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  import SidebarGroupsItem from "./SidebarGroupsItem.vue";
  import FavoritesGroup from './FavoritesGroup.vue';

  export default {
    name: "SidebarGroupsList",
    components: {
      FavoritesGroup,
      SidebarGroupsItem},
    computed: {
      ...mapGetters('filter', [
        'filters'
      ]),
      ...mapState('favoritesModule', [
          'projects'
      ]),
      groups() {
        return this.$store.state.project.groups;
      },
    },
    methods: {
      ...mapActions({
        getProjects: 'project/fetchProjects'
      }),
      isVisible(group) {
        const filterValue = this.$store.state.project.searchInputProject.toUpperCase();
        let visible = false;
        group.projects.forEach((project) => {
          if (!filterValue || project.name.toUpperCase().indexOf(filterValue) >= 0) {
            visible = true;
          }
        });
        return visible;
      }
    },
    created() {
      this.getProjects({ filters: this.filters });
    }
  }
</script>

<style lang="scss">

</style>