<template>
    <div class="checklist-upcoming" v-if="nextItems.length > 0">
        <div class="checklist-upcoming__title">
            Следующие шаги:
        </div>
        <div class="checklist-upcoming__items">
            <div class="checklist-upcoming__item" v-for="(nextItem, key) in nextItems" :key="key">
                {{ nextItem.position }}. {{ nextItem.text }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "ChecklistUpcoming",
  props: {
    items: Array
  },
  computed: {
    nextItems() {
      return this.items.filter((item) => !item.status).slice(0, 3)
    }
  }
}
</script>

<style lang="scss">
.checklist-upcoming {
  background-color: #191B1E;
  width: 300px;
  padding: 10px 15px;

  &__title {
    margin-bottom: 10px;
  }

  &__item {
    margin-bottom: 5px;
    font-size: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>