<template>
  <svg width="21px" height="14px" viewBox="0 0 21 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
    <title>Group 33</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" fill-opacity="0.3">
      <g id="скрыть" transform="translate(-41.000000, -42.000000)" fill="#FFFFFF" fill-rule="nonzero">
        <g id="Group-33" transform="translate(51.500000, 49.000000) rotate(-180.000000) translate(-51.500000, -49.000000) translate(41.000000, 42.000000)">
          <path d="M3.98145882,8 L7.4081072,12.1312787 L5.86872127,13.4081072 L0.719183125,7.19965655 L5.8200315,0.597284299 L7.4027157,1.8200315 L4.17336347,6 L20.0856934,6 L20.0856934,8 L3.98145882,8 Z" id="Combined-Shape"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IconArrowGrey"
  }
</script>

<style>

</style>