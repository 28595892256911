<template>
  <div class="task-options-tags-search">
    <input type="text" placeholder="Найти метку..." class="task-options-tags-search__input" v-model="$store.state.tag.searchQuery">
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions} from "vuex";

  export default {
    name: "TaskOptionsTagsSearch",
  }
</script>

<style lang="scss">
  .task-options-tags-search {
    width: 100%;
    padding: 0 24px;

    &__input {
      width: 100%;
      border: none;
      border-bottom: 1px solid #EAECF2;
      padding: 10px 27px;
      color: #6C707B;
      font-size: 15px;
    }
  }
</style>