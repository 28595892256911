<template>
    <svg width="36px" height="40px" viewBox="0 0 36 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
        <title>Group 20 Copy 5</title>
        <desc>Created with Sketch.</desc>
        <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Artboard" transform="translate(-112.000000, -223.000000)">
                <g id="Group-20-Copy-5" transform="translate(112.000000, 223.000000)">
                    <path d="M0.5,0.5 L0.5,39.5 L35.5,39.5 L35.5,8.67740291 L27.4130879,0.5 L0.5,0.5 Z" id="Rectangle-Copy" stroke="#EAECF2" fill="#FFFFFF"></path>
                    <g id="microsoft-word-2013-logo" transform="translate(10.063599, 13.002929)" fill-rule="nonzero">
                        <path d="M8.94885254,0 L8.94885254,1.02002065 C10.8909709,1.03091103 13.3179225,0.999982346 15.2582984,1.03091103 C15.672133,0.989091962 15.9969568,1.59269731 15.9589131,2.00653188 C15.9896966,5.58220777 15.9516528,9.14035718 15.9770637,12.7160331 C15.9589131,13.082676 16.013365,13.4892503 15.8009299,13.8140741 C15.5359306,14.0028408 15.1928109,13.9793176 14.8843953,13.9936929 C13.0672985,13.9848353 10.7656589,13.9884655 8.94885254,13.9884655 L8.94885254,15.0308849 L7.8145102,15.0308849 C4.95544434,14.7727672 2.76877108,14.6421931 0.000871230674,14.1574985 C-0.00101643579,9.92302697 0.000871230674,5.68841028 0.000871230674,1.45568125 C2.78314639,0.969244125 5.08102396,0.495585049 7.86358953,0 L8.94885254,0 Z" id="Path" fill="#2A5699"></path>
                        <path d="M8.96069336,1.91626089 L14.9508057,1.91626089 L14.9508057,13.0167248 L8.96069336,13.0167248 L8.96069336,11.7642986 L13.6801758,11.7642986 L13.6801758,10.9947426 L8.96069336,10.9947426 L8.96069336,10.0018932 L13.6801758,10.0018932 L13.6801758,9.00041883 L8.96069336,9.00041883 L8.96069336,8.00702016 L13.6801758,8.00702016 L13.6801758,6.99707143 L8.96069336,6.99707143 L8.96069336,6.0048229 L13.6801758,6.0048229 L13.6801758,5.01214712 L8.96069336,5.01214712 L8.96069336,4.01043813 L13.6801758,4.01043813 L13.6801758,3.01213376 L8.96069336,3.01213376 L8.96069336,1.91626089 Z M3.75064805,5.20371561 C4.0955102,5.18382251 4.44037234,5.169302 4.78523448,5.15115136 C5.02656538,6.3763921 5.27341407,7.59959997 5.53478327,8.81932291 C5.73995809,7.55966856 5.96691368,6.30364434 6.18631861,5.04587766 C6.54933139,5.03324481 6.91234417,5.01320651 7.27361449,4.99142574 C6.86341005,6.75015006 6.50402739,8.5235401 6.05563401,10.271374 C5.75244573,10.4293572 5.29867976,10.2641138 4.9392971,10.2895247 C4.697821,9.08809758 4.4165587,7.89364032 4.20049349,6.68669543 C3.98820362,7.85922671 3.7123139,9.02086761 3.46909534,10.1862838 C3.12060307,10.1681332 2.77036834,10.1463524 2.4198432,10.122684 C2.1186878,8.52542776 1.76467773,6.93906191 1.48341543,5.33817555 C1.79371875,5.32365504 2.10590975,5.31087699 2.41635828,5.2999866 C2.60323726,6.45610971 2.81567233,7.60700543 2.97902809,8.76487099 C3.23531511,7.57796441 3.49653911,6.39091261 3.75064805,5.20371561 Z" id="Shape" fill="#FFFFFF"></path>
                    </g>
                    <polygon id="Rectangle" fill="#EAECF2" points="27 0 36 9 27 9"></polygon>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
  export default {
    name: "IconWord"
  }
</script>

<style>

</style>