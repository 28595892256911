<template>
    <svg width="36px" height="40px" viewBox="0 0 36 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
        <title>Group 20 Copy 3</title>
        <desc>Created with Sketch.</desc>
        <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Artboard" transform="translate(-112.000000, -154.000000)">
                <g id="Group-20-Copy-3" transform="translate(112.000000, 154.000000)">
                    <path d="M0.5,0.5 L0.5,39.5 L35.5,39.5 L35.5,8.67740291 L27.4130879,0.5 L0.5,0.5 Z" id="Rectangle-Copy" stroke="#EAECF2" fill="#FFFFFF"></path>
                    <g id="sketch-2" transform="translate(9.000000, 12.467285)" fill-rule="nonzero">
                        <g id="a-link" transform="translate(0.032248, 4.804776)" fill="#EA6C00">
                            <polygon id="a" points="3.61012876 0.726108131 8.91275896 11.4332951 0 0.726108131"></polygon>
                        </g>
                        <g id="b-link" transform="translate(13.452855, 10.804776) scale(-1, 1) translate(-13.452855, -10.804776) translate(8.952855, 4.804776)" fill="#EA6C00">
                            <polygon id="b" points="3.60765508 0.726474342 8.90665188 11.4332951 0 0.726474342"></polygon>
                        </g>
                        <g id="c-link" transform="translate(2.661138, 4.804776)" fill="#FDAD00">
                            <polygon id="c" points="0.95488128 0.710910378 11.7060494 0.710910378 6.33046533 11.5564914"></polygon>
                        </g>
                        <g id="d-link" transform="translate(3.661138, 0.022643)" fill="#FDD231">
                            <polygon id="d" points="5.33046533 0 0.280560791 0.513923403 0 5.56677301"></polygon>
                        </g>
                        <g id="e-link" transform="translate(11.322069, 3.022643) scale(-1, 1) translate(-11.322069, -3.022643) translate(8.322069, 0.022643)" fill="#FDD231">
                            <polygon id="e" points="5.33046533 0 0.280560791 0.511179278 0 5.53704889"></polygon>
                        </g>
                        <g id="f-link" transform="translate(15.952855, 3.056449) scale(-1, 1) translate(-15.952855, -3.056449) translate(13.952855, 0.556449)" fill="#FDAD00">
                            <polygon id="f" points="0 4.99103654 3.90964023 0 3.62907944 4.99103654"></polygon>
                        </g>
                        <g id="g-link" transform="translate(0.032248, 0.556449)" fill="#FDAD00">
                            <polygon id="g" points="0 4.98572648 3.90964023 0 3.62907944 4.98572648"></polygon>
                        </g>
                        <g id="h-link" transform="translate(3.661138, 0.022643)" fill="#FEEEB7">
                            <polygon id="h" points="5.33046533 0 0 5.53491266 10.6609307 5.53491266"></polygon>
                        </g>
                    </g>
                    <polygon id="Rectangle" fill="#EAECF2" points="27 0 36 9 27 9"></polygon>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
  export default {
    name: "IconSketch"
  }
</script>

<style>

</style>