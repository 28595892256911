<template>
    <div class="task-index__label">
        <h2
            contenteditable
            @input="onInput"
        >
            {{innerName}}
        </h2>
    </div>
</template>

<script>
  import { mapActions } from "vuex";

  export default {
    name: "TaskTitle",
    props: {
      name: String
    },
    data() {
      return {
        innerName: this.name
      }
    },
    methods: {
      ...mapActions('task', [
        'updateTitle'
      ]),
      onInput(e) {
        this.updateTitle(e.target.innerText);
      },
    }
  }
</script>

<style>

</style>