import ApiService from './api.service';

const LogService = {
  async getLog(pageNum, projectId, types) {
    const page = pageNum || 1;
    let url = '/api/log';
    if (projectId) {
      url = `/api/${projectId}/log`;
    }
    const requestData = {
      url,
      method: 'get',
      params: {
        page,
        types,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async getTaskLog(pageNum, taskId, types) {
    const page = pageNum || 1;
    const url = `/api/tasks/${taskId}/log`;
    const requestData = {
      url,
      method: 'get',
      params: {
        page,
        types,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};

export default LogService;