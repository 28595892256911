<template>
  <div class="options-tag">
    <a href="javascript:void(0)" class="options-tag__link" @click.prevent="handleClick">
      <span class="options-tag__circle" :style="{background: tag.color}"></span>
      <span class="options-tag__name" :style="{color: tag.color}">{{tag.name}}</span>
      <span class="options-tag__icon" v-if="isSelectedData"><IconCheckRed/></span>
    </a>
  </div>
</template>

<script>
  import IconCheckRed from "../../../icons/IconCheckRed.vue";
  import { mapState } from "vuex"

  export default {
    name: "TaskOptionsTagsItem",
    components: {IconCheckRed},
    props: {
      tag: Object,
      task: Object,
    },
    computed: {
      isSelectedData() {
        this.isSelected = false;
        if (this.task.tags !== undefined) {
          this.task.tags.forEach((taskTag) => {
            if (taskTag.id === this.tag.id) {
              this.isSelected = true;
            }
          });
        }
        return this.isSelected;
      }
    },
    data() {
      return {
        isSelected: false
      }
    },
    methods: {
      async handleClick() {
        if (this.isSelected === false) {
          await this.$store.dispatch('task/addTag', this.tag);
          const payload = {taskId: this.task.id, tags: this.task.tags};
          this.$store.dispatch('task/storeTags', payload)
        } else {
          await this.$store.dispatch('task/removeTag', this.tag);
          const payload = {taskId: this.task.id, tags: this.task.tags};
          this.$store.dispatch('task/storeTags', payload)
        }
      }
    }
  }
</script>

<style lang="scss">
  .options-tag {
    &__link {
      display: flex;
      height: 37px;
      padding: 0 24px;
      align-items: center;

      &:hover {
        background-color: #F1F3F8;
      }
    }
    &__circle {
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 100%;
      margin-right: 9px;
    }
    &__name {
      display: block;
      flex-grow: 1;
      font-size: 15px;
    }
    &__avatar {
      display: block;
      margin-right: 9px;
    }
  }
</style>