<script>
  export default {
    name: "BaseMultiselectItem",
    props: {
      option: {
        type: Object,
        required: true,
      },
      selected: {
        type: Array,
        default: () => {
          return [];
        }
      }
    },
    data() {
      return {
        isActive: false,
      }
    },
    watch: {
      selected: {
        immediate: true,
        handler: function (val) {
          this.isActive = false;
          const ids = val.map(option => option.id);
          ids.forEach((id) => {
            if (id === this.option.id) {
              return this.isActive = true;
            }
            return this.isActive;
          });
          if (ids === []) {
            this.isActive = false;
          }
        }
      }
    },
    methods: {
      handleClick() {
        if (!this.isActive) {
          this.$emit('add', this.option);
        } else {
          this.$emit('remove', this.option);
        }
      }
    },
    render() {
      return this.$scopedSlots.default({
        option: this.option,
        isActive: this.isActive,
        handleClick: this.handleClick,
      })
    }
  }
</script>

<style>

</style>