<template>
  <div class="app-modal">
    <div class="app-modal__overlay" @click="$emit('close')"></div>
    <div class="app-modal__inner">
      <slot />
    </div>
  </div>
</template>

<script>
  const body = document.querySelector('body');
  export default {
    name: "TheModal",
  }
</script>

<style lang="scss" scoped>
  .app-modal {
    &__overlay {
      background-color: #000000;
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 9;
      opacity: 0.8;
    }
    &__inner {
      position: relative;
      z-index: 10000;
    }
  }
  body {
    position: fixed;
  }
  
</style>