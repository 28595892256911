<template>
    <svg width="36px" height="40px" viewBox="0 0 36 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
        <title>Group 20 Copy 9</title>
        <desc>Created with Sketch.</desc>
        <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Artboard" transform="translate(-112.000000, -361.000000)">
                <g id="Group-20-Copy-9" transform="translate(112.000000, 361.000000)">
                    <path d="M0.5,0.5 L0.5,39.5 L35.5,39.5 L35.5,8.67740291 L27.4130879,0.5 L0.5,0.5 Z" id="Rectangle-Copy" stroke="#EAECF2" fill="#FFFFFF"></path>
                    <g id="Group-28" transform="translate(9.000000, 1.000000)">
                        <rect id="Rectangle" fill="#ABB1C3" x="0" y="0" width="4" height="2"></rect>
                        <rect id="Rectangle-Copy-6" fill="#ABB1C3" x="0" y="12" width="4" height="2"></rect>
                        <rect id="Rectangle-Copy-9" fill="#ABB1C3" x="0" y="18" width="4" height="2"></rect>
                        <rect id="Rectangle-Copy-4" fill="#ABB1C3" x="0" y="6" width="4" height="2"></rect>
                        <rect id="Rectangle-Copy-7" fill="#ABB1C3" x="0" y="21" width="7" height="8"></rect>
                        <rect id="Rectangle-Copy-7" fill="#FFFFFF" x="2" y="23" width="3" height="2"></rect>
                        <rect id="Rectangle-Copy-3" fill="#ABB1C3" x="3" y="3" width="4" height="2"></rect>
                        <rect id="Rectangle-Copy-8" fill="#ABB1C3" x="3" y="15" width="4" height="2"></rect>
                        <rect id="Rectangle-Copy-5" fill="#ABB1C3" x="3" y="9" width="4" height="2"></rect>
                    </g>
                    <polygon id="Rectangle" fill="#EAECF2" points="27 0 36 9 27 9"></polygon>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
  export default {
    name: "IconZip"
  }
</script>

<style>

</style>