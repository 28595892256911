<template>
    <div class="checklist-template-list project-subpage">
        <div class="project-subpage__container">
            <Loading v-if="loading"/>
            <div class="checklist-template-list__content" v-else>
                <div class="checklist-template-list__item" v-for="checklist in checklists">
                    <div class="checklist-template-list__pipeline">
                        <router-link :to="{name: 'checklist_update', params: {id: checklist.id}}"
                                     class="checklist-template-list__name">
                            {{ checklist.name }} <span class="checklist-template-list__icon">✎</span>
                        </router-link>
                    </div>
                </div>

                <router-link :to="{name: 'checklist_create'}"
                             class="button button_alert checklist-template-list__button">
                    Добавить план
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from "../../../ui/Loading";
import ChecklistTemplateService from "../../../../services/checklisttemplate.service";

export default {
  name: "TheChecklistTemplateList",
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      checklists: []
    }
  },
  methods: {
    async fetch() {
      this.loading = true;
      try {
        const response = await ChecklistTemplateService.all();
        this.checklists = response.data.data;
        console.log(this.checklists);
      } catch (e) {
        console.log(e);
        this.$notify({
          group: 'foo',
          title: 'Не удалось получить чеклисты'
        })
      }
      this.loading = false;
    },
  },
  mounted() {
    this.fetch();
  }
}
</script>

<style lang="scss">
.checklist-template-list {
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__pipeline {
    display: flex;
  }

  &__name {
    color: white;
    padding: 9px 19px;
    background: #191B1E;
    margin-bottom: 5px;
    min-width: 560px;
    display: flex;
    justify-content: space-between;
  }

  &__button {
    padding: 9px 19px;
    width: fit-content;
    margin-top: 15px;
  }
}
</style>