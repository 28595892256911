<template>
  <ul class="color-list" v-if="colors">
    <li class="color-list__item" v-for="color in colors" :key="color.id">
      <TagsCreatorColorItem
        :color="color"
        :selectedColor="tagForm.selectedColor"
      />
    </li>
  </ul>
</template>

<script>
  import TagsCreatorColorItem from "./TagsCreatorColorItem.vue";
  export default {
    name: "TagsCreatorColorList",
    components: {TagsCreatorColorItem},
    props: {
      colors: Array,
      tagForm: Object,
    }
  }
</script>

<style lang="scss">
  .color-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 16px;
  }
</style>