<template>
    <div class="task-index__position">
        <span class="task-index__position-name">В колонке</span>
        <span class="task-index__position-backlog">{{statusName}}</span>
    </div>
</template>

<script>
  export default {
    name: "TaskStatus",
    props: {
      status: Number
    },
    computed: {
      statusName() {
        const statuses = this.$store.state.project.statuses;
        for (status in statuses) {
          if (status == this.status) {
              return statuses[status];
          }
        }
      }
    }
  }
</script>

<style>

</style>