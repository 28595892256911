<template>
    <div class="sidebar__search">
        <input type="text" class="sidebar__input" placeholder="Найти проект..." v-model="inputProject">
    </div>

</template>

<script>
  export default {
    name: "SidebarSearch",
    computed: {
      inputProject: {
        get() {
          return this.$store.state.project.searchInputProject;
        },
        set(value) {
          this.$store.commit('project/updateSearchProject', value);
        }
      }
    }
  }
</script>

<style scoped>

</style>