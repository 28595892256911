<template>
  <div class="task-index__time task-time">
    <div class="task-time__estimated">
      <div class="task-time__estimated-label">
        Оценка времени
      </div>
      <div class="task-time__estimated-inputs">
        <div class="task-time__estimated-input">
          <label class="task-time__estimated-input-label">
            Дней
          </label>
          <input type="text" v-model="days" @input="onInput" class="task-time__estimated-input-value task-time__estimated-input-value_days">
        </div>
        <div class="task-time__estimated-input">
          <label class="task-time__estimated-input-label">
            Часов
          </label>
          <input type="text" v-model="hours" @input="onInput" class="task-time__estimated-input-value task-time__estimated-input-value_hours">
        </div>
        <div class="task-time__estimated-input">
          <label class="task-time__estimated-input-label">
            Мин
          </label>
          <input type="text" v-model="minutes" @input="onInput" class="task-time__estimated-input-value task-time__estimated-input-value_min">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TaskTime",
    props: {
      task: Object
    },
    data() {
      return {
        hoursInDay: 8,
        days: '',
        hours: '',
        minutes: '',

        estimate: this.task.estimate,
        started: false,
        timer: null,
      }
    },
    methods: {
      onInput() {
        clearTimeout(this.timeout);
        this.countEstimate();
        this.timeout = setTimeout(() => {
          this.$store.dispatch('task/setEstimate', {
            taskId: this.task.id,
            estimate: this.estimate
          });
        }, 300)
      },
      countEstimate() {
        let days = parseInt(this.days, 10);
        if (!days) {
          days = 0;
        }
        let hours = parseInt(this.hours, 10);
        if (!hours) {
          hours = 0;
        }
        let minutes = parseInt(this.minutes, 10);
        if (!minutes) {
          minutes = 0;
        }

        this.estimate = days * this.hoursInDay * 60 + hours * 60 + minutes;
      }
    },
    mounted() {
      let days = 0;
      let hours = 0;
      let minutes = 0;
      let estimate = this.estimate;

      days = Math.floor(estimate/(this.hoursInDay * 60));
      estimate -= days * this.hoursInDay * 60;
      if (!days) {
        days = '';
      }

      hours = Math.floor(estimate/60);
      estimate -= hours * 60;
      if (!hours) {
        hours = '';
      }

      minutes = estimate;
      if (!minutes) {
        minutes = '';
      }

      this.days = days;
      this.hours = hours;
      this.minutes = minutes;
    }
  }
</script>

<style lang="scss">

</style>