<template>
  <div class="avatar"
       v-tooltip.bottom="{
       content: worker.name,
      }"
       :class="{avatar_small: small, avatar_large: big}"
  >
    <div class="avatar__default"
         v-if="!avatarUrl"
         :style="{background: bgColor}"
    >
      <span class="avatar__label">{{avatarLabel}}</span>
    </div>
    <div class="avatar__image"
         :style="{backgroundImage: `url(${avatarUrl})`}"
         v-else
    >
    </div>
  </div>

</template>

<script>
  import IconCrossSmallGrey from "../../icons/IconCrossSmallGrey.vue";
  import { mapState } from 'vuex';
  import axios from 'axios';

  export default {
    name: "TheAvatar",
    components: {IconCrossSmallGrey},
    props: {
      small: Boolean,
      big: Boolean,
      worker: {
        type: Object,
        required: true,
      }
    },
    computed: {
      ...mapState('worker', [
        'userWorker'
      ]),
      avatarLabel() {
        return this.worker.name ? this.worker.name[0] : '';
      },
      avatarUrl() {
        // const size = 200 + this.worker.id;
        // return `http://placekitten.com/${size}/${size}`;

        if (this.worker.avatar) {
          if (this.worker.avatar.path) {
            return `/media/${this.worker.avatar.path}`;
          }
          return `/media/${this.worker.avatar}`;
        }
        return null;
      },
      bgColor() {
        if (!this.worker.color) {
          return '#377DFF';
        }
        return this.worker.color;
      }
    },
    methods: {
      getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
    },
    mounted() {
      this.$store.watch(
        (state, getters) => state.worker.userWorker.color,
        (newValue, oldValue) => {
          if (this.userWorker.id === this.worker.id) {
            this.worker.color = newValue
          }
        }
      );
      this.$store.watch(
        (state, getters) => state.worker.userWorker.avatar,
        (newValue, oldValue) => {
          if (this.userWorker.id === this.worker.id) {
            this.worker.avatar = newValue
          }
        }
      )
    }
  }
</script>

<style lang="scss">
  .avatar {
    position: relative;
    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 19px;
    z-index: 10;
    cursor: default;
    overflow: hidden;

    /*
    transition: all 0.3s;

    &:hover {
      transform: scale(2.5);
      overflow: auto;
    }
    */

    &_small {
      width: 25px;
      height: 25px;
      font-size: 13px;
    }

    &_large {
      width: 70px;
      height: 70px;
      font-size: 30px;
      font-weight: 700;
    }

    &__default {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__image {
      width: 100%;
      height: 100%;
      background: {
        size: cover;
        position: center;
        repeat: no-repeat;
      };
    }

    &__label {
      text-transform: uppercase;
      color: #ffffff;
    }

    &__tooltip {
      display: block;
      position: absolute;
      top: 120%;
      left: -50%;
      transform: translate(-25%);
      padding: 5px 10px;
      background: #000000;
      color: #ffffff;
      white-space: nowrap;
      z-index: 100000;
    }

    &__close-icon {
      padding: 4px;
      display: inline-block;
      margin-right: -4px;
    }
  }

  .tooltip {
    top: 5px;
    color: #ffffff;
    background: #000000;
    font-size: 15px;
    padding: 5px 9px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    z-index: 1000;

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: black;
      z-index: 1;
    }

    &[x-placement^="bottom"] {
      margin-top: 5px;

      .tooltip-arrow {
        border-width: 0 5px 5px 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
</style>