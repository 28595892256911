<template>
  <KanbanLayout>
    <h2 slot="title">
      Команды
    </h2>

    <Tabs
      slot="tabs"
    />

    <div slot="kanban">
      <div class="timeline-container">
        <MembershipContainer v-slot="{ projects, workers, types, memberships, updatedType, created, deletedMembership }">
          <div class="panel">
            <div class="panel__body">

              <vue-simplebar class="panel__scroll panel__scroll_headless">
                <div class="panel__container">
                  <Membership
                    :projects="projects"
                    :workers="workers"
                    :memberships="memberships"
                    v-slot="{ project, worker, membership }"
                  >
                    <MembershipValue
                      :types="types"
                      :project-id="project.id"
                      :worker-id="worker.id"
                      :membership="membership"
                      @created="created"
                      @updatedType="updatedType"
                      @deletedMembership="deletedMembership"
                    ></MembershipValue>
                  </Membership>
                </div>
              </vue-simplebar>
            </div>
          </div>
        </MembershipContainer>
      </div>
    </div>
  </KanbanLayout>
</template>

<script>
  import KanbanLayout from "../layouts/KanbanLayout.vue";
  import Tabs from "../components/app/index/Tabs.vue";
  import MembershipContainer from "../containers/MembershipContainer.vue";
  import Membership from "../components/app/membership/Membership.vue";
  import MembershipValue from "../components/app/membership/MembershipValue.vue";

  export default {
    name: "TheMembership",
    components: {
      KanbanLayout,
      Tabs,
      MembershipContainer,
      Membership,
      MembershipValue
    },
  }
</script>

<style>

</style>