<template>
    <div class="search">
        <input type="text"
               :placeholder="placeholder"
               :value="val"
               @input="$emit('input', $event.target.value)"
               @keyup.enter="$emit('enter')"
               :class="{transparent: transparent}"
               ref="searchInput"
        >
        <span class="search__icon">
			<IconSearchWhite v-if="transparent"/>
			<IconSearch v-else/>
		</span>
    </div>
</template>

<script>
  import IconSearchWhite from "../../../icons/IconSearchWhite.vue";
  import IconSearch from "../../../icons/IconSearch.vue";

  export default {
    name: "BaseSearch",
    components: {IconSearch, IconSearchWhite},
    props: {
      value: {
        type: String
      },
      placeholder: {
        type: String,
        default: () => {
          return '';
        }
      },
      transparent: {
        type: Boolean,
        default: false,
      },
      autofocus: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        val: this.value || ''
      }
    },
    mounted() {
      if (this.autofocus) {
        this.$refs.searchInput.focus();
      }
    }
  }
</script>

<style lang="scss">
    .search {
        position: relative;
        width: 100%;
        padding: 0 24px;
        margin-bottom: 20px;
        input {
            width: 100%;
            border: none;
            border-bottom: 1px solid #EAECF2;
            padding: 10px 27px;
            color: #6C707B;
            font-size: 15px;
            &.transparent {
                border-color: rgba(255, 255, 255, 0.16);
                background: transparent;
                color: #ffffff;
            }
        }
        &__icon {
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
        }
    }
</style>
