<template>
    <div class="workers-time time panel">
        <div class="panel__head">
            <div class="panel__head-content">
                <div class="panel__caption">
                    <div class="panel__title">
                        Проекты
                    </div>
                </div>
                <div class="panel__filter">
                    <div class="time__filter" v-if="initialized">
                        <div class="time__links">
                            <a href="javascript:void(0);" @click="setDates('tomorrow')" class="time__link">
                                Вчера
                            </a>
                            <a href="javascript:void(0);" @click="setDates('today')" class="time__link">
                                Сегодня
                            </a>
                        </div>
                        <div class="time__dates">
                            <datetime
                                    type="datetime"
                                    placeholder="Введите дату"
                                    format="d LLL HH:mm"
                                    class="theme-grey"
                                    input-class="datepicker"
                                    v-model="fromDate"
                                    :phrases="{ cancel: 'Отменить', ok: 'Выбрать'}"
                                    @input="onInput"
                            >
                            </datetime>

                            <datetime
                                    type="datetime"
                                    placeholder="Введите дату"
                                    format="d LLL HH:mm"
                                    class="theme-grey"
                                    input-class="datepicker"
                                    v-model="toDate"
                                    :phrases="{ cancel: 'Отменить', ok: 'Выбрать'}"
                                    @input="onInput"
                            >
                            </datetime>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="panel__body">
            <vue-simplebar class="panel__scroll">
                <div class="panel__container">
                    <TimeProject class="workers-time__worker" v-for="info in byProjects" :info="info" :key="info.project_id">

                    </TimeProject>
                </div>
            </vue-simplebar>
        </div>
    </div>
</template>

<script>
  import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
  import TimeProject from './TimeProject.vue';

  export default {
    name: "TheTimeProjects",
    components: {
      TimeProject
    },
    computed: {
      ...mapGetters({
        getProjectById: 'project/getProjectById'
      }),
      ...mapState('time', [
        'fromDateProjects',
        'toDateProjects',
        'byProjects'
      ]),
    },
    data() {
      return {
        fromDate: null,
        toDate: null,
        initialized: false,
      }
    },
    mounted() {
      this.fetchProjects();
      this.fromDate = this.$moment(this.fromDateProjects).format();
      this.toDate = this.$moment(this.toDateProjects).format();

      this.initialized = true;
    },
    methods: {
      ...mapActions({
        fetchProjects: 'time/fetchProjects',
      }),
      ...mapMutations({
        setFromDateProjects: 'time/setFromDateProjects',
        setToDateProjects: 'time/setToDateProjects',
      }),
      onInput() {
        this.setFromDateProjects(this.$moment(this.fromDate).format('YYYY-MM-DD HH:mm:ss'));
        this.setToDateProjects(this.$moment(this.toDate).format('YYYY-MM-DD HH:mm:ss'));
        this.fetchProjects();
      },
      setDates(to) {
        const startDay = {hour:0,minute:0,second:0,millisecond:0};
        const endDay = {hour:23,minute:59,second:59,millisecond:0};

        if (to === 'tomorrow') {
          const tomorrow = this.$moment().subtract(1, "days");

          this.fromDate = tomorrow.set(startDay).format();
          this.toDate = tomorrow.set(endDay).format();
        }
        if (to === 'today') {
          const today = this.$moment();

          this.fromDate = today.set(startDay).format();
          this.toDate = today.set(endDay).format();
        }
        this.onInput()
      }
    }
  }
</script>

<style>

</style>