const LocalService = {
  getItem(name, def) {
    const value = window.localStorage.getItem(name);
    return value || def;
  },
  setItem(name, value) {
    window.localStorage.setItem(name, value);
  },
  getObject(name, def) {
    const item = this.getItem(name);
    if (item) {
      return JSON.parse(item);
    }
    return def;
  },
  setObject(name, obj) {
    this.setItem(name, JSON.stringify(obj));
  },
};

export default LocalService;