<template>
    <div class="loading-worker">
        <div class="loading-worker__head">
            <div class="loading-worker__head-container">
                <div class="loading-worker__who">
                    <div class="loading-worker__avatar">
                        <TheAvatar :worker="worker"></TheAvatar>
                    </div>
                    <div class="loading-worker__name">
                        {{worker.name}}
                    </div>
                </div>

                <div class="loading-worker__counter">
                    {{tasksCount}} задач
                </div>
            </div>
        </div>
        <div class="loading-worker__tasks">
            <div class="loading-worker__tasks-container">
                <div class="loading-worker__inner">
                    <LoadingGroupWorkerStatus
                            class="loading-worker__tasks-status"
                            name="Progress"
                            :tasks="this.progressTasks">
                    </LoadingGroupWorkerStatus>
                    <LoadingGroupWorkerStatus
                            class="loading-worker__tasks-status"
                            name="Todo, Bug"
                            :tasks="this.todoTasks">
                    </LoadingGroupWorkerStatus>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import TheAvatar from '../../../components/ui/TheAvatar.vue';
  import LoadingGroupWorkerStatus from './LoadingGroupWorkerStatus.vue';

  export default {
    name: "LoadingGroupWorker",
    props: {
      worker: {
        type: Object,
        required: true
      }
    },
    components: {
      TheAvatar,
      LoadingGroupWorkerStatus
    },
    computed: {
      tasksCount() {
        return this.worker.tasks.length;
      },
      progressTasks() {
        return this.worker.tasks.filter((task) => {
            return task.status == 50;
        })
      },
      todoTasks() {
        return this.worker.tasks.filter((task) => {
          return task.status != 50;
        })
      },
    }
  }
</script>

<style>

</style>