<template>
  <div class="tags-add">
    <div class="tags-add__top">
      <a href="javascript:void(0);" class="tags-add__back-icon" @click.prevent="handleClickBack">
        <IconBackSmall/>
      </a>
      <span class="tags-add__title">Создание метки</span>
      <a href="javascript:void(0);" class="tags-add__close-icon" @click.prevent="handleClickClose">
        <IconCrossGrey/>
      </a>
    </div>
    <div class="tags-add__input">
      <TagsCreatorInput/>
    </div>
    <div class="tags-add__color-list">
      <TagsCreatorColorList
        :colors="colors"
        :tagForm="tag"
      />
    </div>
    <div class="tags-add__bottom">
      <a href="javascript:void(0)" class="tags-add__button button button_alert" @click.prevent="handleSaveClick">
        Создать
      </a>
      <a href="javascript:void(0)" class="tags-add__button button" @click.prevent="handleDeleteClick">
        Отменить
      </a>
    </div>
  </div>
</template>

<script>
  import IconBackSmall from "../../../icons/IconBackSmall.vue";
  import IconCrossGrey from "../../../icons/IconCrossGrey.vue";
  import TagsCreatorInput from "./TagsCreatorInput.vue";
  import TagsCreatorColorList from "./TagsCreatorColorList.vue";
  import { mapState } from "vuex";
  import Button from "../../ui/Button.vue";
  export default {
    name: "TagsCreator",
    components: {Button, TagsCreatorColorList, TagsCreatorInput, IconCrossGrey, IconBackSmall},
    computed: {
      ...mapState('tag', [
        'colors',
        'tag'
      ])
    },
    methods: {
      handleClickClose() {
        this.$emit('crossClicked');
        this.$store.commit('tag/clearColor');
        this.$store.commit('tag/clearName');
      },
      handleClickBack() {
        this.$emit('backClicked');
        this.$store.commit('tag/clearColor');
        this.$store.commit('tag/clearName');
      },
      handleDeleteClick() {
        this.$store.commit('tag/clearColor');
        this.$store.commit('tag/clearName');
      },
      async handleSaveClick() {
        if (this.tag.tagName === '') {
          return false;
        }
        await this.$store.dispatch('tag/storeTag', this.tag);
        this.$emit('backClicked');
      }
    }
  }
</script>

<style lang="scss">
  .tags-add {
    position: absolute;
    width: 100%;
    min-height: 268px;
    background: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(32, 38, 47, 0.09);
    display: flex;
    flex-direction: column;
    z-index: 3;
    &__top {
      padding: 0 24px;
      display: flex;
      align-items: center;
    }
    &__back-icon {
      padding: 12px;
      margin-left: -12px;
      margin-right: 2px;
    }
    &__title {
      font-size: 12px;
      color: rgba(12, 34, 68, 0.32);
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.07em;
      flex-grow: 1;
    }
    &__close-icon {
      padding: 12px;
      margin-right: -12px;
    }
    &__input {
      padding: 0 24px;
      margin-bottom: 15px;
    }
    &__color-list {
      padding: 0 24px;
      flex: 1 1 auto;
    }
    &__bottom {
      padding: 0 24px 15px 24px;
      display: flex;
      justify-content: space-between;
    }
    &__button {
      width: 106px;
    }
    
  }
</style>