<template>
    <div class="task-index__support">
        <input type="checkbox" @change="change" :id="'time-estimate-' + task.id" v-model="task.isTimeEstimate" class="task-index__support-checkbox">
        <label :for="'time-estimate-' + task.id" class="task-index__support-label">
            Оценка времени
        </label>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  export default {
    name: "TaskTimeEstimateSupport",
    computed: {
      ...mapState('task', [
        'task'
      ]),
    },
    methods: {
      ...mapActions('task', [
        'storeTimeEstimate'
      ]),
      change() {
        this.storeTimeEstimate(this.task.isTimeEstimate);
      }
    }
  }
</script>

<style>

</style>