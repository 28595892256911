<template>
    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: Sketch 56.3 (81716) - https://sketch.com -->
        <title>Group 24</title>
        <desc>Created with Sketch.</desc>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Все-задачи-Copy-6" transform="translate(-417.000000, -274.000000)" stroke="#C1C4D3" stroke-width="2">
                <g id="Group-15" transform="translate(401.000000, 253.000000)">
                    <g id="Group-26" transform="translate(17.000000, 22.000000)">
                        <g id="Group-24">
                            <circle id="Oval" cx="8" cy="8" r="8"></circle>
                            <polyline id="Line-5" stroke-linecap="square" points="8.0090332 4.11621094 8.0090332 9.32128906 11.0065918 10.7712402"></polyline>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
  export default {
    name: "IconTimes"
  }
</script>

<style>

</style>