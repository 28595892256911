<template>
  <KanbanLayout>
    <h2 slot="title">
      Мои планы
    </h2>

    <Tabs
      slot="tabs"
    />

    <div slot="kanban">
        <TheMyChecklistList/>
    </div>
  </KanbanLayout>
</template>

<script>
  import KanbanLayout from "../layouts/KanbanLayout.vue";
  import Tabs from "../components/app/index/Tabs.vue";
  import TheMyChecklistList from "../components/app/checklist/my/TheMyChecklistList";

  export default {
    name: "TheChecklistTemplates",
    components: {
      TheMyChecklistList,
      KanbanLayout,
      Tabs,
    },
  }
</script>

<style>

</style>