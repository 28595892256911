<template>
  <KanbanLayout>
    <h2 slot="title">
      Шаблоны планов
    </h2>

    <Tabs
      slot="tabs"
    />

    <div slot="kanban">
        <TheList/>
    </div>
  </KanbanLayout>
</template>

<script>
  import KanbanLayout from "../layouts/KanbanLayout.vue";
  import Tabs from "../components/app/index/Tabs.vue";
  import TheList from "../components/app/checklist/template/TheChecklistTemplateList";

  export default {
    name: "TheChecklistTemplates",
    components: {
      TheList,
      KanbanLayout,
      Tabs,
    },
  }
</script>

<style scoped>

</style>