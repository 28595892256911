<template>
  <div class="todo-list project-subpage project-subpage_background">
    <div class="todo-list__inner">
      <ul class="todo-list__list">
        <li class="todo-list__item" v-for="todo in todoes">
          <div class="todo-list__item-main">
            <input type="checkbox"
                   v-model="todo.isDone"
                   :id="`todo-${todo.id}`"
                   class="todo-list__input"
            >
            <label :for="`todo-${todo.id}`" class="todo-list__label">
              {{ todo.title }}
            </label>

            <div class="todo-list__edits">
              <a href="javascript:void(0);"
                 class="todo-list__edit-todo"
                 @click.stop.prevent="editTodo(todo)"
                 v-if="editingTodo !== todo"
              >
                <span class="todo-list__edit-icon">
                  <img src="/static/images/svg/todo-edit.svg" alt="">
                </span>
              </a>

              <a href="javascript:void(0);"
                 class="todo-list__remove-todo"
                 @click.stop.prevent="removeTodo(todo)"
              >
                <span class="todo-list__edit-icon">
                  <img src="/static/images/svg/todo-remove.svg" alt="">
                </span>
              </a>
            </div>
          </div>

          <div class="todo-list__item-edit" v-if="editingTodo === todo">
            <textarea-autosize
                placeholder="Редактирование пункта..."
                name="edit_todo"
                id="edit_todo"
                v-model="editTodoTitle"
                class="todo-list__textarea-edit"
                :min-height="41"
                :maxHeight="41"
                @focus.native="editOnFocusListener"
                @blur.native="editOnBlurListener"
            ></textarea-autosize>

            <div class="todo-list__item-edits">
              <a href="javascript:void(0);"
                 class="todo-list__item-edit-submit"
                 @click.stop.prevent="submitEdit(todo)"
              >
                <span class="todo-list__item-edit-icon">
                  <img src="/static/images/svg/check-white.svg" alt="">
                </span>
              </a>

              <a href="javascript:void(0);"
                 class="todo-list__item-edit-cancel"
                 @click.stop.prevent="cancelEdit(todo)"
              >
                <span class="todo-list__item-edit-icon">
                  <img src="/static/images/svg/todo-remove.svg" alt="">
                </span>
              </a>
            </div>
          </div>
        </li>
      </ul>

      <div class="todo-list__bottom">
        <textarea-autosize
            placeholder="Новый пункт..."
            name="new_todo"
            id="new_todo"
            v-model="newTodoTitle"
            class="todo-list__textarea-new"
            :min-height="58"
            :maxHeight="58"
            @focus.native="addOnFocusListener"
            @blur.native="addOnBlurListener"
        ></textarea-autosize>

        <div class="todo-list__create-button">
          <Button :primary="true" @click="createTodo">
            <img src="/static/images/svg/submit-arrow.svg" alt="">
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Button from '../../ui/Button.vue';

export default {
  name: 'TheMyToDoList',
  props: {
    id: Number
  },
  components: {
    Button,
  },
  data() {
    return {
      newTodoTitle: '',
      editTodoTitle: '',
      editingTodo: null,
      addListener: (e) => {
        if (e.key === 'Escape') {
          e.preventDefault();
          document.getElementById('new_todo').blur();
        }

        if (e.key === 'Enter') {
          e.preventDefault();
          this.createTodo();
        }
      },
      editListener: (e) => {
        if (e.key === 'Escape') {
          e.preventDefault();
          this.cancelEdit();
        }

        if (e.key === 'Enter') {
          e.preventDefault();
          this.submitEdit(this.editingTodo);
        }
      },
    };
  },
  computed: {
    ...mapState('todoes', [
      'todoes'
    ]),
    ...mapGetters('worker', [
      'getWorkerById'
    ])
  },
  methods: {
    ...mapActions('todoes', [
      'fetch',
      'store',
      'update',
      'delete'
    ]),
    createTodo() {
      if (this.newTodoTitle === '') {
        document.getElementById('new_todo').classList.add('_error');
      } else {
        document.getElementById('new_todo').classList.remove('_error');
        this.store({ title: this.newTodoTitle });
        this.newTodoTitle = '';
      }
    },
    editTodo(todo) {
      this.editTodoTitle = todo.title;
      this.editingTodo = todo;
      setTimeout(() => {
        document.getElementById('edit_todo').focus();
      }, 100);
    },
    removeTodo(todo) {
      this.delete(todo.id);
    },
    submitEdit(todo) {
      this.update({ id: todo.id, title: this.editTodoTitle });
      this.editTodoTitle = '';
      this.editingTodo = null;
    },
    cancelEdit() {
      this.editTodoTitle = '';
      this.editingTodo = null;
    },
    addOnFocusListener(e) {
      document.addEventListener('keydown', this.addListener);
    },
    addOnBlurListener(e) {
      document.getElementById('new_todo').classList.remove('_error');
      document.removeEventListener('keydown', this.addListener);
    },
    editOnFocusListener(e) {
      document.addEventListener('keydown', this.editListener);
    },
    editOnBlurListener(e) {
      document.removeEventListener('keydown', this.editListener);
    }
  },
  mounted() {
    this.fetch();
  }
};
</script>

