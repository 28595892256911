<script>
  import { mapActions, mapState } from "vuex";

  export default {
    name: "TimelineContainer",
    computed: {
      ...mapState('timeline', [
        'timelines',
        'days'
      ]),
    },
    data() {
      return {
        interval: null,
        currentProjectId: this.projectId
      }
    },
    methods: {
      ...mapActions({
        fetch: 'timeline/fetchTimeline',
        refresh: 'timeline/refreshTimeline'
      }),

      init() {
        this.fetch();
      }
    },

    created() {
      this.init();
      this.interval = setInterval(() => {
        this.refresh();
      }, 30 * 1000);
    },

    beforeDestroy() {
      clearInterval(this.interval);
    },

    render() {
      return this.$scopedSlots.default({
        timelines: this.timelines,
        days: this.days
      })
    },
  }
</script>