<template>
    <div class="tabs">
        <router-link :to="{name: 'projects'}" class="tabs__info-tab-item">Задачи</router-link>
        <router-link :to="{name: 'manager'}" class="tabs__info-tab-item">Панель менеджера</router-link>
        <router-link :to="{name: 'time'}" class="tabs__info-tab-item">Машина времени</router-link>
        <!--\
            <router-link :to="{name: 'timeline'}" class="tabs__info-tab-item">Таймлайн</router-link>
            <router-link :to="{name: 'membership'}" class="tabs__info-tab-item">Команды</router-link>
        -->
        <router-link :to="{name: 'checklists_my'}" class="tabs__info-tab-item">Планы</router-link>
        <router-link :to="{name: 'todoes_my'}" class="tabs__info-tab-item">Чеклист</router-link>
    </div>
</template>

<script>
  export default {
    name: "Tabs",
  }
</script>

<style>

</style>