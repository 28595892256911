<template>
  <svg width="10px" height="7px" viewBox="0 0 10 7" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
    <title>Line 2 Copy 9</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
      <g id="Все-задачи-Copy-2" transform="translate(-1552.000000, -116.000000)" stroke="#FFFFFF" stroke-width="2">
        <g id="Group-13" transform="translate(1405.000000, 106.000000)">
          <g id="Group-10">
            <polyline id="Line-2-Copy-9" points="149 12 151.952288 15 155 12"></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IconArrowDownWhiteSmall"
  }
</script>

<style>

</style>