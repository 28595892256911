import ApiService from './api.service';

const TimelineService = {
  async getTimeline() {
    const url = '/api/time/timeline';
    const requestData = {
      url,
      method: 'get',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};

export default TimelineService;