<template>
	<ul class="task-index__comments-list comments-list">
		<TaskCommentsItem v-for="comment in comments" :key="comment.id"
		                  :comment="comment"
		/>
	</ul>
</template>

<script>
  import TaskCommentsItem from "./TaskCommentsItem.vue";
  import { mapGetters } from "vuex";
  export default {
    name: "TaskCommentsList",
    components: {TaskCommentsItem},
	  computed: {
      ...mapGetters('task', [
        'comments'
      ])
	  }
	  
  }
</script>

<style>

</style>