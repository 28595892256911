<template>
  <svg width="17px" height="19px" viewBox="0 0 17 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
    <title>Group 8</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Все-задачи" transform="translate(-44.000000, -91.000000)" stroke="#D0D4E6" stroke-width="3">
        <g id="Group-22">
          <g id="Group-7" transform="translate(32.000000, 81.000000)">
            <g id="Group-8" transform="translate(14.000000, 12.000000)">
              <circle id="Oval" cx="5.5" cy="5.5" r="5.5"></circle>
              <path d="M9.4,10.4166667 L12.6,14.1666667" id="Line-2" stroke-linecap="square"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IconSearch"
  }
</script>

<style>

</style>