<template>
  <div class="task-index__deadline" :class="{'isExpired': isTaskExpired, 'isLocked': isTaskLocked}">
    <div class="task-index__start-at start-at-datepicker">
      <span class="task-index__deadline-label">Начало</span>
      <DatePicker
          v-if="dateTimeStart"
          v-model="dateTimeStart"
          value-type="format"
          format="DD.MM.YYYY"
          @input="onInputStart"
          :lang="lang"
      />
    </div>

    <div class="task-index__deadline-inner deadline-datepicker">
      <span class="task-index__deadline-label" v-if="isTaskExpired">просрочен</span>
      <span class="task-index__deadline-label" v-else>срок</span>
      <!--<div class="task-index__deadline-form">-->
        <!--<input type="checkbox" class="task-index__deadline-checkbox" id="checkbox">-->
        <!--<span class="task-index__deadline-date" v-if="date">{{date}}</span>-->
      <!--</div>-->
      <!--<a href="javascript:void(0)" class="task-index__link">Добавить срок</a>-->
<!--      <datetime-->
<!--        type="datetime"-->
<!--        placeholder="Введите дату"-->
<!--        format="d LLL HH:mm"-->
<!--        :class="{'isExpired': isTaskExpired}"-->
<!--        class="theme-grey"-->
<!--        input-class="datepicker"-->
<!--        v-model="dateTime"-->
<!--        :phrases="{ cancel: 'Отменить', ok: 'Выбрать'}"-->
<!--        @input="onInput"-->
<!--      >-->
<!--      </datetime>-->

      <DatePicker
          v-if="dateTime"
          v-model="dateTime"
          value-type="format"
          format="DD.MM.YYYY"
          @input="onInput"
          :disabled-date="getDisabledDates"
          :lang="lang"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    name: "TaskDeadline",
    components: {
      DatePicker
    },
    props: {
      task: Object
    },
    data() {
      return {
        dateTime: null,
        dateTimeStart: null,
        taskStatus: {
          isExpired: false,
          isReady: false,
        },
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
            monthsShort: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
            weekdaysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
          },
        },
      }
    },
    computed: {
      isTaskExpired() {
        const dateNow = new Date();
        const deadline = new Date(this.$moment(this.dateTime, 'DD.MM.YYYY').add(1,'days').format('YYYY-MM-DD'));
        if (dateNow > deadline) {
          this.taskStatus.isExpired = true;
          return this.taskStatus.isExpired;
        }
      },
      isTaskLocked() {
        const dateNow = new Date();
        const start = new Date(this.$moment(this.dateTimeStart, 'DD.MM.YYYY').format('YYYY-MM-DD'));
        return (dateNow <= start);
      },
    },
    methods: {
      onInput() {
        let deadline = this.$moment(this.dateTime, 'DD.MM.YYYY').format('YYYY-MM-DD HH:mm:ss');
        if (!this.dateTime) {
          // в dateTime можно вернуть null, но тогда после того, как мы нажмен на крестик,
          // исчезнет все поле. Если юзаем new Date, будет просто пустое поле.
          this.dateTime = new Date();
          deadline = null;
        }
        const payload = { taskId: this.task.id, deadline: deadline};
        this.$store.dispatch('task/storeDeadline', payload);
      },
      onInputStart() {
        let deadline = this.$moment(this.dateTimeStart, 'DD.MM.YYYY').format('YYYY-MM-DD HH:mm:ss');
        if (!this.dateTimeStart) {
          // в dateTime можно вернуть null, но тогда после того, как мы нажмен на крестик,
          // исчезнет все поле. Если юзаем new Date, будет просто пустое поле.
          this.dateTimeStart = new Date();
          deadline = null;
        }
        const payload = { taskId: this.task.id, start_at: deadline};
        this.$store.dispatch('task/storeStartAt', payload);
      },
      getDisabledDates(date) {
        let today = new Date();

        if (today.getHours() < 14) {
          today.setDate(today.getDate() - 1);
        }

        return date < today;
      }
    },
    mounted() {
      this.dateTime = this.$moment(this.task.deadline).format('DD.MM.YYYY');
      this.dateTimeStart = this.$moment(this.task.start_at).format('DD.MM.YYYY');
    }
  }
</script>

<style lang="scss">
  .deadline-datepicker {
    .theme-grey {
      input {
        padding-left: 54px;
      }
    }
  }

  .isExpired {
    background: #FFECF0;

    input {
      background: #FFECF0;
      color: #FF234C;
    }
  }

  .isLocked {
    background: #f1ffec;

    input {
      background: #f1ffec;
      color: #538611;
    }
  }
</style>