<template>
  <div class="tasks-column" :class="{'tasks-column_adding': isAddingTask}" ref="column">
    <div class="tasks-column__content">
      <div class="tasks-column__head">
        <span class="tasks-column__title">{{status.status_name}}</span>
        <a href="#" class="tasks-column__menu">
          <span class="tasks-column__dot"></span>
          <span class="tasks-column__dot"></span>
          <span class="tasks-column__dot"></span>
        </a>
      </div>
      <vue-simplebar class="tasks-column__simplebar" :class="{'tasks-column__simplebar_nofoot': canAddTask}">
        <div class="tasks-column__list tasks-list">
          <draggable
            v-model="status.tasks"
            group="status.tasks"
            ghost-class="ghost"
            @add.stop="onAdd"
            @start="onStartDrag"
            @end="onEndDrag"
            :move="checkMove"
            draggable=".item">
            <div class="tasks-list__item" v-for="task in status.tasks" :key=task.id
                :taskId="task.id"
                :class="{'item': !isNewTask && !isEditTask}"
            >
              <ProjectKanbanStatusTask
                :task="task"
                @editTask="onEditTask"
                @editedTask="onEditedTask"
              />
            </div>
          </draggable>
        </div>
      </vue-simplebar>

      <div class="tasks-column__create-form" v-if="isAddingTask">
        <div class="tasks-column__create-area-wrapper">
          <textarea-autosize
            placeholder="Название задачи"
            @input.native="onInputName"
            autofocus
            rows="1"
            class="tasks-column__create-form-name"
          >
          </textarea-autosize>
          <textarea-autosize
              placeholder="Описание"
              @input.native="onInputDescription"
              autofocus
              rows="1"
              class="tasks-column__create-form-description"
          >
          </textarea-autosize>
          <div class="tasks-column__create-area-options">
            <div class="tasks-column__create-area-options-left">
              <div class="tasks-column__create-area-date date-select-kanban"
                   :class="{_active: taskDate}"
              >
                <DatePicker
                    v-model="taskDate"
                    value-type="format"
                    format="DD.MM.YYYY"
                    :disabled-date="getDisabledDates"
                />
              </div>

              <a href="javascript:void(0);"
                 class="tasks-column__create-area-option"
                 @click.stop.prevent="openSelectPerformer"
              >
                <span class="tasks-column__create-area-option-icon">
                  <img src="/static/images/svg/executor.svg" alt="">
                </span>
              </a>

              <a href="javascript:void(0);"
                 class="tasks-column__create-area-option upload-button"
              >
                <span class="tasks-column__create-area-option-icon">
                  <img src="/static/images/svg/clip-dark.svg" alt="">
                </span>
              </a>
            </div>

            <div class="tasks-column__create-area-options-right">
              <TheAvatar :worker="getWorkerById(task.performer_id)"
                         :small="true"
                         v-if="getWorkerById(task.performer_id)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tasks-column__footer" v-if="canAddTask">
      <a href="#" class="tasks-column__add-task" @click.prevent="handleClick" v-if="!isAddingTask">
        <span class="tasks-column__list-plus">
          <img src="/static/images/svg/white-plus.svg" alt="">
        </span>
        <span class="tasks-column__add">Добавить задачу</span>
      </a>
      <div class="tasks-column__save-task" v-else>
        <div class="tasks-column__project-wrap">
          <a href="javascript:void(0);"
             class="tasks-column__project"
             @click.prevent="openProjectModal"
          >
            <span class="tasks-column__project-name">
              {{ projectName }}
            </span>
            <span class="tasks-column__project-icon">
              <img src="/static/images/svg/arrow-down-sm.svg" alt="">
            </span>
          </a>

          <div class="tasks-column__project-modal" v-if="isProjectModal">
            <BaseSelect
                title="Проект"
                name="Проект"
                :value="taskProjectId"
                :options="projectsChoices"
                @input="onProjectInput"
                @close="onProjectInputClose"
            >
              <template v-slot:option="{option}">
                <a href="javascript:void(0)" class="select-item__link">
                  <div class="select-item__name">
                    {{ option.name }}
                  </div>
                  <div class="select-item__icon" v-if="option.id === taskProjectId">
                    <IconCheckRed/>
                  </div>
                </a>
              </template>
            </BaseSelect>
          </div>
        </div>

        <a href="javascript:void(0);"
           class="tasks-column__save-task-cancel"
           @click.prevent="handleCrossClick"
        >
          <img src="/static/images/svg/cross-dark-grey.svg" alt="">
        </a>
        <div class="tasks-column__save-task-button">
          <Button :primary="true" @click="handleCreate">
            <img src="/static/images/svg/submit-arrow.svg" alt="">
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';
  import IconWhitePlus from "../../../icons/IconWhitePlus.vue";
  import ProjectKanbanStatusTask from "./ProjectKanbanStatusTask.vue";
  import Button from "../../ui/Button.vue";
  import IconCrossGrey from "../../../icons/IconCrossGrey.vue";
  import IconCrossDarkGrey from "../../../icons/IconCrossDarkGrey.vue";
  import {mapState, mapGetters, mapActions, mapMutations} from "vuex";
  import PerformerSelectKanban from "./PerformerSelectKanban.vue";
  import TheAvatar from "../../ui/TheAvatar.vue";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import UploadService from "../../../services/upload.service";
  import TaskAttachments from "../task/TaskAttachments.vue";
  import BaseSelect from "../../ui/multiselect/BaseSelect.vue";
  import IconCheckRed from "../../../icons/IconCheckRed.vue";

  export default {
    name: "ProjectKanbanStatusItem",
    components: {
      IconCheckRed,
      BaseSelect,
      TaskAttachments,
      TheAvatar,
      IconCrossDarkGrey,
      IconCrossGrey,
      Button,
      ProjectKanbanStatusTask,
      IconWhitePlus,
      draggable,
      DatePicker
    },
    props: {
      status: Object
    },
    computed: {
      ...mapState('project', [
        'taskNameInput',
      ]),
      ...mapGetters('project', [
        'projectsChoices'
      ]),
      ...mapState('worker', [
        'userWorker',
      ]),
      ...mapGetters('worker', [
        'getWorkerById'
      ]),
      ...mapGetters('filter', [
        'filters'
      ]),
      ...mapGetters('task', [
        'task'
      ]),
      canAddTask() {
        return [
          10,
          20,
          30
        ].indexOf(this.status.status) > -1;
      },
      projectName() {
        if (this.projectsChoices.find((item) => item.id === this.taskProjectId)) {
          return this.projectsChoices.find((item) => item.id === this.taskProjectId).name;
        }
        return 'Проект';
      }
    },
    data() {
      return {
        isAddingTask: false,
        taskName: '',
        taskDescription: '',
        taskDate: null,
        isNewTask: false,
        isEditTask: false,
        taskProjectId: null,
        isProjectModal: false
      }
    },
    methods: {
      ...mapActions('project', [
        'fetchAllProjects'
      ]),
      ...mapMutations('task', [
        'clearTask'
      ]),
      checkMove(evt) {
        if (this.isNewTask) {
          return false;
        }
        return true;
      },
      async onAdd(evt) {
        const statusId = this.status.status;
        const taskId = evt.item.getAttribute('taskId');
        const task = this.status.tasks.filter(task => task.id == taskId)[0];
        task.status = statusId;
        const payload = {taskId, status: task.status};

        try {
          await this.$store.dispatch('task/updateTaskStatus', payload);
        } catch (e) {
          if (e.response.data.errors) {
            e.response.data.errors.forEach((errorText) => {
              this.$notify({
                group: 'foo',
                title: 'Ошибка переноса задачи',
                text: errorText
              })
            })
          }
        }
        this.$emit('updatedStatus');
      },
      onStartDrag() {
        document.body.classList.add('drag-task');
      },
      onEndDrag() {
        document.body.classList.remove('drag-task');
      },
      handleClick() {
        this.isAddingTask = true;
        this.isNewTask = true;

        setTimeout(() => {
          this.upload = new UploadService({
            button: '.upload-button',
            endpoint: '/api/file'
          });
        }, 200);
      },
      handleCrossClick() {
        this.isAddingTask = false;
        this.isNewTask = false;
        this.taskName = '';
        this.taskDescription = '';
        this.taskDate = null;
        this.taskProjectId = null;
        this.clearTask();
      },
      onInputName(event) {
        this.taskName = event.target.value;
      },
      onInputDescription(event) {
        this.taskDescription = event.target.value;
      },
      onDateInput(date) {
        this.taskDate = date;
      },
      onEditTask() {
        this.isEditTask = true;
      },
      onEditedTask() {
        this.isEditTask = false;
      },
      async handleCreate() {
        if (this.taskName !== '') {
          let payload = {
            status: this.status.status,
            name: this.taskName,
            description: this.taskDescription,
            date: this.taskDate,
            performerId: this.task.performer_id,
            projectId: this.taskProjectId,
            workerId: this.userWorker.id,
            files: this.upload.fileIds
          };
          if (this.$route.params.projectId) {
            payload.projectId = this.$route.params.projectId;
          }
          await this.$store.dispatch('task/storeTask', payload);
          this.isAddingTask = false;
          this.isNewTask = false;
          this.$store.commit('project/clearTaskName');
          this.$emit('updatedStatus');
        }
      },
      openSelectPerformer() {
        this.$modal.show(
            PerformerSelectKanban,
            {},
            {width: '300', height: 'auto'},
        )
      },
      openProjectModal() {
        this.isProjectModal = true;
      },
      closeProjectModal() {
        this.isProjectModal = false;
      },
      onProjectInput(value) {
        this.taskProjectId = value;
        this.closeProjectModal();
      },
      onProjectInputClose() {
        this.taskProjectId = null;
        this.closeProjectModal();
      },
      getDisabledDates(date) {
        let today = new Date();

        if (today.getHours() < 14) {
          today.setDate(today.getDate() - 1);
        }

        return date < today;
      }
    },
    mounted() {
      this.fetchAllProjects();
    }
  }
</script>