<template>
  <li class='sidebar__item'>
    <router-link :to="{name: 'project_tasks', params: {projectId: project.id}}"
                 exact
                 class="sidebar__link"
                 :class="isActiveClass">
            <span class="sidebar__left">
              <span class="sidebar__name">
                {{project.name}}
              </span>
              <span class="sidebar__count" v-if="countTasks > 0">
                {{countTasks}}
              </span>
            </span>
            <span class="sidebar__fav">
                <FavButton :project="project"/>
            </span>

    </router-link>
  </li>
</template>

<script>
  import {mapState} from 'vuex';
  import FavButton from '../favorites/FavButton.vue';

  export default {
    name: "SidebarGroupsProjectsItem",
    components: { FavButton },
    props: {
      project: Object
    },
    computed: {
      ...mapState('project', [
        'currentProject',
      ]),
      ...mapState('project', [
        'tasks'
      ]),
      isActiveClass() {
        return this.project.id == this.$route.params.projectId ? 'active' : '';
      },
      countTasks() {
        return this.project.tasks_count;
      }
    }
  }
</script>

<style lang="scss">
  .sidebar__item {
    .sidebar__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .sidebar__left{
      display: flex;
      align-items: center;
    }
    .router-link-active {
      background-color: #FF234C;

      .sidebar__count {
        background-color: #ffffff;
        color: #000000;
      }
    }
  }
</style>