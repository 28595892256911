<template>
  <div class="TheFilter">
    <div class="TheFilter__tags-icon">
      <TheTagList :tags="tagsSelected"/>
    </div>
    <a href="#" class="TheFilter__link" @click.prevent="handleClick">
      <span class="TheFilter__label">Метки</span>
      <span class="TheFilter__icon" :class="{'TheFilter__icon_select-opened': isOpenFilter}"><IconArrowDownWhiteSmall/></span>
    </a>
    <transition name="fade">
      <div class="TheFilter__select" v-show="isOpenFilter">
        <TheModal
          @close="handleCrossClick"
        >
          <BaseMultiselect
            :options="tags"
            :selected="tagsSelected"
            title="Метки"
            placeholder="Найти метку"
            @close="handleCrossClick"
          >
            <template v-slot:default="slotProps">
              <BaseMultiselectItem
                :option="slotProps.option"
                :selected="slotProps.selected"
                @add="addTag"
                @remove="removeTag"
                v-slot="{option, isActive, handleClick}"
              >
                <div class="select-item">
                  <a href="javascript:void(0)" class="select-item__link" @click.prevent="handleClick">
                    <div class="select-item__avatar">
                      <TheTag :tag="option"/>
                    </div>
                    <div class="select-item__name" :style="{color: option.color}">
                      {{option.name}}
                    </div>
                    <div class="select-item__icon" v-if="isActive">
                      <IconCheckRed/>
                    </div>
                  </a>
                </div>
              </BaseMultiselectItem>
            </template>
          </BaseMultiselect>
        </TheModal>
      </div>
    </transition>
  </div>
</template>

<script>
  import IconArrowDownWhiteSmall from "../../../icons/IconArrowDownWhiteSmall.vue";
  import TheModal from "../../ui/TheModal.vue";
  import BaseMultiselect from "../../ui/multiselect/BaseMultiselect.vue";
  import { mapState, mapGetters, mapActions } from "vuex";
  import BaseMultiselectItem from "../../ui/multiselect/BaseMultiselectItem.vue";
  import IconCheckRed from "../../../icons/IconCheckRed.vue";
  import TheTag from "../../ui/TheTag.vue";
  import TheTagList from "../../ui/TheTagList.vue";

  export default {
    name: "TagFilter",
    components: {
      TheTagList,
      TheTag, IconCheckRed, BaseMultiselectItem, BaseMultiselect, TheModal, IconArrowDownWhiteSmall},
    computed: {
      ...mapState('tag', [
        'tags'
      ]),
      ...mapState('filter', [
        'tagsSelected'
      ]),
      ...mapGetters('filter', [
        'filters'
      ])
    },
    data() {
      return {
        isOpenFilter: false
      }
    },
    methods: {
      ...mapActions({
        storeTag: 'filter/addTag',
        deleteTag: 'filter/removeTag',
        fetchTasks: 'project/fetchTasks',
      }),
      addTag(value) {
        this.storeTag(value);
        this.fetchTasks({id: this.$route.params.projectId, filters: this.filters});
      },
      removeTag(value) {
        this.deleteTag(value);
        this.fetchTasks({id: this.$route.params.projectId, filters: this.filters});
      },
      handleClick() {
        this.isOpenFilter = !this.isOpenFilter;
      },
      handleCrossClick() {
        this.isOpenFilter = false;
      }
    }
  }
</script>

<style scoped>

</style>