<script>
  import { mapActions, mapGetters, mapState } from "vuex";

  export default {
    name: "ProjectContainer",
    computed: {
      ...mapState('project', [
        'currentProject'
      ]),
    },
    watch: {
      '$route'(to, from) {
        this.fetchProject(to.params.projectId);
      }
    },
    methods: {
      ...mapActions({
        fetchProject: 'project/fetchProject'
      }),
    },
    
    created() {
      this.fetchProject(this.$route.params.projectId);
    },
   
    render() {
      return this.$scopedSlots.default({
        project: this.currentProject,
      })
    },
  }
</script>

