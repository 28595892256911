import ApiService from './api.service';

const ProjectChecklistService = {
  async all(projectId) {
    const requestData = {
      url: `/api/checklist/project/${projectId}/all`,
      method: 'get',
    };
    return ApiService.request(requestData);
  },
  async get(id) {
    const requestData = {
      url: `/api/checklist/project/get/${id}`,
      method: 'get',
    };
    return ApiService.request(requestData);
  },
  async create(projectId, { checklist, items }) {
    const requestData = {
      url: `/api/checklist/project/${projectId}/create`,
      method: 'post',
      data: {
        checklist,
        items,
      },
    };
    return ApiService.request(requestData);
  },
  async update(id, { checklist, items }) {
    const requestData = {
      url: `/api/checklist/project/update/${id}`,
      method: 'put',
      data: {
        checklist,
        items,
      },
    };
    return ApiService.request(requestData);
  },
  async delete(id) {
    const requestData = {
      url: `/api/checklist/project/delete/${id}`,
      method: 'delete',
    };
    return ApiService.request(requestData);
  },
};

export default ProjectChecklistService;