<template>
  <div class="task-options-status">
    <div class="task-options-status__top">
      <span class="task-options-status__title">Перемещение</span>
      <a href="javascript:void(0)" class="task-options-status__icon" @click.prevent="handleClick">
        <IconCrossGrey/>
      </a>
    </div>
    <div class="task-options-status__form">
        <span class="task-options-status__label">
            Из
          <strong>{{statusName}}</strong>
            в
        </span>
      <div class="task-options-status__select">
        <multiselect
          v-model="value"
          deselect-label="Can't remove this value"
          :show-labels="false"
          placeholder="BackLog"
          :options="statusesName"
          :searchable="false"
          :allow-empty="false"
          :max-height="350"
        >
          <template slot="singleLabel"
                    slot-scope="{ option }"
          >
            <strong>{{ option }}</strong>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="task-options-status__button">
      <Button :wide="true" :primary="true" @click="handleButtonClick">Изменить статус</Button>
    </div>
    <div class="task-options-status__parent">
      <div class="task-options-status__top">
        <span class="task-options-status__title">Изменить родителя</span>
      </div>
      <div class="task-options-status__form">
        <input type="text" placeholder="Укажите id нового родителя" class="input" v-model="parentId">
      </div>
      <div class="task-options-status__button">
        <Button :wide="true" :primary="true" @click="handleParentButtonClick">Изменить родителя</Button>
      </div>
    </div>
  </div>
</template>

<script>
  import IconCrossGrey from "../../../icons/IconCrossGrey.vue";
  import Multiselect from "vue-multiselect";
  import Button from "../../ui/Button.vue";
  import { mapState, mapGetters } from "vuex";
  import _ from "lodash";

  export default {
    name: "TaskOptionsStatus",
    components: {Button, IconCrossGrey, Multiselect},
    computed: {
      ...mapState('task', [
        'task',
      ]),
      ...mapState('project', [
        'statuses',
      ]),
      ...mapGetters('filter', [
        'filters',
      ]),
      statusName() {
        const task = this.$store.state.task.task;
        const statuses = this.$store.state.project.statuses;
        for (status in statuses) {
          if (status == task.status) {
            return statuses[status];
          }
        }
      },
      statusesName() {
        const statuses = this.$store.state.project.statuses;
        const statusesValues = Object.values(statuses);
        return statusesValues;
      },
    },
    data() {
      return {
        value: '',
        parentId: 0
      }
    },
    methods: {
      handleClick() {
        this.$emit('crossClicked');
      },
      async handleButtonClick() {
        let newStatus = null;
        for (let key in this.statuses) {
          if (this.statuses[key] === this.value) {
             newStatus = parseInt(key, 10);
          }
        }
        const payload = { taskId:this.task.id, newStatus, projectId: this.task.project_id };
        try {
          await this.$store.dispatch('task/updateStatus', payload);
          this.task.status = newStatus;
        } catch (e) {
          if (e.response.data.errors) {
            e.response.data.errors.forEach((errorText) => {
              this.$notify({
                group: 'foo',
                title: 'Ошибка переноса задачи',
                text: errorText
              })
            })
          }
        }

        this.$store.dispatch('project/fetchTasks', {id: this.$route.params.projectId, filters: this.filters});
      },
      async handleParentButtonClick() {
        const payload = { taskId:this.task.id, newParent: this.parentId, projectId: this.task.project_id };
        try {
          await this.$store.dispatch('task/updateParent', payload);
          this.task.parent_id = this.parentId;
        } catch (e) {
          if (e.response.data.errors) {
            e.response.data.errors.forEach((errorText) => {
              this.$notify({
                group: 'foo',
                title: 'Ошибка переноса задачи',
                text: errorText
              })
            })
          }
        }

        this.$store.dispatch('project/fetchTasks', {id: this.$route.params.projectId, filters: this.filters});
        window.location.refresh();
      }
    },
    mounted() {
      this.value = 'Backlog';
      this.parentId = this.task ? this.task.parent_id : '';
    }
  }
</script>

<style lang="scss">
  .multiselect {
    &__element {
      font-size: 15px;
    }
    
    &__single {
      font-size: 15px;
    }
  }
  
  .task-options-status {
    padding-bottom: 20px;
    position: absolute;
    min-height: 103px;
    width: 100%;
    background-color: #ffffff;
    z-index: 3;
    box-shadow: 0 20px 30px 0 rgba(32, 38, 47, 0.09);
    
    &__title {
      color: rgba(12, 34, 68, 0.32);
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.07em;
      font-weight: 500;
    }
    
    &__top {
      padding: 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    &__icon {
      display: block;
      padding: 13px;
      margin-right: -13px;
    }
    
    &__form {
      padding: 0 24px;
      font-size: 15px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    
    &__select {
      position: relative;
      top: 0.166em;
      width: 130px;
      .multiselect {
        width: 150px;
        &__content-wrapper {
          height: 350px;
        }
      }
    }
    
    &__label {
      color: #A9AEB9;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      
      strong {
        color: #3A3E44;
      }
    }
    &__button {
      padding: 0 24px;
    }

    &__parent {
      margin-top: 20px;
    }
  }
</style>