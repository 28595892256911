<template>
    <div class="task-subtasks">
        <header class="task-subtasks__head">
            <div class="task-subtasks__label">
                Подзадачи
            </div>

            <button class="button" @click.prevent="openAddForm">
                Добавить
            </button>
        </header>

        <div class="task-subtasks__body">
            <form action="" @submit.prevent="addSubtask" class="task-subtasks__form" v-if="formOpened">
                <div class="task-subtasks__form-body">
                    <textarea name="" v-model="newTaskText" placeholder="Укажите суть задачи" class="textarea task-subtasks__textarea"></textarea>
                </div>

                <footer class="task-subtasks__form-footer">
                    <button class="button button_alert" type="submit">
                        Создать
                    </button>

                    <button class="task-subtasks__form-close" @click.prevent="closeAddForm">
                        <IconCrossDarkGrey/>
                    </button>
                </footer>
            </form>

            <ul class="task-subtasks__list">
                <li class="task-subtasks__item" v-for="subtask in task.children">
                    <div class="task-subtasks__item-container">
                        <TaskSubtasksItem :task="subtask"/>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import IconCrossDarkGrey from "../../../icons/IconCrossDarkGrey";
import TaskService from "../../../services/task.service";
import {mapActions, mapState} from 'vuex';
import TaskSubtasksItem from "./TaskSubtasksItem";

export default {
  name: "TaskSubtasks",
  components: {TaskSubtasksItem, IconCrossDarkGrey},
  props: {
    task: Object,
    subtasks: Array
  },
  data() {
    return {
      formOpened: false,
      newTaskText: ''
    }
  },
  computed: {
    ...mapState('worker', {
      currentWorker: 'userWorker'
    }),
  },
  methods: {
    ...mapActions('task', [
      'refreshTask'
    ]),
    openAddForm() {
      this.formOpened = true;
    },
    closeAddForm() {
      this.formOpened = false;
    },
    async addSubtask() {

      // Сохраняем задачу, дожидаемся корректного ответа (200)

      try {
        const response = await TaskService.storeTask({
          'parentId': this.task.id,
          'status': 20,
          // Owner-ом задачи делаем того, кто ее ставит
          'workerId': this.currentWorker.id,
          'projectId': this.task.project_id,
          'name': this.newTaskText
        });

        if (response.status !== 200) {
            throw 'Incorrect status';
        }
      } catch (e) {
        this.$notify({
          group: 'foo',
          title: 'При сохранении подзадачи возникла ошибка'
        })
      }

      // Чистим форму
      this.newTaskText = '';

      // Обновляем родительскую задачу
      await this.refreshTask();
    }
  },
}
</script>

<style lang="scss">
.task-subtasks {
  margin-top: 52px;

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__label {
    font-weight: 500;
    font-size: 20px;
    padding-right: 12px;
  }

  &__form {
    border: 1px solid #EAECF2;
    box-shadow: 0 10px 20px rgba(44, 67, 101, 0.100524);
    z-index: 100;
    margin: 0 0 20px;
  }

  &__textarea {
    border: none;
    max-width: 100%;
    min-width: 100%;
  }

  &__form-footer {
    border-top: 1px solid #EAECF2;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__form-close {
    appearance: none;
    border: none;
    padding: 6px;
    display: block;
    background-color: transparent;
  }

  &__list {
    position: relative;
  }

  &__item-container {
    padding-left: 55px;

    &:after {
      position: absolute;
      width: 7px;
      height: 7px;
      left: 40px;
      top: 6px;
      background: #FFFFFF;
      border: 1px solid #EAECF2;
      content: '';
      border-radius: 10px;
      z-index: 1;
    }

    &:before {
      position: absolute;
      left: 0;
      width: 43px;
      height: 1px;
      top: 10px;
      background-color: #EAECF2;
      content: '';
    }

  }

  &__item {
    padding-bottom: 25px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: -10px;
      background-color: #EAECF2;
      width: 1px;
    }

    &:last-child {
      padding-bottom: 0;
      &:before {
        display: none;
      }
    }
  }
}
</style>