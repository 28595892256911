import ApiService from './api.service';
import TokenService from "./token.service";

class AuthenticationError {
  constructor(errorCode, message) {
    this.errorCode = errorCode;
    this.message = message;
  }
}

const AuthService = {
  login: async function (email, password) {
    const requestData = {
      method: 'post',
      url: '/api/login',
      data: {
        email,
        password,
      },
    };
    try {
      const response = await ApiService.request(requestData);
      TokenService.setToken(response.data.data.jwt);
      ApiService.setHeader();
      return response.data;
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.errors.details);
    }
  },
};

export { AuthService, AuthenticationError };