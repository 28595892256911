<template>
  <KanbanLayout>
    <h2 slot="title">
      Панель менеджера
    </h2>

    <Tabs
      slot="tabs"
    />

    <div slot="kanban">
      <div class="manager">
        <div class="manager__half">
          <TheLog/>
        </div>
        <div class="manager__half">
          <TheLoading/>
        </div>
      </div>
    </div>

    <BaseModal
      v-show="showModal"
      slot="modal"
    >
      <router-view name="task"/>
    </BaseModal>
  </KanbanLayout>
</template>

<script>
  import KanbanLayout from "../layouts/KanbanLayout.vue";
  import BaseModal from "../components/ui/BaseModal.vue";
  import Tabs from "../components/app/index/Tabs.vue";
  import TheLog from "../components/app/manager/TheLog.vue";
  import TheLoading from "../components/app/manager/TheLoading.vue";

  import ModalMixin from "../mixins/modal.mixin";

  export default {
    name: "TheManager",
    components: {
      TheLog,
      TheLoading,
      Tabs,
      KanbanLayout,
      BaseModal
    },
    mixins: [ModalMixin],
  }
</script>

<style scoped>

</style>