<template>
  <vue-simplebar>
    <ul class="options-tags-list" v-if="tags">
      <li class="options-tags-list__item" v-for="tag in tags" :key="tag.id">
        <TaskOptionsTagsItem
          :tag="tag" :task="task"
        />
      </li>
    </ul>
  </vue-simplebar>
</template>

<script>
  import {mapState, mapGetters, mapActions} from "vuex";
  import TaskOptionsTagsItem from "./TaskOptionsTagsItem.vue";

  export default {
    name: "TaskOptionsTagsList",
    components: {TaskOptionsTagsItem},
    computed: {
      ...mapGetters('tag', {
        'tags': 'filteredTags'
      }),
      ...mapState('task', [
        'task'
      ])
    },
    methods: {
      ...mapActions('tag', {
        fetchTags: 'fetchTags',
      })
    },
    mounted() {
      this.fetchTags()
    }
  }
</script>

<style scoped lang="scss">
  [data-simplebar] {
    height: 100px;
    width: 100%;
  }
  .options-tags-list {

  }
</style>