<template>
  <svg width="17px" height="18px" viewBox="0 0 17 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
    <title>Group 5</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <circle id="path-1" cx="10.5" cy="10.5" r="10.5"></circle>
    </defs>
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Все-задачи-Copy-3" transform="translate(-35.000000, -369.000000)">
        <g id="Group-22" transform="translate(-1.000000, -6.000000)">
          <g id="Group-18">
            <g id="Group-24" transform="translate(0.000000, 358.000000)">
              <g id="Group-5" transform="translate(34.000000, 15.000000)">
                <g id="Oval-+-Oval-Copy-Mask">
                  <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                  </mask>
                  <g id="Mask" fill-rule="nonzero"></g>
                  <circle id="Oval" stroke-opacity="0.2" stroke="#FFFFFF" stroke-width="2" fill-rule="nonzero" mask="url(#mask-2)" cx="10.5" cy="7" r="3.5"></circle>
                  <circle id="Oval-Copy" stroke-opacity="0.2" stroke="#FFFFFF" stroke-width="2" fill-rule="nonzero" mask="url(#mask-2)" cx="10.5" cy="21.875" r="7.875"></circle>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IconExecutorLarge"
  }
</script>

<style>

</style>