<template>
    <div class="base-modal">
        <div class="base-modal__overlay" @click="handleClose"></div>
        <slot/>
    </div>
</template>

<script>
  export default {
    name: "BaseModal",
    methods: {
      handleClose() {
        if (this.$route.params.projectId) {
          this.$router.push({name: 'project_tasks', params: {projectId: this.$route.params.projectId}})
        } else {
          this.$router.push({name: 'projects'})
        }
      }
    }
  }
</script>

<style lang="scss">
    .base-modal {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.0);
        }
        &__inner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
</style>