import LoadService from '../../services/load.service';

const loadModule = {
  namespaced: true,
  state: {
    loading: [],
    projectId: null,
  },
  getters: {
    byGroups(state) {
      const groups = [
        {
          name: 'Разработчики',
          type: 30,
          workers: [],
        },
        {
          name: 'Дизайнеры',
          type: 40,
          workers: [],
        },
        {
          name: 'Маркетологи',
          type: 20,
          workers: [],
        },
      ];
      state.loading.forEach((worker) => {
        groups.forEach((group, key) => {
          if (worker.type === group.type) {
            groups[key].workers.push(worker);
          }
        });
      });
      return groups;
    },
  },
  actions: {
    async fetchLoading({ dispatch, rootState, commit }, projectId) {
      commit('setProjectId', projectId);
      rootState.loading = true;
      await dispatch('refreshLoading');
      rootState.loading = false;
    },

    async refreshLoading({ commit, state }) {
      try {
        const response = await LoadService.getLog(state.projectId);
        commit('setLoading', response.data.data);
      } catch (e) {
        console.log(e);
      }
    },
  },

  mutations: {
    setLoading(state, data) {
      state.loading = data;
    },
    setProjectId(state, projectId) {
      state.projectId = projectId;
    },
  },
};

export default loadModule;