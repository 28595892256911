<template>
    <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
        <title>noun_Attachment_2389623</title>
        <desc>Created with Sketch.</desc>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Все-задачи" transform="translate(-802.000000, -267.000000)" fill="#D0D4E6" fill-rule="nonzero">
                <g id="Group-5" transform="translate(246.000000, -188.000000)">
                    <g id="Group-16-Copy-2">
                        <g id="Group-26-Copy" transform="translate(553.000000, 450.000000)">
                            <g id="noun_Attachment_2389623" transform="translate(13.000000, 13.000000) rotate(-315.000000) translate(-13.000000, -13.000000) translate(6.000000, 3.000000)">
                                <path d="M7,20 C10.8659932,20 14,16.8659932 14,13 L14,5 C13.9999999,2.2385763 11.7614237,1.29047749e-07 9,1.29047748e-07 C6.2385763,1.29047748e-07 4.00000007,2.2385763 4,5 L4,13 C4.00000003,14.6568542 5.34314578,15.9999999 7,15.9999999 C8.65685422,15.9999999 9.99999997,14.6568542 10,13 L10,5 C10,4.44771525 9.55228475,4 9,4 C8.44771525,4 8,4.44771525 8,5 L8,13 C8,13.5522847 7.55228475,14 7,14 C6.44771525,14 6,13.5522847 6,13 L6,5 C6.00000003,3.34314578 7.34314578,2.00000006 9,2.00000006 C10.6568542,2.00000006 12,3.34314578 12,5 L12,13 C12,15.7614237 9.76142375,18 7,18 C4.23857625,18 2,15.7614237 2,13 L2,7 C2,6.44771525 1.55228475,6 1,6 C0.44771525,6 0,6.44771525 0,7 L0,13 C0,16.8659932 3.13400675,20 7,20 Z" id="Path"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
  export default {
    name: "IconClip"
  }
</script>

<style>

</style>