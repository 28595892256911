<template>
  <div class="task-options-workers" :class="{'filter': filter}">
    <div class="task-options-workers__top">
      <span class="task-options-workers__title">Участники</span>
      <a href="javascript:void(0)" class="task-options-workers__icon" @click.prevent="handleClick"><IconCrossGrey/></a>
    </div>
    <div class="task-options-workers__search">
      <TaskOptionsWorkersSearch @input="onInput" :autofocus="true"/>
    </div>
    <TaskOptionsWorkersList
      @executorAdded="onExecutorAdded"
      @executorRemoved="onExecutorRemoved"
      :workers="filteredWorkers"
      @input="onInput"
    />
  </div>
</template>

<script>
  import Multiselect from "vue-multiselect";
  import { mapState, mapGetters } from "vuex";
  import IconCrossGrey from "../../../icons/IconCrossGrey.vue";
  import TaskOptionsWorkersSearch from "./TaskOptionsWorkersSearch.vue";
  import TaskOptionsWorkersList from "./TaskOptionsWorkersList.vue";
  import Matcher from "../../../../common/matcher";
  export default {
    name: "TaskOptionsWorkers",
    components: {TaskOptionsWorkersList, TaskOptionsWorkersSearch, IconCrossGrey, Multiselect},
    props: {
      filter: Boolean,
    },
    computed: {
      ...mapState ('task', [
        'task'
      ]),
      ...mapState('worker', [
        'workers'
      ])
    },
    data() {
      return {
        value: '',
        filteredWorkers: [],
      }
    },
    methods: {
      handleClick() {
        this.$emit('crossClicked');
      },
      onExecutorAdded(worker) {
        this.$emit('executorAdded', worker)
      },
      onExecutorRemoved(worker) {
        this.$emit('executorRemoved', worker);
      },
      onInput(value) {
        this.value = value;
        let workers = this.workers;
        this.filteredWorkers = workers.filter((option) => {
          return Matcher.match(option.name, value);
        });
      }
    },
    mounted() {
      this.filteredWorkers = this.workers;
    }
  }
</script>

<style lang="scss">
  .task-options-workers {
    padding-bottom: 20px;
    position: absolute;
    min-height: 205px;
    width: 100%;
    background-color: #ffffff;
    z-index: 3;
    box-shadow: 0 20px 30px 0 rgba(32,38,47,0.09);
    &__title {
      color: rgba(12, 34, 68, 0.32);
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.07em;
      font-weight: 500;
    }
    &__top {
      padding: 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__icon {
      display: block;
      padding: 13px;
      margin-right: -13px;
    }
    &__search {
      margin-bottom: 15px;
    }
  }
  .task-options-workers.filter {

  }
</style>