<template>
  <vue-simplebar>
    <ul class="options-workers-list" v-if="workers">
      <li class="options-workers-list-item" v-for="worker in workers" :key="worker.id">
        <TaskOptionsWorkersItem
          :worker="worker"
          :executors="taskExecutors"
          @executorAdded = "onExecutorAdded"
          @executorRemoved = "onExecutorRemoved"
        />
      </li>
    </ul>
  </vue-simplebar>
</template>

<script>
  import TaskOptionsWorkersItem from "./TaskOptionsWorkersItem.vue";
  import { mapState, mapGetters } from "vuex";
  
  export default {
    name: "TaskOptionsWorkersList",
    components: {TaskOptionsWorkersItem},
    props: {
      workers: {
        type: Array,
        required: true,
      }
    },
    computed: {
      ...mapGetters('task', [
        'taskExecutors'
      ])
    },
    methods: {
      onExecutorAdded(worker) {
        this.$emit('executorAdded', worker);
      },
      onExecutorRemoved(worker) {
        this.$emit('executorRemoved', worker);
      }
    },
   
  }
</script>

<style scoped lang="scss">
  [data-simplebar] {
    height: 257px;
    width: 100%;
  }
</style>