<template>
  <div class="task-workers">
    <div class="task-workers__performer task-workers__block" v-if="taskPerformer">
      <div class="task-workers__label">
        Исполнитель:
      </div>
      <div class="task-workers__value">
        <TheAvatar :worker="taskPerformer" :small="true" />
      </div>
    </div>
    <div class="task-workers__executors task-workers__block" v-if="taskExecutors.length>0">
      <div class="task-workers__label">
        Ответственные:
      </div>
      <div class="task-workers__value">
        <TheAvatarList :workers="taskExecutors" :small="true"/>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters} from 'vuex';
  import TheAvatarList from "../../ui/TheAvatarList.vue";
  import TheAvatar from "../../ui/TheAvatar.vue";

  export default {
    name: "TaskWorkers",
    components: {TheAvatarList, TheAvatar},
    computed: {
      ...mapState('worker', [
        'workers'
      ]),
      ...mapGetters('task', [
        'taskExecutors',
        'taskPerformer'
      ])
    }
  }
</script>

<style lang="scss">
  .task-workers {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 11px;

    &__block {
      display: flex;
      align-items: center;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    &__label {
      margin-right: 10px;
      font-size: 15px;
      color: #A9AEB9;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }



  .option {
    &__small {
      font-size: 15px;
      color: #3A3E44;
    }
  }

  .multiselect {
    &__input {
      font-size: 15px;
    }

    &__placeholder {
      font-size: 15px;
      color: #3A3E44;
    }

    &__tag {
      background: rgba(255, 35, 76, 0.8);
    }

    &__tag-icon {
      &:hover {
        background-color: #FF234C;
      }

      &::after {
        color: #ffffff;
      }
    }

    &__tags {
      font-size: 15px;
      border: none;
      color: #3A3E44;
    }

    &__content-wrapper {
      border: none;
      box-shadow: 0 20px 30px 0 rgba(32, 38, 47, 0.09);
    }

    &__option--highlight {
      background-color: #F1F3F8;
      color: #3A3E44;
    }
  }


</style>