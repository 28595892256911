<template>
    <div class="TheFilter">
        <div class="TheFilter__avatars">
            <TheAvatarList
                    :workers="ownersSelected"
                    small
            />
        </div>
        <a href="#" class="TheFilter__link" @click.prevent="handleClick">
            <span class="TheFilter__label">Ответственные</span>
            <span class="TheFilter__icon" :class="{'TheFilter__icon_select-opened': isOpenFilter}"><IconArrowDownWhiteSmall/></span>
        </a>
        <transition name="fade">
            <div class="TheFilter__select" v-if="isOpenFilter">
                <TheModal
                    @close="handleCrossClick"
                >
                    <BaseMultiselect
                            :options="filteredWorkers"
                            :selected="ownersSelected"
                            title="Ответственные"
                            placeholder="Найти ответственного"
                            @close="handleCrossClick"
                    >
                        <template v-slot:default="slotProps">
                            <BaseMultiselectItem
                                    :option="slotProps.option"
                                    :selected="slotProps.selected"
                                    @add="addOwner"
                                    @remove="removeOwner"
                                    v-slot="{option, isActive, handleClick}"
                            >
                                <div class="select-item">
                                    <a href="javascript:void(0)" class="select-item__link" @click.prevent="handleClick">
                                        <div class="select-item__avatar">
                                            <TheAvatar
                                                    :worker="option"
                                                    small/>
                                        </div>
                                        <div class="select-item__name">
                                            {{option.name}}
                                        </div>
                                        <div class="select-item__icon" v-if="isActive">
                                            <IconCheckRed/>
                                        </div>
                                    </a>
                                </div>
                            </BaseMultiselectItem>
                        </template>
                    </BaseMultiselect>
                </TheModal>
            </div>
        </transition>
    </div>
</template>

<script>
  import IconCrossGrey from "../../../icons/IconCrossGrey.vue";
  import IconArrowDownWhiteSmall from "../../../icons/IconArrowDownWhiteSmall.vue";
  import {mapState, mapGetters} from 'vuex';
  import TheAvatarList from "../../ui/TheAvatarList.vue";
  import BaseMultiselect from "../../ui/multiselect/BaseMultiselect.vue";
  import TheAvatar from "../../ui/TheAvatar.vue";
  import IconCheckRed from "../../../icons/IconCheckRed.vue";
  import BaseMultiselectItem from "../../ui/multiselect/BaseMultiselectItem.vue";
  import TheModal from "../../ui/TheModal.vue";
  import Matcher from "../../../../common/matcher";

  export default {
    name: "OwnerFilter",
    components: {
      TheModal,
      BaseMultiselectItem,
      IconCheckRed,
      TheAvatar,
      BaseMultiselect, TheAvatarList, IconArrowDownWhiteSmall, IconCrossGrey,
    },

    computed: {
      ...mapState('worker', [
        'workers',
        'userWorker'
      ]),
      ...mapState('filter', [
        "ownersSelected"
      ]),
      ...mapGetters('filter', [
        'filters'
      ])
    },
    data() {
      return {
        isOpenFilter: false,
        filteredWorkers: [],
        selected: []
      }
    },
    methods: {
      handleClick() {
        this.isOpenFilter = !this.isOpenFilter;
      },
      handleCrossClick() {
        this.isOpenFilter = false;
        this.filteredWorkers = this.workers;
      },
      addOwner(value) {
        this.$store.dispatch('filter/addOwner', value);
        this.$store.dispatch('project/fetchTasks', {id: this.$route.params.projectId, filters: this.filters});
      },
      removeOwner(value) {
        this.$store.dispatch('filter/removeOwner', value);
        this.$store.dispatch('project/fetchTasks', {id: this.$route.params.projectId, filters: this.filters});
      },
      handleChange(value) {
        this.filteredWorkers = this.workers.filter((option) => {
          return Matcher.match(option.name, value);
        });
      }
    },
    created() {
    },
    mounted() {
      this.filteredWorkers = this.workers;
      // this.$store.dispatch('filter/addOwner', this.userWorker);
    }
  }
</script>

<style lang="scss">
    @import '../../../../../scss/_settings/settings';

    .TheFilter {
        display: flex;
        align-items: center;
        position: relative;

        &__avatars {
            position: relative;
            top: 2px;
            z-index: 12;
        }

        &__link {
            position: relative;
            display: inline-block;
            padding: 10px;
            margin-right: -10px;
            z-index: 20;
        }

        &__label {
            position: relative;
            color: #ffffff;
            margin-right: 3px;
        }

        &__select {
            background: #ffffff;
            position: absolute;
            width: 278px;
            right: 0;
            top: 50px;
            z-index: 10;
        }

        &__icon {
            display: inline-block;
            transition: transform .2s ease-in-out;
            transform-origin: 50% 50%;

            &_select-opened {
                transform: rotate(-180deg);
            }
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .3s;

        .app-modal__inner {
            transition: transform .3s;
        }
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;

        .app-modal__inner {
            transform: translate3d(0, 20px, 0);
        }

    }
</style>