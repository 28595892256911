<template>
    <div class="task-index__support">
        <input type="checkbox" @change="change" :id="'support-' + task.id" v-model="task.support" class="task-index__support-checkbox">
        <label :for="'support-' + task.id" class="task-index__support-label">
            Тех. поддержка
        </label>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  export default {
    name: "TaskSupport",
    computed: {
      ...mapState('task', [
        'task'
      ]),
    },
    methods: {
      ...mapActions('task', [
        'storeSupport'
      ]),
      change() {
        this.storeSupport(this.task.support);
      }
    }
  }
</script>

<style>

</style>