<template>
  <li class="images-list__item">
    <a :href="path" class="list-image" target="_blank" :style="{backgroundImage: style}">
      <span class="list-image__icon" @click.stop.prevent="onCrossClick">
        <IconCrossImage/>
      </span>
    </a>
  </li>
</template>

<script>
  import TaskFileItem from "./TaskDocsItem.vue";
  import { mapState } from "vuex";
  import IconCrossImage from "../../../icons/IconCrossImage.vue";

  export default {
    name: "TaskFilesItem",
    components: {IconCrossImage, TaskFileItem },
    computed: {
      path() {
        return `/media/${this.file.file.path}`
      },
      ...mapState('task', [
        'task'
      ])
    },
    props: {
      file: Object
    },
    data() {
      return {
        style: ''
      }
    },
    methods: {
      onCrossClick() {
        const payload = {projectId: this.task.id, fileId: this.file.id};
        this.$store.dispatch('task/deleteFile', payload);
      }
    },
    mounted() {
      this.style = `url('/media/${this.file.file.path}')`
    }
  }
</script>

<style lang="scss">
  .list-image {
    position: relative;
    &__icon {
      position: absolute;
      top: -5px;
      right: -5px;
      opacity: 0;
      transition: .1s ease-in-out;
      padding: 5px;
    }
    &:hover {
      .list-image__icon {
        opacity: 1;
      }
    }
  }
  
</style>