<template>
  <ul class="tag-list" v-if="tags.length > 0">
    <li class="tag-list__item" v-for="tag in tags" :key="tag.id">
      <TheTag
        :tag="tag"
      />
    </li>
  </ul>
</template>

<script>
  import TheTag from "./TheTag.vue";
  export default {
    name: "TheTagList",
    components: {TheTag},
    props: {
      tags: {
        type: Array,
        default: () => {
          return [];
        }
      }
    }
  }
</script>

<style>

</style>