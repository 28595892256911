<template>
  <div class="options-worker">
    <a href="javascript:void(0)" class="options-worker__link" @click.prevent="handleClick">
      <span class="options-worker__avatar">
        <TheAvatar
          :worker="worker"
          :small="true"
        />
      </span>
      <span class="options-worker__name">{{worker.name}}</span>
      <span class="options-worker__icon" v-if="isSelectedData"><IconCheckRed/></span>
    </a>
  </div>
</template>

<script>
  import TheAvatar from "../../ui/TheAvatar.vue";
  import IconCheckRed from "../../../icons/IconCheckRed.vue";

  export default {
    name: "TaskOptionsWorkersItem",
    components: {IconCheckRed, TheAvatar},
    props: {
      worker: Object,
      executors: Array,
    },
    computed: {
      isSelectedData() {
        this.isSelected = false;
        this.executors.forEach((executor) => {
          if (executor.id == this.worker.id) {
            this.isSelected = true;
          }
        });
        return this.isSelected;
      }
    },
    data() {
      return {
        isSelected: false,
      }
    },
    methods: {
     handleClick() {
        this.isSelected = !this.isSelected;
        if (this.isSelected === true) {
          this.$emit('executorAdded', this.worker);
        } else {
          this.$emit('executorRemoved', this.worker);
        }
      },
    },
  }
</script>

<style lang="scss">
  .options-worker {
    &__link {
      display: flex;
      padding: 6px 24px;
      align-items: center;

      &:hover {
        background-color: #F1F3F8;
      }
    }

    &__name {
      display: block;
      flex-grow: 1;
      font-size: 15px;
      color: #3A3E44;
    }

    &__avatar {
      display: block;
      margin-right: 9px;
    }
  }

</style>