<template>
    <div class="select">
        <div class="select__top">
            <h3 class="select__title">{{title}}</h3>
            <a href="#" class="select__closer" @click.prevent="$emit('close')">
                <IconCrossSmall/>
            </a>
        </div>
        <div class="select__search">
            <BaseSearch
                :placeholder="placeholder"
                :autofocus="true"
                @input="onInput"
            />
        </div>
        <vue-simplebar>
            <ul class="select__list select-list" v-if="options">
                <li class="select-list__item" v-for="option in filteredOptions" :key="option.id">
                    <div class="select-item">
                        <slot
                                :option="option"
                                :selected="selected"
                        >
                        </slot>
                    </div>
                </li>
            </ul>
        </vue-simplebar>
    </div>
</template>

<script>
  import BaseSearch from "./BaseSearch.vue";
  import IconCrossSmallGrey from "../../../icons/IconCrossSmallGrey.vue";
  import IconCrossSmall from "../../../icons/IconCrossSmall.vue";

  export default {
    name: "BaseMultiselect",
    components: {IconCrossSmall, IconCrossSmallGrey, BaseSearch},
    props: {
      options: {
        type: Array,
        required: true,
      },
      selected: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      placeholder: {
        type: String,
        default: () => {
          return '';
        }
      },
    },
    computed: {
      filteredOptions() {
        return this.options.filter((option) => {
          return option.name.toLowerCase().match(this.searchInput);
        })
      }
    },
    data() {
      return {
        searchInput: ''
      }
    },
    methods: {
      onInput(value) {
        this.searchInput = value.toLowerCase();
      }
    },
    mounted() {

    }
  }
</script>

<style>

</style>