<template>
  <div>
    <router-view></router-view>
    <notifications width="calc(100vw - 235px)" group="foo" position="top left"/>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    name: "App",
    computed: {
      ...mapState('user', [
        'user',
      ])
    },
  }
</script>

<style>

</style>