<template>
  <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
    <title>Group 23</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
      <g id="Все-задачи-Copy-7" transform="translate(-1219.000000, -255.000000)" stroke="#C1C4D3" stroke-width="2">
        <g id="Group-21" transform="translate(-3141.000000, 240.000000)">
          <g id="Group-23" transform="translate(4366.192388, 21.500000) rotate(-225.000000) translate(-4366.192388, -21.500000) translate(4359.692388, 15.000000)">
            <path d="M0.00830078125,7 L12.0021973,7" id="Line-3"></path>
            <path d="M5.98950195,0.967895508 L5.98950195,12.9210205" id="Line-3"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IconCrossGrey"
  }
</script>

<style>

</style>