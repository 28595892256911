<template>
  <div class="base-wrapper" :style="{backgroundImage: `url(${background})`, backgroundPosition: 'center'}">
    <div class="base-wrapper__inner">
      <div class="base-wrapper__sidebar">
        <AppSidebar/>
      </div>
      <div class="base-wrapper__main" :class="{'base-wrapper__main_wide': !sidebarIsOpen}">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import AppSidebar from "../components/app/sidebar/AppSidebar.vue";
  import { mapState } from "vuex";

  export default {
    name: "AppLayout",
    components: {AppSidebar},
    computed: {
      ...mapState(['sidebarIsOpen']),
      ...mapState('worker', [
        'userWorker',
        ]),
      background() {
        // const backs = ['cat.gif', 'hypno.gif', 'hypno2.gif', 'hypno3.gif', 'giphy.gif', 'giphy3.gif'];
        // const back = backs[~~(Math.random() * backs.length)];

        // return '/static/images/joke/' + back;

        if (this.userWorker.background) {
          return `/media/${this.userWorker.background}`;
        } else {
          return '/static/images/base/tidalwaves.jpg';
        }
      }
    }
  }
</script>

<style lang="scss">
  .base-wrapper {
    min-width: 100%;
    background: {
      repeat: no-repeat;
      size: cover;
    };

    &__inner {
      display: flex;
    }

    &__main {
      width: calc(100vw - 280px);
      &_wide {
        width: 100vw;
      }
    }


  }
</style>