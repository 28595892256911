import FavoritesService from '../../services/favorites.service';

const favoritesModule = {
  namespaced: true,
  state: {
    loading: false,
    projects: [],
  },
  getters: {
  },
  mutations: {
    setProjects(state, data) {
      state.projects = data;
    },
  },
  actions: {
    async add({ state, dispatch, commit }, { id, userId }) {
      try {
        const result = await FavoritesService.add(id);
        dispatch('fetchData', userId);
      } catch (e) {
        dispatch('notifyError', e);
      }
    },
    async remove({ state, dispatch, commit }, { id, userId }) {
      try {
        const result = await FavoritesService.remove(id);
        dispatch('fetchData', userId);
      } catch (e) {
        dispatch('notifyError', e);
      }
    },
    async fetchData({ state, dispatch, commit }, userId) {
      try {
        const result = await FavoritesService.fetchData(userId);
        commit('setProjects', result.data.data);
      } catch (e) {
        dispatch('notifyError', e);
      }
    },
  },
};

export default favoritesModule;