import moment from 'moment';
import ProjectService from '../../services/project.service';
import TaskService from '../../services/task.service';

const projectModule = {
  namespaced: true,
  state: {
    groups: [],
    searchInputProject: '',
    taskNameInput: '',
    currentProject: {},
    tasks: [],
    statuses: {},
    selectedWorkers: [],
    allProjects: [],
  },
  getters: {
    projectsChoices(state) {
      return state.allProjects;
    },
    projects(state) {
      const found = [];
      state.groups.forEach((group) => {
        group.projects.forEach((project) => {
          found.push(project);
        });
      });
      return found;
    },
    workerTasks(state, getters, rootState) {
      return state.tasks.filter((column) => {
        if (rootState.user.settings.showAllColumns) {
          return true;
        }

        if ([10, 70].indexOf(column.status) > -1) {
          return false;
        }

        return true;
      });
    },
    getProjectById(state) {
      return (id) => {
        let found = null;
        state.groups.forEach((group) => {
          group.projects.forEach((project) => {
            if (project.id === id) {
              found = project;
            }
          });
        });
        return found;
      };
    },
    getStatusName(state) {
      return id => (state.statuses[id] ? state.statuses[id] : null);
    },
  },
  actions: {
    async fetchAllProjects({ commit }) {
      try {
        const response = await ProjectService.getAllProjects();
        commit('setAllProjects', response.data.data);
      } catch (e) {
        console.log(e);
      }
    },
    async fetchProjects({ commit }, { filters }) {
      try {
        const response = await ProjectService.getGroupsWithProjects({ filters });
        commit('setProjects', response.data.data);
      } catch (e) {
        console.log(e);
      }
    },
    async fetchProject({ commit }, projectId) {
      try {
        const response = await ProjectService.getProject(projectId);
        commit('setCurrentProject', response.data.data);
      } catch (e) {
        console.log(e);
      }
    },
    clearTasks({ commit, state }) {
      const statuses = Object.entries(state.statuses).map(([key, value]) => ({
        status: parseInt(key, 10),
        status_name: value,
        tasks: [],
      }));
      commit('setTasks', statuses);
    },
    async fetchAllTasks({ rootState, dispatch }) {
      rootState.loading = true;
      await dispatch('refreshAllTasks');
      rootState.loading = false;
    },
    async refreshAllTasks({ commit, rootGetters }) {
      try {
        const filters = rootGetters['filter/filters'];
        const response = await ProjectService.getAllTasks({ filters });
        commit('setTasks', response.data.data);
      } catch (e) {
        console.error(e);
      }
    },

    async fetchAllProjectTasks({ rootState, dispatch }) {
      rootState.loading = true;
      await dispatch('refreshAllProjectTasks');
      rootState.loading = false;
    },
    async refreshAllProjectTasks({ commit, rootGetters }) {
      try {
        const filters = rootGetters['filter/filters'];
        const response = await ProjectService.getAllProjectTasks({ filters });
        commit('setTasks', response.data.data);
      } catch (e) {
        console.error(e);
      }
    },

    async fetchAllSupportTasks({ rootState, dispatch }) {
      rootState.loading = true;
      await dispatch('refreshAllSupportTasks');
      rootState.loading = false;
    },
    async refreshAllSupportTasks({ commit, rootGetters }) {
      try {
        const filters = rootGetters['filter/filters'];
        const response = await ProjectService.getAllSupportTasks({ filters });
        commit('setTasks', response.data.data);
      } catch (e) {
        console.error(e);
      }
    },
    
    async fetchTasks({ dispatch, rootState }, payload) {
      rootState.loading = true;
      await dispatch('refreshTasks', payload);
      rootState.loading = false;
    },

    async refreshTasks({ dispatch, commit, rootState }, payload) {
      if (payload.id === 'all' || payload.id === undefined) {
        dispatch('refreshAllTasks');
        return;
      }
      try {
        const response = await ProjectService.getProjectTasks(payload);
        commit('setTasks', response.data.data);
      } catch (e) {
        console.log(e);
      }
    },
    async fetchStatuses({ commit }) {
      try {
        const response = await ProjectService.getStatuses();
        commit('setStatuses', response.data.data);
      } catch (e) {
        console.log(e);
      }
    },
    async updateTaskTitle({ dispatch }, payload) {
      try {
        await TaskService.updateTitle(payload.taskId, payload.title);
      } catch (e) {
        console.log(e);
      }
    },
    async updateDescription({commit}, payload) {
      try {
        console.log(payload.projectId, payload.description);
        await ProjectService.updateDescription(payload.projectId, payload.description);
      } catch (e) {
        console.log(e);
      }
    },
    async createCredential({ dispatch }, payload) {
      try {
        console.log(payload);
        await ProjectService.createCredential(payload.projectId, payload.credential)
          .then((r) => {
            if (r.data.status === 'success') {
              dispatch('fetchProject', payload.projectId);
            }
          });
      } catch (e) {
        console.log(e);
        console.log('Ошибка добавления доступа, payload:', payload);
      }
    },
    async updateCredential({ dispatch }, payload) {
      try {
        await ProjectService.updateCredential(payload.credential)
          .then((r) => {
            if (r.data.status === 'success') {
              dispatch('fetchProject', payload.projectId);
            }
          });
      } catch (e) {
        console.log(e);
        console.log('Ошибка обновления доступа, payload:', payload);
      }
    },
    async deleteCredential({ dispatch }, payload) {
      try {
        await ProjectService.deleteCredential(payload.credentialId);
        dispatch('fetchProject', payload.projectId);
      } catch (e) {
        console.log(e);
      }
    },
  },
  mutations: {
    setAllProjects(state, projects) {
      state.allProjects = projects;
    },
    setProjects(state, projects) {
      state.groups = projects;
    },
    setCurrentProject(state, project) {
      state.currentProject = project[0];
    },
    setTasks(state, tasks) {
      state.tasks = tasks;
      state.tasks.forEach((status) => {
        if (status.tasks !== undefined) {
          status.tasks.forEach((task) => {
            if (moment(task.deadline).isAfter(Date.now()) || task.deadline === null) {
              task.isExpired = false;
            } else {
              task.isExpired = true;
            }
          });
        }
      });
    },
    updateSearchProject(state, value) {
      state.searchInputProject = value;
    },
    setStatuses(state, statuses) {
      state.statuses = statuses;
    },
    addTask(state, status) {
      const filtered = state.tasks.filter(taskStatus => taskStatus.status === status.status)[0];
      const newTask = {};
      filtered.tasks.push(newTask);
    },
    removeAddingTask(state, status) {
      const filtered = state.tasks.filter(taskStatus => taskStatus.status === status.status)[0];
      filtered.tasks.splice(-1, 1);
    },
    updateTaskName(state, value) {
      state.taskNameInput = value;
    },
    clearTaskName(state) {
      state.taskNameInput = '';
    },
  },
};

export default projectModule;