<template>
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
    <title>Group 18</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Все-задачи-Copy-6" transform="translate(-504.000000, -701.000000)">
        <g id="Group-29" transform="translate(401.000000, 701.000000)">
          <g id="Group-18" transform="translate(103.000000, 0.000000)">
            <rect id="Rectangle" fill-opacity="0.3" fill="#191B1E" x="0" y="0" width="18" height="18"></rect>
            <g id="Group-23-Copy-2" transform="translate(9.363961, 9.363961) rotate(-315.000000) translate(-9.363961, -9.363961) translate(3.363961, 4.363961)" fill="#FFFFFF" fill-opacity="0.8" fill-rule="nonzero">
              <path d="M4.98950195,4 L4.98950195,-4.54747351e-13 L6.98950195,-4.54747351e-13 L6.98950195,4 L11.0092773,4 L11.0092773,6 L6.98950195,6 L6.98950195,10 L4.98950195,10 L4.98950195,6 L0.989501953,6 L0.989501953,4 L4.98950195,4 Z" id="Combined-Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IconCrossImage"
  }
</script>

<style>

</style>