const TextService = {
  prepareText(text) {
    text = this.handleLinks(text);
    text = this.handleNewLines(text);
    return text;
  },

  handleNewLines(text) {
    return text.replace(/(?:\r\n|\r|\n)/g, '<br/>');
  },

  handleLinks(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
      let inner = url;
      if (inner.match(/(jpg|jpeg|gif|png)$/ig)) {
        inner = `<img src="${url}">`;
      }
      return `<a href="${url}" target="_blank">${inner}</a>`;
    });
  },
};

export default TextService;