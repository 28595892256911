<template>
    <div class="task" @click="show">
        <div class="task__main" :class="['task__main_status_' + task.status]">
            <div class="task__status">
                {{ statusVerbose }}
            </div>
            <p class="task__description">{{task.name}}</p>

            <TaskBottom :task="task" :hidePerformer="false"></TaskBottom>
        </div>
    </div>
</template>

<script>
import TaskBottom from "../../parts/TaskBottom";
import StatusService from "../../../services/status.service";

export default {
  name: "PipelineViewTask",
  props: {
    task: Object
  },
  components: {
    TaskBottom,
  },
  computed: {
    statusVerbose() {
      return StatusService.verboseStatus(this.task.status);
    },
  },
  methods: {
    show() {
      const routeData = this.$router.resolve({name: 'task', params: {taskId: this.task.id}});
      window.open(routeData.href, '_blank');
    }
  },
}
</script>

<style lang="scss">

</style>