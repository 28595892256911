<template>
  <svg width="16px" height="12px" viewBox="0 0 16 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
    <title>Group 22</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
      <g id="123" transform="translate(-368.000000, -51.000000)" stroke="#C1C4D3" stroke-width="2">
        <g id="Group-21-Copy-2" transform="translate(345.000000, 34.000000)">
          <g id="Group-22" transform="translate(31.000000, 22.500000) rotate(-180.000000) translate(-31.000000, -22.500000) translate(24.000000, 18.000000)">
            <path d="M0.0114746094,4 L11.998291,4" id="Line-3"></path>
            <polyline id="Line-3" points="9.49914551 0.0606689453 13.4991455 3.98138047 9.49914551 8.06066895"></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IconBackSmall"
  }
</script>

<style>

</style>