<template>
  <KanbanLayout>
    <h2 slot="title">
      Шаблон плана
    </h2>

    <Tabs
      slot="tabs"
    />

    <div slot="kanban">
        <TheChecklistTemplateForm :id="parseInt($route.params.id, 10)"/>
    </div>
  </KanbanLayout>
</template>

<script>
  import KanbanLayout from "../layouts/KanbanLayout.vue";
  import Tabs from "../components/app/index/Tabs.vue";
  import TheChecklistTemplateForm from '../components/app/checklist/template/TheChecklistTemplateForm.vue';

  export default {
    name: "TheChecklistTemplate",
    components: {
      KanbanLayout,
      Tabs,
      TheChecklistTemplateForm,
    },
  }
</script>

<style>

</style>