<template>
    <div class="file">
        <a class="file__link-download" :href="path" target="_blank">
            <span class="file__img-wrapper" v-if="isImage">
                <img :src="path" alt="" class="file__img">
            </span>
            <span class="file__icon" v-else>
                <IconSketch v-if="isSketch"/>
                <IconWord v-if="isDoc"/>
                <IconZip v-if="isZip"/>
                <IconFile v-if="isFile"/>
            </span>
        </a>
        <a class="file__link-name" :href="path" target="_blank">
            <span class="file__name-file">{{name}}</span>
            <span class="file__name-size" v-if="size">{{fileSize}} МБ</span>
        </a>
    </div>
</template>

<script>
  import IconSketch from "../../icons/IconSketch.vue";
  import IconWord from "../../icons/IconWord.vue";
  import IconZip from "../../icons/IconZip.vue";
  import IconFile from "../../icons/IconFile.vue";

  export default {
    name: "File",
    components: {IconFile, IconZip, IconWord, IconSketch},
    props: {
      name: String,
      size: Number,
      path: String
    },
    computed: {
      fileSize() {
        return Math.ceil(this.size / 1000000);
      },
      isSketch() {
        if (this.name.match(/\.sketch$/) !== null) {
          return true;
        }
        return false;
      },
      isPsd() {
        if (this.name.match(/\.psd$/) !== null) {
          return true;
        }
        return false;
      },
      isDoc() {
        if (this.name.match(/\.(doc|docx)$/) !== null) {
          return true;
        }
        return false;
      },
      isZip() {
        if (this.name.match(/\.zip$/) !== null) {
          return true;
        }
        return false;
      },
      isFile() {
        if (this.name.match(/\.(zip|doc|docx|psd|sketch)$/) === null) {
          return true;
        }
        return false;
      },
      isImage() {
        return this.name.match(/\.(png|jpg|jpeg|webp|gif)$/) !== null;
      }
    }
  }
</script>
