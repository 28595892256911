import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth.module';
import user from './modules/user.module';
import project from './modules/project.module';
import task from './modules/task.module';
import worker from './modules/worker.module';
import tag from './modules/tag.module';
import filter from './modules/filter.module';
import load from './modules/load.module';
import log from './modules/log.module';
import logTask from './modules/logTask.module';
import time from './modules/time.module';
import timeline from './modules/timeline.module';
import membership from './modules/membership.module';
import favoritesModule from './modules/favorites.module';
import todoes from './modules/todoes.module';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loading: false,
    sidebarIsOpen: true,
    isUserOptions: false,
  },
  modules: {
    auth,
    user,
    project,
    task,
    worker,
    tag,
    filter,
    load,
    log,
    logTask,
    time,
    timeline,
    membership,
    favoritesModule,
    todoes,
  },
  mutations: {
    setLoadingTrue(state) {
      state.loading = true;
    },
    setLoadingFalse(state) {
      state.loading = false;
    },
    toggleSidebar(state) {
      state.sidebarIsOpen = !state.sidebarIsOpen;
    },
    closeSidebar(state) {
      state.sidebarIsOpen = false;
    },
    openSidebar(state) {
      state.sidebarIsOpen = true;
    },
    toggleUserOptions(state) {
      state.isUserOptions = !state.isUserOptions;
    },
  },
});

export default store;