import Flow from '@flowjs/flow.js';
import store from '../store/index';

class UploadUserOptionsService {
  constructor(opts = {}) {
    this.button = opts.button;
    this.endpoint = opts.endpoint;
    this.userId = opts.userId;
    this.flow = new Flow({
      target: this.endpoint,
      testChunks: false,
      allowDuplicateUploads: true,
      query: { user_id: this.userId },
    });
    this.bind();
  }

  bind() {
    this.flow.assignBrowse(document.querySelector(this.button));
    this.flow.on('fileAdded', (file, event) => {
      store.commit('setLoadingTrue');
      // console.log(file, event);
    });
    this.flow.on('fileSuccess', (file, message) => {
      // console.log(file, message);
      store.commit('setLoadingFalse');
      store.dispatch('worker/fetchWorker', this.userId);
    });
    this.flow.on('fileError', (file, message) => {
      console.log('error');
      store.commit('setLoadingFalse');
      // console.log(file, message);
    });
    this.flow.on('filesSubmitted', () => {
      // me.counter = 0;
      console.log('submitted');
      this.flow.upload();
    });
  }
}

export default UploadUserOptionsService;