<template>
	<ProjectContainer v-slot="{project}">
		<ProjectLayout :project="project">
			<ProjectSearchTask slot="options"/>

			<ProjectFilters
				slot="filters"
			/>

			<div slot="kanban">
				<TasksContainer v-if="project.id" :projectId="project.id" v-slot="{tasks}">
					<ProjectKanban :tasks="tasks"></ProjectKanban>
				</TasksContainer>
			</div>

			<BaseModal
			  v-show="showModal"
			  slot="modal"
			>
			  <router-view name="task"/>
			</BaseModal>

		</ProjectLayout>
	</ProjectContainer>
</template>

<script>
  import ProjectKanban from "../components/app/project/ProjectKanban.vue";
  import ProjectContainer from "../containers/ProjectContainer.vue";
  import TasksContainer from "../containers/TasksContainer.vue";
  import KanbanLayout from "../layouts/KanbanLayout.vue";
  import ProjectFilters from "../components/app/project/ProjectFilters.vue";
  import BaseSearch from "../components/ui/multiselect/BaseSearch.vue";
  import BaseModal from "../components/ui/BaseModal.vue";
  import ProjectLayout from "../layouts/ProjectLayout.vue";

  import ModalMixin from "../mixins/modal.mixin";
  import ProjectSearchTask from '../components/app/project/ProjectSearchTask.vue';

  export default {
    name: "TheProjectTasks",
    components: {
      ProjectSearchTask,
      ProjectContainer,
      TasksContainer,
      ProjectLayout,
      ProjectKanban,
      KanbanLayout,
      ProjectFilters,
      BaseSearch,
      BaseModal
	},
    mixins: [ModalMixin],
  }
</script>

<style scoped>

</style>