import ApiService from './api.service';

const PipelineService = {
  async all(projectId) {
    const requestData = {
      url: `/api/pipelines/${projectId}/all`,
      method: 'get',
    };
    return ApiService.request(requestData);
  },
  async get(projectId, pipelineId) {
    const requestData = {
      url: `/api/pipelines/${projectId}/get/${pipelineId}`,
      method: 'get',
    };
    return ApiService.request(requestData);
  },
  async create(projectId, { pipeline, tasks }) {
    const requestData = {
      url: `/api/pipelines/${projectId}/create`,
      method: 'post',
      data: {
        pipeline,
        tasks,
      },
    };
    return ApiService.request(requestData);
  },
  async update(projectId, pipelineId, { pipeline, tasks }) {
    const requestData = {
      url: `/api/pipelines/${projectId}/update/${pipelineId}`,
      method: 'put',
      data: {
        pipeline,
        tasks,
      },
    };
    return ApiService.request(requestData);
  },
};

export default PipelineService;