const StatusService = {
  getStatusesMap() {
    return {
      10: 'Backlog',
      20: 'Todo',
      30: 'Bug',
      50: 'Progress',
      60: 'Review',
      70: 'Check',
      80: 'Done',
    };
  },
  verboseStatus(status) {
    const map = this.getStatusesMap();
    return map[status] ? map[status] : '';
  },
};

export default StatusService;