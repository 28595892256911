import Vue from 'vue';
import VModal from 'vue-js-modal';
import moment from 'moment';
import VTooltip from 'v-tooltip';
import VueSimplebar from 'vue-simplebar';
import VueTextareaAutosize from 'vue-textarea-autosize';
import VueAutoSize from 'vue-autosize';
import Datetime from 'vue-datetime';
import Notifications from 'vue-notification';
import wysiwyg from 'vue-wysiwyg';
import { Settings } from 'luxon';
import { VueHammer } from 'vue2-hammer';
import App from './App.vue';
import router from './router/index';
import store from './store/index';
import TokenService from './services/token.service';
import ApiService from './services/api.service';
import 'vue-simplebar/dist/vue-simplebar.css';
import 'vue-datetime/dist/vue-datetime.css';
import Draggon from '../../components/draggon';

Vue.use(Datetime);
Vue.use(VueTextareaAutosize);
Vue.use(VueAutoSize);
Vue.use(VueSimplebar);
Vue.use(VueHammer);
Vue.use(wysiwyg, {});
Vue.use(VTooltip);
Vue.use(Notifications);

Vue.use(VModal, { dynamic: true, injectModalsContainer: true });

moment.locale('ru');
Vue.prototype.$moment = moment;
Settings.defaultLocale = 'ru';

Vue.config.devtools = process.env.NODE_ENV === 'development';

ApiService.init();

const startWithToken = async () => {
  if (TokenService.getToken()) {
    await ApiService.setHeader();
    await store.dispatch('user/getUser');
    const { user } = store.state.user;
    await store.dispatch('worker/fetchWorker', user.id);
    await store.dispatch('worker/fetchWorkers');
    await store.dispatch('project/fetchStatuses');
    const { userWorker } = store.state.worker;
    // Ахтыж хитрая жопа
    store.dispatch('filter/addExecutor', userWorker);
    store.dispatch('filter/addOwner', userWorker);
    store.dispatch('favoritesModule/fetchData', userWorker.id);
  }
};

startWithToken().then(() => {
  const vm = new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
  });

  const draggon = new Draggon('.kanban');
  draggon.bindEvents();
});