<template>
    <svg width="11px" height="11px" viewBox="0 0 11 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
        <title>Group 37</title>
        <desc>Created with Sketch.</desc>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Все-задачи-Copy-2" transform="translate(-539.000000, -329.000000)" fill="#9FA4AD">
                <g id="Group-17" transform="translate(287.000000, 171.000000)">
                    <g id="Group-15-Copy" transform="translate(16.000000, 147.000000)">
                        <g id="Group-25" transform="translate(225.000000, 0.000000)">
                            <g id="Group-37" transform="translate(16.010408, 17.010408) rotate(-225.000000) translate(-16.010408, -17.010408) translate(9.010408, 15.510408)">
                                <polygon id="Triangle" transform="translate(12.500000, 1.500000) rotate(-270.000000) translate(-12.500000, -1.500000) " points="12.5 0 14 3 11 3"></polygon>
                                <rect id="Rectangle" x="0" y="0" width="9" height="3"></rect>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
  export default {
    name: "IconPen"
  }
</script>

<style>

</style>