<script>
  import { mapActions, mapState, mapGetters } from "vuex";

  export default {
    name: "MembershipContainer",
    computed: {
      ...mapState('membership', [
        'types'
      ]),
      ...mapState('membership', [
        'memberships'
      ]),
      ...mapGetters('worker', [
        'activeWorkers'
      ]),
      ...mapGetters('project', [
        'projects'
      ]),
    },
    data() {
      return {

      }
    },
    methods: {
      ...mapActions({
        fetch: 'membership/fetchAll',
        fetchWorkers: 'worker/fetchWorkers',
        create: 'membership/create',
        updateType: 'membership/updateType',
        deleteMembership: 'membership/deleteMembership',
      }),

      init() {
        this.fetch();
        this.fetchWorkers();
      },
      updatedType(data) {
        this.updateType(data);
      },
      created(data) {
        this.create(data);
      },
      deletedMembership(data) {
        this.deleteMembership(data);
      }
    },

    created() {
      this.init();
    },

    render() {
      return this.$scopedSlots.default({
        types: this.types,
        projects: this.projects,
        workers: this.activeWorkers,
        memberships: this.memberships,
        updatedType: this.updatedType,
        created: this.created,
        deletedMembership: this.deletedMembership
      })
    },
  }
</script>