<template>
  <svg width="16px" height="12px" viewBox="0 0 16 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
    <title>Group 22</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
      <g id="Все-задачи-Copy-6" transform="translate(-995.000000, -357.000000)" stroke="#C1C4D3" stroke-width="2">
        <g id="Group-15" transform="translate(975.000000, 64.000000)">
          <g id="Group-14" transform="translate(0.000000, 140.000000)">
            <g id="Group-22" transform="translate(21.000000, 155.000000)">
              <path d="M0.0114746094,4 L11.998291,4" id="Line-3"></path>
              <polyline id="Line-3" points="9.49914551 0.0606689453 13.4991455 3.98138047 9.49914551 8.06066895"></polyline>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IconMove"
  }
</script>

<style>

</style>