<template>
  <div class="task-index__comments">
    <TaskCommentsTitle :comments="comments"/>
    <TaskCommentsList/>
    <TaskCommentsAdd/>
  </div>
</template>

<script>
  import TaskCommentsTitle from "./TaskCommentsTitle.vue";
  import { mapGetters } from 'vuex';
  import TaskCommentsList from "./TaskCommentsList.vue";
  import TaskCommentsAdd from "./TaskCommentsAdd.vue";
  export default {
    name: "TaskComments",
    components: {TaskCommentsAdd, TaskCommentsList, TaskCommentsTitle},
    computed: {
      ...mapGetters('task', [
        'comments',
      ])
    },
  }
</script>

<style>

</style>