import ApiService from './api.service';

const TimeService = {
  async getByWorkers(dateFrom, dateTo) {
    const requestData = {
      url: '/api/time/workers/dates',
      method: 'get',
      params: {
        from_date: dateFrom,
        to_date: dateTo,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async getByProjects(dateFrom, dateTo) {
    const requestData = {
      url: '/api/time/projects/dates',
      method: 'get',
      params: {
        from_date: dateFrom,
        to_date: dateTo,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};

export default TimeService;