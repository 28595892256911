<template>
  <object>
    <a v-if='isCurrentProject' class="fav-button in-fav" href="javascript:void(0);" @click="remove({id: project.id, userId: userWorker.id})">
       <span class="fav-button__icon">
          <Star/>
        </span>
    </a>
    <a v-else class="fav-button" href="javascript:void(0);" @click="add({id: project.id, userId: userWorker.id})">
      <span class="fav-button__icon">
          <Star/>
      </span>
    </a>
  </object>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Star from "../../icons/Star.vue";

export default {
  name: 'FavButton',
  components: {
    Star,
  },
  props: {
    project: Object
  },
  computed: {
    ...mapState('favoritesModule', [
        'projects'
    ]),
    ...mapState('worker', [
        'userWorker'
    ]),
    isCurrentProject() {
      return this.projects.some(item => item.id === this.project.id);
    }
  },
  methods: {
    ...mapActions('favoritesModule', [
        'add'
    ]),
    ...mapActions('favoritesModule', [
      'remove'
    ]),
  }
};
</script>

<style scoped>

</style>