const FilterService = {
  getExecutors() {
    const executors = window.localStorage.getItem('FILTER_EXECUTORS');
    if (executors) {
      return JSON.parse(executors);
    }
    return [];
  },

  setExecutors(executors) {
    window.localStorage.setItem('FILTER_EXECUTORS', JSON.stringify(executors));
  },

  getOwners() {
    const owners = window.localStorage.getItem('FILTER_OWNERS');
    if (owners) {
      return JSON.parse(owners);
    }
    return [];
  },

  setOwners(owners) {
    window.localStorage.setItem('FILTER_OWNERS', JSON.stringify(owners));
  },
};

export default FilterService;