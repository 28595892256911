<template>
  <div class="user-options">
    <div class="user-options__button">
      <!--@TODO Костыль!-->
      <a href="/admin/create/Main/ProjectAdmin" target="_blank" class="user-options__button-create-project button button_wide button_transparent button_big">
        Создать проект
      </a>
      <!--<Button wide transparent big @click="$emit('createProject')">
        Создать проект
      </Button>-->
    </div>
    <div class="user-options__info">
      <div class="user-options__avatar">
        <TheAvatar
          :worker="worker"
          big
        />
      </div>
      <h3 class="user-options__name">
        {{worker.name}}
      </h3>
    </div>
    <ul class="user-options__list user-options-list">
      <li class="user-options-list__item">
        <button class="user-options-item avatar-change">
          <span class="user-options-item__icon"><IconExecutorLarge /></span>
          <span class="user-options-item__label">Сменить аватар</span>
        </button>
      </li>
      <li class="user-options-list__item">
        <button class="user-options-item background-change">
          <span class="user-options-item__icon"><IconBackground /></span>
          <span class="user-options-item__label">Сменить фон</span>
        </button>
      </li>
      <li class="user-options-list__item">
        <div class="user-options-item">
          <input type="checkbox" id="showAllColumns" v-model="settings.showAllColumns" @change="changeShowAllColumns">
          <label for="showAllColumns" class="user-options-item__check-label">
            Все колонки
          </label>
        </div>

      </li>
    </ul>
    <div class="user-options__colorpicker">
      <div class="user-options__colorpicker-palette">
        <color-picker
          v-bind="color"
          initially-collapsed
          @input="onInput"
        >
        </color-picker>
      </div>
      <button
        class="user-options__colorpicker-button background-change"
        @click="$emit('selectColor')"
      >
        Выбрать цвет аватара
      </button>
    </div>
  </div>
</template>
<script>
  import Button from "../../ui/Button.vue";
  import TheAvatar from "../../ui/TheAvatar.vue";
  import IconExecutorLarge from "../../../icons/IconExecutorLarge.vue";
  import IconBackground from "../../../icons/IconBackground.vue";
  import ColorPicker from "@radial-color-picker/vue-color-picker";
  import { mapState, mapActions } from "vuex";

  export default {
    name: "SidebarUserOptions",
    components: {IconBackground, IconExecutorLarge, TheAvatar, Button, ColorPicker},
    computed: {
      ...mapState('user', [
        'settings'
      ]),
    },
    props: {
      worker: {
        type: Object,
        required: true,
      },
      color: {
        type: Object,
        required: true,
      }
    },
    methods: {
      ...mapActions({
        saveSettings: 'user/saveSettings'
      }),
      onInput(hue) {
        this.$emit('changeColor', hue);
      },
      changeShowAllColumns() {
        this.saveSettings();
      }
    },
  }
</script>

<style lang="scss">
  //@import '@radial-color-picker/vue-color-picker/dist/vue-color-picker.min.css';
  @import '../../../../../../node_modules/@radial-color-picker/vue-color-picker/dist/vue-color-picker.min.css';

  .rcp {
    width: 180px;
    height: 180px;
  }
  .rcp__palette:before {
    background: #191B1E;
  }

  .rcp__well {
    border: none;
    box-shadow: none;
  }
</style>