<template>
    <div class="timeline-line timeline-worker">
        <div class="timeline-line__legend timeline-worker__legend timeline-legend">
            {{ timeline.worker.name }}
        </div>
        <div class="timeline-worker__days timeline-days">
            <TimelineWorkerDay v-for="(day, key) in days" :key="key" :day="day" :tasks="timeline.tasks"/>
        </div>
    </div>
</template>

<script>
  import TimelineWorkerDay from './TimelineWorkerDay.vue';

  export default {
    name: "TimelineWorker",
    components: {
      TimelineWorkerDay,
    },
    props: {
      days: Array,
      timeline: Object
    }
  }
</script>

<style>

</style>