<template>
    <div class="tabs" v-if="id">
        <router-link :to="{name: 'project_tasks', params: {projectId: id}}" class="tabs__info-tab-item">Задачи</router-link>
        <!--
            <router-link :to="{name: 'project_pipelines', params: {projectId: id}}" v-if="!allTasks" class="tabs__info-tab-item">Пайплайны</router-link>
        -->
        <router-link :to="{name: 'project_checklists', params: {projectId: id}}" v-if="!allTasks" class="tabs__info-tab-item">Планы</router-link>
        <router-link :to="{name: 'project_manager', params: {projectId: id}}" v-if="!allTasks" class="tabs__info-tab-item">Панель менеджера</router-link>
        <router-link :to="{name: 'project_wiki', params: {projectId: id}}" v-if="!allTasks" class="tabs__info-tab-item">Wiki</router-link>
    </div>
</template>

<script>
  export default {
    name: "ProjectTabs",
    props: {
      id: Number,
      active: String,
      allTasks: {
          type: Boolean,
          default: false,
      }
    },
    methods: {
      getActiveClass(name) {
        return this.active === name ? 'active' : ''
      }
    }
  }
</script>

<style>

</style>