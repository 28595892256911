<template>
  <KanbanLayout>
    <h2 slot="title">
      Машина времени
    </h2>

    <Tabs
      slot="tabs"
    />

    <div slot="kanban">
      <div class="manager">
        <div class="manager__half">
          <TheTimeWorkers/>
        </div>
        <div class="manager__half">
          <TheTimeProjects/>
        </div>
      </div>
    </div>
  </KanbanLayout>
</template>

<script>
  import KanbanLayout from "../layouts/KanbanLayout.vue";
  import Tabs from "../components/app/index/Tabs.vue";
  import TheTimeWorkers from "../components/app/time/TheTimeWorkers.vue";
  import TheTimeProjects from "../components/app/time/TheTimeProjects.vue";

  export default {
    name: "TheManager",
    components: {
      KanbanLayout,
      Tabs,
      TheTimeWorkers,
      TheTimeProjects,
    },
  }
</script>

<style scoped>

</style>