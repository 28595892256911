<template>
    <div class="time-worker" :class="{'time-worker': opened}">
        <a class="time-worker__container" href="javascript:void(0);" @click="opened = !opened">
            <div class="time-worker__info">
                <div class="time-worker__avatar">
                    <TheAvatar :worker="worker"/>
                </div>
                <div class="time-worker__name">
                    {{ worker.name }}
                </div>
            </div>
            <div class="time-worker__spent">
                {{ spent }}
            </div>
        </a>

        <div class="time-worker__projects" v-if="info && info.by_projects && opened">

            <TimeWorkerProject
                    v-for="projectInfo in info.by_projects"
                    :key="projectInfo.project_id"
                    :info="projectInfo"
                    :opened="opened"
                    :project-id="projectInfo.project_id"
                    :spent="projectInfo.spent"/>
        </div>
    </div>
</template>

<script>
  import SpentService from '../../../services/spent.service';
  import TheAvatar from '../../ui/TheAvatar.vue';
  import TimeWorkerProject from './TimeWorkerProject.vue';

  export default {
    name: "TimeWorker",
    components: {
      TheAvatar,
      TimeWorkerProject,
    },
    props: {
      info: Object,
      worker: Object
    },
    data() {
      return {
        opened: false,
      }
    },
    computed: {
      spent() {
        if (this.info && this.info.spent) {
            return SpentService.spent(this.info.spent);
        }
        return '';
      }
    }
  }
</script>

<style>

</style>