import Vue from 'vue';
import TaskService from '../../services/task.service';
import ApiService from "../../services/api.service";

const taskModule = {
  namespaced: true,
  state: {
    task: {},
    isLoadingFile: false,
  },
  getters: {
    taskProjectId(state) {
      return state.task.project_id;
    },
    taskExecutors(state) {
      return state.task.executors;
    },
    taskPerformer(state, getters, rootState) {
      return rootState.worker.workers.find(worker => worker.id === state.task.performer_id);
    },
    text(state) {
      return state.task.text;
    },
    images(state) {
      if (state.task.files !== undefined) {
        return state.task.files.filter(file => file.name.match(/\.(jpg|png)$/) !== null);
      }
      return null;
    },
    docs(state) {
      if (state.task.files !== undefined) {
        return state.task.files.filter(file => file.name.match(/\.(jpg|png)$/) === null);
      }
      return null;
    },
    comments(state) {
      return state.task.comments;
    },
    task(state) {
      return state.task;
    },
    taskTags(state) {
      return state.task.tags;
    },
  },

  actions: {
    // Добавляем задачу
    async storeTask({commit}, payload) {
      try {
        console.log('workStore');
        const response = TaskService.storeTask(payload);
      } catch (e) {
        console.log(e);
      }
    },
    // Очищаем данные по задаче в хранилище
    clearTask({commit}) {
      commit('clearTask');
    },
    // Обновляем на сервере данные по задаче
    updateTaskStatus({}, payload) {
      return TaskService.updateStatus(payload.taskId, payload.status);
    },
    // Добавляем тэг на клиенте
    async addTag({commit}, tag) {
      commit('addTag', tag);
    },
    // Удаляем тэг на клиенте
    async removeTag({commit}, tag) {
      commit('removeTag', tag);
    },
    // Получаем данные с сервера по задаче
    async fetchTask({commit}, id) {
      try {
        const response = await TaskService.getTaskById(id);
        commit('setTask', response.data.data[0]);
      } catch (e) {
        console.log(e);
      }
    },
    // Обновляем данные по задаче
    async refreshTask({commit, state}) {
      try {
        const response = await TaskService.getTaskById(state.task.id);
        commit('refreshTask', response.data.data[0]);
      } catch (e) {
        console.log(e);
      }
    },
    // Добавляем на сервер тэги задачи
    async storeTags({commit}, payload) {
      try {
        TaskService.storeTags(payload.taskId, payload.tags);
      } catch (e) {
        console.log(e);
      }
    },
    // Обновляем описание в сторе и на сервере
    async updateDescription({commit}, payload) {
      try {
        TaskService.updateDescription(payload.task.id, payload.text);
        commit('setDescription', payload.text);
      } catch (e) {
        console.log(e);
      }
    },
    // Загружаем файлы на сервер
    async storeFile({commit}, id) {
      try {
        const response = await TaskService.getTaskById(id);
        commit('setTask', response.data.data[0]);
      } catch (e) {
        console.log(e);
      }
    },
    // Получаем файлы с сервера
    async fetchFiles({ commit }, id) {
      try {
        const response = await TaskService.getFiles(id);
        commit('setFiles', response.data);
      } catch (e) {
        console.log(e);
      }
    },
    // Удаляем файл
    async deleteFile({ commit }, payload) {
      try {
        await TaskService.deleteFile(payload.projectId, payload.fileId);
        commit('deleteFile', payload.fileId);
      } catch (e) {
        console.log(e);
      }
    },
    // Получаем комментарии с сервера
    async fetchComments({ commit }, id) {
      try {
        const response = await TaskService.getComments(id);
        commit('setComments', response.data.data);
      } catch (e) {
        console.log(e);
      }
    },
    // Получаем исполнителей с сервера
    async fetchExecutors({commit}, id) {
      try {
        const response = await TaskService.getExecutors(id);
        commit('setExecutors', response.data.data);
      } catch (e) {
        console.log(e);
      }
    },
    async storeExecutors({commit, state}, value) {
      try {
        TaskService.storeExecutors(state.task.id, value);
        commit('setExecutors', value);
      } catch (e) {
        console.log(e);
      }
    },
    async storePerformerId({commit, state}, value) {
      try {
        await TaskService.storePerformerId(state.task.id, value);
        commit('setPerformerId', value);
      } catch (e) {
        console.log(e);
      }
    },
    async updateTitle({commit, state}, value) {
      try {
        await TaskService.updateTitle(state.task.id, value);
        commit('setName', value);
      } catch (e) {
        console.log(e);
      }
    },
    async storeComment({ dispatch }, payload) {
      try {
        await TaskService.storeComment(payload.taskId, payload.workerId, payload.comment);
        dispatch('fetchComments', payload.taskId);
      } catch (e) {
        console.log(e);
      }
    },
    async updateComment({dispatch}, payload) {
      try {
        await TaskService.updateComment(payload.commentId, payload.comment);
        dispatch('fetchComments', payload.taskId);
      } catch (e) {
        console.error(e);
      }
    },
    async deleteComment({commit}, payload) {
      try {
        TaskService.deleteComment(payload.commentId);
        commit('deleteComment', payload.commentId);
      } catch (e) {
        console.log(e);
      }
    },
    async updateStatus({dispatch}, payload) {
      return TaskService.updateStatus(payload.taskId, payload.newStatus);
    },
    async updateParent({dispatch}, payload) {
      return TaskService.updateParent(payload.taskId, payload.newParent);
    },
    async storeDeadline({commit}, payload) {
      try {
        TaskService.storeDeadline(payload.taskId, payload.deadline);
      } catch (e) {
        console.log(e);
      }
    },
    async storeStartAt({commit}, payload) {
      try {
        TaskService.storeStartAt(payload.taskId, payload.start_at);
      } catch (e) {
        console.log(e);
      }
    },
    async updateIsArchive({dispatch}, taskId) {
      try {
        await TaskService.updateIsArchive(taskId);
      } catch (e) {
        console.log(e);
      }
    },
    async setEstimate({dispatch}, payload) {
      try {
        await TaskService.setEstimate(payload.taskId, payload.estimate);
      } catch (e) {
        console.log(e);
      }
    },
    async startTask({dispatch}, taskId) {
      try {
        await TaskService.startTask(taskId);
      } catch (e) {
        console.log(e);
      }
    },
    async endTask({ dispatch }, taskId) {
      try {
        await TaskService.endTask(taskId);
      } catch (e) {
        console.log(e);
      }
    },
    async storeProjectId({ commit, state }, value) {
      try {
        TaskService.storeProjectId(state.task.id, value);
        commit('setProjectId', value);
      } catch (e) {
        console.log(e);
      }
    },
    async storeSupport({ commit, state }, value) {
      try {
        TaskService.storeSupport(state.task.id, value);
        commit('setSupport', value);
      } catch (e) {
        console.log(e);
      }
    },
    async storeTimeEstimate({ commit, state }, value) {
      try {
        TaskService.storeTimeEstimate(state.task.id, value);
        commit('setTimeEstimate', value);
      } catch (e) {
        console.log(e);
      }
    },
    async createRepeater({ state }, data) {
      try {
        await TaskService.createRepeater(state.task.id, data);
      } catch (e) {
        console.log(e);
      }
    },
    async deleteRepeater({ state }) {
      try {
        await TaskService.deleteRepeater(state.task.id);
      } catch (e) {
        console.log(e);
      }
    },
  },

  mutations: {
    setTask(state, task) {
      state.task = task;
      if (state.task.files === undefined) {
        Vue.set(state.task, 'files', []);
      }
      if (state.task.comments === undefined) {
        Vue.set(state.task, 'comments', []);
      }
      if (state.task.executors === undefined) {
        Vue.set(state.task, 'executors', []);
      }
      if (state.task.tags === undefined) {
        Vue.set(state.task, 'tags', []);
      }
      if (state.task.times === undefined) {
        Vue.set(state.task, 'times', []);
      }
    },
    refreshTask(state, task) {
      Object.entries(task).forEach(([key, value]) => {
        state.task[key] = value;
        Vue.set(state.task, key, value);
      });
    },
    clearTask(state) {
      state.task = {};
    },
    setDescription(state, text) {
      state.task.text = text;
    },
    setName(state, name) {
      state.task.name = name;
    },
    setFiles(state, files) {
      Vue.set(state.task, 'files', files);
    },
    deleteFile(state, id) {
      const filtered = state.task.files.filter(file => file.id !== id);
      state.task.files = filtered;
    },
    setComments(state, comments) {
      state.task.comments = comments;
    },
    deleteComment(state, commentId) {
      const filtered = state.task.comments.filter(comment => comment.id !== commentId);
      state.task.comments = filtered;
    },
    setExecutors(state, executors) {
      state.task.executors = executors;
    },
    setPerformerId(state, performerId) {
      state.task.performer_id = performerId;
    },
    addExecutor(state, executor) {
      state.task.executors.push(executor);
    },
    removeExecutor(state, executor) {
      const filtered = state.task.executors.filter(executorItem => executorItem.id !== executor.id);
      state.task.executors = filtered;
    },
    addTag(state, tag) {
      state.task.tags.push(tag);
    },
    removeTag(state, tag) {
      const filtered = state.task.tags.filter(tagItem => tagItem.id !== tag.id);
      state.task.tags = filtered;
    },
    startLoadingFile(state) {
      state.isLoadingFile = true;
    },
    stopLoadingFile(state) {
      state.isLoadingFile = false;
    },
    setProjectId(state, projectId) {
      state.task.project_id = projectId;
    },
    setSupport(state, support) {
      state.task.support = !!support;
    },
    setTimeEstimate(state, timeEstimate) {
      state.task.isTimeEstimate = !!timeEstimate;
    },
  },
};

export default taskModule;