const ModalMixin = {
  data() {
    return {
      showModal: this.$route.meta.showModal,
    };
  },
  watch: {
    '$route.meta' ({ showModal }) {
      this.showModal = showModal;
    },
  },
};

export default ModalMixin;