<template>
  <div class="sidebar" v-show="sidebarIsOpen">
    <div class="sidebar__head">
      <a href="/" class="sidebar__logo">
        <IconLogo/>
      </a>
      <div class="sidebar__actions">
        <a href="#" class="sidebar__closer" @click.prevent="handleClose">
          <IconArrowGrey />
        </a>
        <a href="#"
           class="sidebar__hamburger"
           :class="{'sidebar__hamburger_closer': isUserOptions}"
           @click.prevent="handleClickMenu"
        >
          <span class="sidebar__hamburger-line"></span>
          <span class="sidebar__hamburger-line"></span>
          <span class="sidebar__hamburger-line"></span>
        </a>
      </div>
    </div>
    <div class="sidebar__main">
      <div class="sidebar__user-options" v-show="isUserOptions">
        <SidebarUserOptions
          :worker="userWorker"
          :color="color"
          @createProject="handleCreateProject"
          @changeColor="handleChangeColor"
          @selectColor="handleSelectColor"
        />
      </div>
      <div class="sidebar__projects" v-show="!isUserOptions">

        <SidebarSearch/>

        <vue-simplebar>
          <div class="sidebar__all">
            <router-link
                    :to="{name:'projects'}"
                    exact
                    class="sidebar__link sidebar__link_iconable"
                    :class="isActiveClass"
            >
              <span class="sidebar__link-icon">
                <img src="/static/images/svg/all-tasks.svg" alt="Все задачи">
              </span>
              <span class="sidebar__name">Все задачи</span>
            </router-link>

            <router-link
                :to="{name:'projects_project'}"
                exact
                class="sidebar__link sidebar__link_iconable"
                :class="isActiveClassProject"
            >
              <span class="sidebar__link-icon">
                <img src="/static/images/svg/all-tasks.svg" alt="Все задачи">
              </span>
              <span class="sidebar__name">Проектные</span>
            </router-link>

            <router-link
                :to="{name:'projects_support'}"
                exact
                class="sidebar__link sidebar__link_iconable"
                :class="isActiveClassSupport"
            >
              <span class="sidebar__link-icon">
                <img src="/static/images/svg/all-tasks.svg" alt="Все задачи">
              </span>
              <span class="sidebar__name">Саппорт</span>
            </router-link>
          </div>
          <SidebarGroupsList/>
        </vue-simplebar>

      </div>
    </div>
  </div>
</template>

<script>
  import IconLogo from "../../../icons/IconLogo.vue";
  import SidebarSearch from "./SidebarSearch.vue";
  import SidebarGroupsList from "./SidebarGroupsList.vue";
  import { mapState, mapActions, mapMutations } from "vuex";
  import IconArrowGrey from "../../../icons/IconArrowGrey.vue";
  import SidebarUserOptions from "./SidebarUserOptions.vue";
  import UploadUserOptionsService from "../../../services/uploadavatar.service";
  import HSLtoHEX from "../../../utils/color-helper";


  export default {
    name: "AppSidebar",
    components: {SidebarUserOptions, IconArrowGrey, SidebarGroupsList, SidebarSearch, IconLogo},
    computed: {
      ...mapState(['sidebarIsOpen', 'isUserOptions']),
      ...mapState('worker', [
          'userWorker'
      ]),
      ...mapState('user', [
        'user'
      ]),
      isActiveClass() {
        return this.$route.name === 'projects' ? 'active' : null
      },
      isActiveClassProject() {
        return this.$route.name === 'projects_project' ? 'active' : null
      },
      isActiveClassSupport() {
        return this.$route.name === 'projects_support' ? 'active' : null
      }
    },
    data() {
      return {
        color: {
          hue: 50,
          saturation: 100,
          luminosity: 50,
          alpha: 1,
        },
        colorHEX: null,
      }
    },
    methods: {
      ...mapActions({
        storeColor: 'worker/storeColor'
      }),
      ...mapMutations({
        toggleSidebar: 'toggleSidebar',
        closeSidebar: 'closeSidebar',
        toggleUserOptions: 'toggleUserOptions'
      }),
      handleClose() {
        this.toggleSidebar();
      },
      handleClickMenu() {
        this.toggleUserOptions();
      },
      handleCreateProject() {
        this.$notify({
          group: 'foo',
          title: 'Ошибка пользователя #287153',
          text: 'Пока нельзя создать проект. Мы работаем над этим функционалом'
        })
      },
      handleChangeColor(hue) {
        this.color.hue = hue;
      },
      handleSelectColor() {
        this.hslToHex();
        this.storeColor({ workerId: this.userWorker.id, color: this.colorHEX });
      },
      hslToHex() {
        this.colorHEX = HSLtoHEX(this.color.hue, this.color.saturation, this.color.luminosity);
      }
    },
    mounted() {
      const avatarUpload = new UploadUserOptionsService({
        button: '.avatar-change',
        endpoint: '/api/workers/avatar',
        userId: this.user.id,
      });
      const bgUpload = new UploadUserOptionsService({
        button: '.background-change',
        endpoint: '/api/workers/background',
        userId: this.user.id
      });
    }
  }
</script>

<style lang="scss">

</style>