<template>
  <ProjectContainer v-slot="{project}">
    <ProjectLayout :project="project">
      <div slot="kanban">
        <div class="project-wiki">
          <ProjectWiki class="project-wiki__item" :initialDescription = project.description :projectId = project.id />

          <ProjectCredentials class="project-wiki__item" :project = project />
        </div>
      </div>
    </ProjectLayout>
  </ProjectContainer>
</template>

<script>
  import ProjectContainer from "../containers/ProjectContainer.vue";
  import ProjectLayout from "../layouts/ProjectLayout.vue";
  import ProjectWiki from "../components/app/project/ProjectWiki.vue";
  import ProjectCredentials from '../components/app/project/ProjectCredentials.vue';

  export default {
    name: "TheProjectWiki",
    components: {
      ProjectCredentials,
      ProjectContainer,
      ProjectLayout,
      ProjectWiki
    },
  }
</script>

<style scoped>

</style>