<template>
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
    <title>Group 12</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Все-задачи-Copy-6" transform="translate(-994.000000, -254.000000)">
        <g id="Group-15" transform="translate(975.000000, 64.000000)">
          <g id="Group-14" transform="translate(0.000000, 140.000000)">
            <g id="Group-12" transform="translate(30.646447, 58.353553) rotate(-315.000000) translate(-30.646447, -58.353553) translate(25.646447, 49.853553)">
              <polygon id="Rectangle" stroke="#C1C4D3" stroke-width="2" points="6.3190992e-13 4.49809441 5.13618316 0.864087658 10 4.49809441 10 16.8640877 6.3190992e-13 16.8640877"></polygon>
              <circle id="Oval" fill="#C1C4D3" cx="5" cy="7" r="2"></circle>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IconTags"
  }
</script>

<style>

</style>