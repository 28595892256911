const TOKEN_KEY = 'token';

const TokenService = {
  getToken() {
    return window.localStorage.getItem(TOKEN_KEY);
  },

  setToken(token) {
    window.localStorage.setItem(TOKEN_KEY, token);
  },

  removeToken() {
    window.localStorage.removeItem(TOKEN_KEY);
  },
};

export default TokenService;