<template>
    <div class="pipeline-form project-subpage" v-if="!loading">
        <div class="project-subpage__container">
            <div class="checklist-form__head">
                <router-link :to="{name: 'project_checklists', params: {projectId: project.id}}" class="checklist-form__back">
                    &larr;
                </router-link>
                <div class="checklist-form__head-info">
                    <h1 class="checklist-form__title">
                        Редактирование плана
                    </h1>

                    <div class="checklist-form__head-worker" v-if="worker">
                        <div class="checklist-form__head-worker-title">
                            Исполнитель:
                        </div>
                        <TheAvatar :worker="worker"></TheAvatar>
                        <div class="checklist-form__head-worker-name">
                            {{ worker.name }}
                        </div>
                    </div>
                </div>
            </div>


            <div class="checklist-form__name">
                <input type="text" v-model="checklist.name" class="checklist-form__name-input" placeholder="Наименование">
            </div>

            <ul class="checklist-form__items">
                <TheChecklistItem
                    v-for="(item, key) in items"
                    :data-key="key"
                    :key="key"
                    :item="item"
                    :has-status="true"
                    @delete="removeItem(key)"
                    @addNext="addAfter(key)"
                />
            </ul>

            <div class="checklist-form__actions">
                <button type="submit" class="checklist-form__submit" @click="save">
                    Сохранить
                </button>
                <a href="javascript: void(0);" @click="remove" v-if="id">
                    Удалить
                </a>
            </div>
        </div>
    </div>
    <Loading v-else/>
</template>

<script>
import Loading from "../../../ui/Loading";
import TheChecklistItem from "../item/ChecklistItem";
import ProjectChecklistService from "../../../../services/projectchecklist.service";
import TheAvatar from "../../../ui/TheAvatar";
import {mapGetters} from "vuex";

export default {
  name: "TheProjectChecklistForm",
  components: {
    TheAvatar,
    TheChecklistItem,
    Loading
  },
  props: {
    project: Object,
    id: {
      required: false,
      type: Number
    },
  },
  data() {
    return {
      loading: true,
      checklist: {
        id: 0,
        name: '',
        project_id: 0,
        owner_id: 0
      },
      items: [],
      arrows: []
    }
  },
  mixins: [

  ],
  computed: {
    ...mapGetters('worker', [
      'getWorkerById'
    ]),
    taskIds() {
      return this.items.map((item) => item.id);
    },
    worker() {
      return this.getWorkerById(this.checklist.owner_id)
    }
  },
  methods: {
    getDefaultItem() {
      return {
        text: '',
        status: 0,
      }
    },
    removeItem(index) {
      this.items.splice(index, 1);
      if (index > 0) {
        this.$nextTick(() => {
          console.log(index - 1);
          document.querySelector(`.checklist-item[data-key="${index - 1}"] textarea`).focus()
        });
      }
    },
    addAfter(index) {
      const nextItem = this.getDefaultItem();
      this.items.splice(index + 1, 0, nextItem);
      this.$nextTick(() => {
        document.querySelector(`.checklist-item[data-key="${index + 1}"] textarea`).focus()
      });
    },
    async save() {
      try {
        if (this.id) {
          const response = await ProjectChecklistService.update(this.checklist.id, {
            items: this.items,
            checklist: this.checklist
          });
        } else {
          const response = await ProjectChecklistService.create(this.project.id, {
            items: this.items,
            checklist: this.checklist
          });
        }
        this.$router.push({name: 'project_checklists', params: {projectId: this.project.id}});
      } catch (e) {
        console.log(e);
        this.$notify({
          group: 'foo',
          title: 'Ошибка сохранения чеклиста'
        })
      }
    },
    async remove() {
      if (confirm('Ты чиво собрался наделать? Ты хочешь план удалить?')) {
        try {
          if (this.id) {
            const response = await ProjectChecklistService.delete(this.checklist.id);
            this.$router.push({name: 'project_checklists', params: {projectId: this.project.id}});
          }
        } catch (e) {
          console.log(e);
          this.$notify({
            group: 'foo',
            title: 'Ошибка сохранения чеклиста'
          })
        }
      }
    },
    async init() {
      this.loading = true;
      console.log(this.id);
      if (this.id) {
        try {
          const response = await ProjectChecklistService.get(this.id);
          this.checklist = response.data.data.checklist;
          this.items = response.data.data.items;
          if (this.items.length === 0) {
            this.items.push(this.getDefaultItem());
          }
        } catch (e) {
          console.log(e);
          this.$notify({
            group: 'foo',
            title: 'Ошибка получения чеклиста'
          })
        }
      } else {
        this.items.push(this.getDefaultItem());
      }
      this.loading = false;
    }
  },
  mounted() {
    this.init();
  },
}
</script>

<style lang="scss">

</style>