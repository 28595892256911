// Штуковина, которая умеет матчить подстроку в строке да/нет
// Раскладко/Регистро-независимая (ru/en)

const Matcher = {
  keylist_ru: {
    'й': 'q',
    'ц': 'w',
    'у': 'e',
    'к': 'r',
    'е': 't',
    'н': 'y',
    'г': 'u',
    'ш': 'i',
    'щ': 'o',
    'з': 'p',
    'х': '[',
    'ъ': ']',
    'ф': 'a',
    'ы': 's',
    'в': 'd',
    'а': 'f',
    'п': 'g',
    'р': 'h',
    'о': 'j',
    'л': 'k',
    'д': 'l',
    'ж': ';',
    'э': "'",
    'ё': "\\",
    'я': 'z',
    'ч': 'x',
    'с': 'c',
    'м': 'v',
    'и': 'b',
    'т': 'n',
    'ь': 'm',
    'б': ',',
    'ю': '.',
  },
  keylist_en: {
    'q': 'й',
    'w': 'ц',
    'e': 'у',
    'r': 'к',
    't': 'е',
    'y': 'н',
    'u': 'г',
    'i': 'ш',
    'o': 'щ',
    'p': 'з',
    '[': 'х',
    '{': 'х',
    ']': 'ъ',
    '}': 'ъ',
    'a': 'ф',
    's': 'ы',
    'd': 'в',
    'f': 'а',
    'g': 'п',
    'h': 'р',
    'j': 'о',
    'k': 'л',
    'l': 'д',
    ';': 'ж',
    ':': 'ж',
    "'": "э",
    '"': "э",
    '\\': "ё",
    '|': "ё",
    'z': 'я',
    'x': 'ч',
    'c': 'с',
    'v': 'м',
    'b': 'и',
    'n': 'т',
    'm': 'ь',
    ',': 'б',
    '<': 'б',
    '.': 'ю',
    '>': 'ю',
  },
  match(str, search) {
    const lowerString = str.toLowerCase();
    const lowerSearch = search.toLowerCase();
    const matchedPlain = lowerString.includes(lowerSearch);
    const matchedRu = this.forceEncodeTo(lowerString, this.keylist_ru).includes(lowerSearch);
    const matchedEn = this.forceEncodeTo(lowerString, this.keylist_en).includes(lowerSearch);
    return !!matchedPlain || !!matchedRu || !!matchedEn;
  },
  forceEncodeTo(str, keylist) {
    return str.split('').map((letter) => {
      if (letter in keylist) {
        return keylist[letter];
      }
      return letter;
    }).join('');
  },
};

export default Matcher;