<template>
    <div class="timeline-head timeline-line">
        <div class="timeline-line__legend timeline-head__legend timeline-legend">

        </div>
        <div class="timeline-head__days timeline-days">
            <div class="timeline-days__day timeline-head__day" v-for="(day, key) in days" :key="key">
                <div class="timeline-head__day-name">
                    {{ day.name }}
                </div>
                <div class="timeline-days__hours timeline-head__hours">
                    <div class="timeline-days__hour timeline-head__hour" v-for="(hour, key) in day.hours" :key="key">
                        {{ hour.name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "TimelineHead",
    props: {
      days: Array
    },
  }
</script>

<style>

</style>