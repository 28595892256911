import MembershipService from '../../services/membership.service';

const membershipModule = {
  namespaced: true,
  state: {
    loading: false,
    memberships: [],
    types: [
      { name: 'Исполнитель', value: 10 },
      { name: 'Аккаунт', value: 20 },
      { name: 'Тех.лид', value: 30 },
      { name: 'Дизайн лид', value: 40 },
      { name: 'Маркетинг лид', value: 50 },
    ],
  },
  getters: {
    getByWorkerAndProject(state) {
      return (workerId, projectId) => state.memberships.find(
        membership => membership.worker_id === workerId && membership.project_id === projectId,
      );
    },
  },
  actions: {
    async fetchAll({ commit }) {
      commit('setLoading', true);
      try {
        const response = await MembershipService.all();
        commit('setMemberships', response.data.data);
      } catch (e) {
        console.log(e);
      }
      commit('setLoading', false);
    },
    async create({ commit, dispatch }, { type, workerId, projectId }) {
      try {
        commit('setLoading', true);
        const response = await MembershipService.create(type, workerId, projectId);
        dispatch('fetchAll');
        commit('setLoading', false);
        return response;
      } catch (e) {
        throw e;
      }
    },
    async deleteMembership({ commit, dispatch }, { id }) {
      try {
        commit('setLoading', true);
        const response = await MembershipService.deleteMembership(id);
        dispatch('fetchAll');
        commit('setLoading', false);
        return response;
      } catch (e) {
        throw e;
      }
    },
    async updateType({ commit, dispatch }, { id, type }) {
      try {
        commit('setLoading', true);
        const response = await MembershipService.updateType(id, type);
        dispatch('fetchAll');
        commit('setLoading', false);
        return response;
      } catch (e) {
        throw e;
      }
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setMemberships(state, memberships) {
      state.memberships = memberships;
    },
  },
};

export default membershipModule;