<template>
  <div class="kanban">
    <div class="kanban__inner">
      <ProjectKanbanStatusList
        :tasks="tasks"
        @updateStatus="onUpdateStatus"
      />
    </div>
  </div>
</template>

<script>
  import ProjectKanbanStatusList from './ProjectKanbanStatusList.vue';
  import TaskEditModal from '../../../pages/TheTask.vue';
  import { mapState, mapActions, mapGetters } from 'vuex';

  export default {
    name: "ProjectKanban",
    components: {TaskEditModal, ProjectKanbanStatusList},
    props: {
      tasks: Array
    },
    computed: {
      ...mapGetters('filter', [
        'filters'
      ])
    },
    data() {
      return {
        x: 0,
      }
    },
    methods: {
      ...mapActions({
        fetchTasks: 'project/fetchTasks'
      }),
      onUpdateStatus() {
        this.fetchTasks({id: this.$route.params.projectId, filters: this.filters});
      },
    },
    mounted() {
      console.log('Mounted kanban');
    }
  }
</script>

<style lang="scss">

</style>