<template>
  <ProjectContainer v-slot="{project}">
    <ProjectLayout :project="project">
      <div slot="kanban" v-if="project.id">
        <TheList :id="project.id"/>
      </div>
    </ProjectLayout>
  </ProjectContainer>
</template>

<script>
  import ProjectContainer from "../containers/ProjectContainer.vue";
  import ProjectLayout from "../layouts/ProjectLayout.vue";
  import TheList from "../components/app/pipeline/TheList";

  export default {
    name: "TheProjectPipelines",
    components: {
      TheList,
      ProjectContainer,
      ProjectLayout
    },
  }
</script>

<style>

</style>