<template>
    <ul class="sidebar__items">
        <SidebarGroupsProjectsItem v-for="project in projects" :key="project.id"
                            :project="project" v-if="isVisible(project)"
        />
    </ul>

</template>

<script>
  import SidebarGroupsProjectsItem from "./SidebarGroupsProjectsItem.vue";
  export default {
    name: "SidebarGroupsProjectsList",
    components: {SidebarGroupsProjectsItem},
    props: {
      projects: Array
    },
    methods: {
      isVisible(project) {
        const filterValue = this.$store.state.project.searchInputProject.toUpperCase();
        return (!filterValue || project.name.toUpperCase().indexOf(filterValue) >= 0);
      }
    }
  }
</script>

<style scoped>

</style>