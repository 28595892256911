<template>
    <div class="membership-container">
        <div class="membership-container__line membership-container__head">
            <div class="membership-container__line-head">

            </div>
            <div class="membership-container__line-values">
                <div class="membership-container__value" v-for="worker in workers">
                    <div class="membership-container__worker-name">
                        <span>
                            {{ worker.name }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="membership-container__aside membership-container__line" v-for="project in projects">
            <div class="membership-container__line-head">
                <div class="membership-container__line-head-name">
                    {{ project.name }}
                </div>
            </div>
            <div class="membership-container__line-values">
                <div class="membership-container__value" v-for="worker in workers">
                    <slot v-bind="{ project, worker, membership: getMembershipByWorkerAndProject(worker.id, project.id) }">

                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import MembershipValue from "./MembershipValue.vue";

  export default {
    name: "Membership",
    components: {
      MembershipValue
    },
    props: {
      workers: Array,
      memberships: Array,
      projects: Array
    },
    methods: {
      getMembershipByWorkerAndProject(workerId, projectId) {
        const membership = this.memberships.find(
          membership => membership.worker_id === workerId && membership.project_id === projectId,
        );
        console.log(membership);
        return membership;
      },
    }
  }
</script>