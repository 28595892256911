<template>
    <div class="time-worker__project">
        <div class="time-worker__project-head" v-if="project" @click="opened = !opened">
            <div class="time-worker__project-name">
                {{ project.name }}
            </div>
            <div class="time-worker__project-spent">
                {{ spentTime }}
            </div>
        </div>

        <div class="time-worker__project-tasks" v-if="opened && info.by_tasks">
            <TimeWorkerProjectTask v-for="task in info.by_tasks" :task="task" :key="task.task_id"></TimeWorkerProjectTask>
        </div>
    </div>
</template>

<script>
  import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
  import TimeWorkerProjectTask from './TimeWorkerProjectTask.vue';
  import SpentService from '../../../services/spent.service';

  export default {
    name: "TimeWorkerProject",
    components: {
      TimeWorkerProjectTask
    },
    props: {
      projectId: Number,
      info: Object,
      spent: Number,
      opened: Boolean,
    },
    computed: {
      ...mapGetters({
        getProjectById: 'project/getProjectById'
      }),
      project() {
        return this.getProjectById(this.projectId)
      },
      spentTime() {
        return SpentService.spent(this.spent);
      }
    }
  }
</script>

<style>

</style>