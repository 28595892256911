<template>
  <div class="tag" :style="{background: tag.color}">

  </div>
</template>

<script>
  export default {
    name: "TheTag",
    props: {
      tag: Object,
      required: true,
    }
  }
</script>

<style>

</style>