<template>
  <svg width="17px" height="19px" viewBox="0 0 17 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
    <title>Group 13</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Все-задачи-Copy-6" transform="translate(-995.000000, -404.000000)" stroke="#C1C4D3" stroke-width="2">
        <g id="Group-15" transform="translate(975.000000, 64.000000)">
          <g id="Group-14" transform="translate(0.000000, 140.000000)">
            <g id="Group-13" transform="translate(22.000000, 201.000000)">
              <polygon id="Rectangle" points="0 4 13 4 11.8614613 17 1.19912997 17"></polygon>
              <path d="M4.5,7.5 L5,13.6513672" id="Line" stroke-linecap="square"></path>
              <path d="M8.25,7.5 L8.75,13.6513672" id="Line-Copy-6" stroke-linecap="square" transform="translate(8.500000, 10.575684) scale(-1, 1) translate(-8.500000, -10.575684) "></path>
              <path d="M13,3 L1.80761184,1" id="Line-2" stroke-linecap="square"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IconArchive"
  }
</script>

<style>

</style>