<template>
    <div class="time-project__worker" v-if="worker">
        <div class="time-project__worker-container" @click="opened = !opened">
            <div class="time-project__worker-info">
                <div class="time-project__worker-avatar">
                    <TheAvatar :worker="worker" :small="true"/>
                </div>
                <div class="time-project__worker-name">
                    {{ worker.name }}
                </div>
            </div>

            <div class="time-project__worker-spent">
                {{ spentTime }}
            </div>
        </div>
        <div class="time-project__worker-tasks" v-if="opened && info.by_tasks">
            <TimeProjectWorkerTask v-for="task in info.by_tasks" :task="task" :key="task.task_id"></TimeProjectWorkerTask>
        </div>
    </div>
</template>

<script>
  import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
  import SpentService from '../../../services/spent.service';
  import TimeProjectWorkerTask from './TimeProjectWorkerTask.vue';
  import TheAvatar from "../../ui/TheAvatar.vue";

  export default {
    name: "TimeProjectWorker",
    components: {TheAvatar, TimeProjectWorkerTask},
    props: {
      workerId: Number,
      info: Object,
      spent: Number,
      opened: Boolean,
    },
    computed: {
      ...mapGetters({
        getWorkerById: 'worker/getWorkerById'
      }),
      worker() {
        return this.getWorkerById(this.workerId)
      },
      spentTime() {
        return SpentService.spent(this.spent);
      }
    }
  }
</script>

<style>

</style>