<template>
  <KanbanLayout>
    <h2 slot="title">
      Все задачи
    </h2>

    <Tabs
      slot="tabs"
    />

    <ProjectSearchTask slot="options"/>

    <ProjectFilters
        slot="filters"
    />

    <div slot="kanban">
      <TasksContainer v-slot="{tasks}">
        <ProjectKanban :tasks="tasks"></ProjectKanban>
      </TasksContainer>
    </div>

    <BaseModal
      v-show="showModal"
      slot="modal"
    >
      <router-view name="task"/>
    </BaseModal>
  </KanbanLayout>
</template>

<script>
  import ProjectKanban from "../components/app/project/ProjectKanban.vue";
  import TasksContainer from "../containers/TasksContainer.vue";
  import KanbanLayout from "../layouts/KanbanLayout.vue";
  import ProjectFilters from "../components/app/project/ProjectFilters.vue";
  import BaseSearch from "../components/ui/multiselect/BaseSearch.vue";
  import BaseModal from "../components/ui/BaseModal.vue";
  import ProjectLayout from "../layouts/ProjectLayout.vue";
  import Tabs from "../components/app/index/Tabs.vue";
  import ProjectSearchTask from '../components/app/project/ProjectSearchTask.vue';

  import ModalMixin from "../mixins/modal.mixin";

  export default {
    name: "TheTasks",
    components: {
      Tabs,
      TasksContainer,
      ProjectLayout,
      ProjectKanban,
      KanbanLayout,
      ProjectFilters,
      BaseSearch,
      BaseModal,
      ProjectSearchTask
    },
    mixins: [ModalMixin],
  }
</script>

<style scoped>

</style>