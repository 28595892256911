<template>
  <div class="project-subhead__filters project-filters">
    <div class="project-filters__item">
      <ArchiveFilter />
    </div>
    <div class="project-filters__item">
      <ExecutorFilter />
    </div>
    <div class="project-filters__item">
      <OwnerFilter />
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import ExecutorFilter from "./ExecutorFilter.vue";
  import OwnerFilter from "./OwnerFilter.vue";
  import TagFilter from "./TagFilter.vue";
  import ArchiveFilter from "./ArchiveFilter.vue"

  export default {
    name: "ProjectFilters",
    components: {TagFilter, ExecutorFilter, OwnerFilter, ArchiveFilter},
    computed: {
      ...mapState('worker', [
        'workers'
      ])
    },
    data() {
      return {

      }
    },
    methods: {

    },

  }
</script>

<style>

</style>