<template>
    <div class="task-project">
        <div class="task-index__additional task-index__additional_last" v-if="!editing">
            <a class="task-index__additional-item task-index__additional-item_editable" @click.prevent="toggleEditing">
                <span class="task-index__additional-label">Проект:</span>
                <span class="task-index__additional-value">{{projectName}}</span>
            </a>
        </div>

        <div class="task-project-select" v-else>
            <BaseSelect
                    title="Проект"
                    name="Проект"
                    :value="taskProjectId"
                    :options="projectsChoices"
                    @input="onInput"
                    @close="onCloseEditing">
                <template v-slot:option="{option, isActive}">
                    <a href="javascript:void(0)" class="select-item__link">
                        <div class="select-item__name">
                            {{option.name}}
                        </div>
                        <div class="select-item__icon" v-if="isActive">
                            <IconCheckRed/>
                        </div>
                    </a>
                </template>
            </BaseSelect>
        </div>
    </div>
</template>

<script>
  import {mapGetters, mapState, mapActions} from "vuex";
  import BaseSelect from '../../ui/multiselect/BaseSelect.vue';
  import IconCheckRed from "../../../icons/IconCheckRed.vue";

  export default {
    name: "TaskProject",
    components: {
      BaseSelect,
      IconCheckRed
    },
    data() {
        return {
          editing: false,
        }
    },
    computed: {
      ...mapGetters('task', [
        'taskProjectId'
      ]),
      ...mapGetters('project', [
        'projectsChoices'
      ]),
      projectName() {
        let name = "Не указан";
        this.projectsChoices.forEach( option => {
          if (this.taskProjectId == option.id) {
            name = option.name;
          }
        });
        return name;
      }
    },
    methods: {
      onCloseEditing() {
        this.toggleEditing();
      },
      onInput(value) {
        this.$store.dispatch('task/storeProjectId', value);
        this.toggleEditing();
      },
      ...mapActions('project', [
        'fetchAllProjects'
      ]),
      toggleEditing() {
        this.editing = !this.editing;
      }
    },
    mounted() {
      this.fetchAllProjects();
    }
  }
</script>

<style lang="scss">
    .task-project {
        position: relative;
    }
    .task-project-select {
        padding-bottom: 20px;
        position: absolute;
        min-height: 200px;
        width: 100%;
        background-color: #ffffff;
        z-index: 3;
        -webkit-box-shadow: 0 20px 30px 0 rgba(32, 38, 47, 0.09);
        box-shadow: 0 20px 30px 0 rgba(32, 38, 47, 0.09);
    }
</style>