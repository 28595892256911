<template>
    <div class="project-checklist-list project-subpage">
        <div class="project-subpage__container">
            <Loading v-if="loading"/>
            <div class="project-checklist-list__groups" v-else>

                <div class="project-checklist-list__group" v-for="group in groups" :class="[ group.class ]">
                    <div class="project-checklist-list__group-name">
                        {{ group.name }}
                    </div>
                    <div class="project-checklist-list__content" >
                        <div class="project-checklist-list__item" v-for="checklist in group.checklists" :key="checklist.id">
                            <div class="project-checklist-list__checklist">
                                <div class="checklist-view-container">
                                    <router-link :to="{name: 'project_checklist_update', params: {projectId: id, checklistId: checklist.id}}" class="project-checklist-list__checklist-item">
                                        <div class="project-checklist-list__checklist-name">
                                            {{ checklist.name }}
                                        </div>
                                        <div class="project-checklist-list__checklist-worker" v-if="getWorkerById(checklist.owner_id)">
                                            {{ getWorkerById(checklist.owner_id).name }}
                                        </div>
                                        <div class="project-checklist-list__checklist-stat" v-if="checklist.stat">
                                            Выполнено: {{ checklist.stat.done }} / {{ checklist.stat.count }}
                                        </div>
                                        <ChecklistUpcoming :items="checklist.items"></ChecklistUpcoming>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-checklist-list__nav">
                <router-link :to="{name: 'checklists'}" class="project-checklist-list__nav-link button">
                    Шаблоны планов &rarr;
                </router-link>
            </div>
        </div>
    </div>
</template>


<script>

import Loading from "../../../ui/Loading";
import ProjectChecklistService from "../../../../services/projectchecklist.service";
import {mapGetters} from "vuex";
import ChecklistUpcoming from "../upcoming/ChecklistUpcoming";


export default {
  name: "TheProjectChecklistList",
  components: {ChecklistUpcoming, Loading },
  props: {
    id: Number
  },
  data() {
    return {
      loading: false,
      checklists: [],
    }
  },
  computed: {
    ...mapGetters('worker', [
      'getWorkerById'
    ]),
    doneChecklists() {
      return this.checklists.filter((item) => item.stat.done === item.stat.count);
    },
    progressChecklists() {
      return this.checklists.filter((item) => item.stat.done !== item.stat.count);
    },
    groups() {
      return [
        {
          name: 'В работе',
          class: '_work',
          checklists: this.progressChecklists
        },
        {
          name: 'Выполнено',
          class: '_done',
          checklists: this.doneChecklists
        },
      ]
    }
  },
  methods: {
    async fetch() {
      this.loading = true;
      try {
        const response = await ProjectChecklistService.all(this.id);
        this.checklists = response.data.data;
        console.log(this.checklists);
      } catch (e) {
        console.log(e);
        this.$notify({
          group: 'foo',
          title: 'Не удалось получить чеклисты'
        })
      }
      this.loading = false;
    },
  },
  mounted() {
    console.log(this.id);
    this.fetch();
  }
}
</script>

<style lang="scss">
.project-checklist-list {

  &__checklist-name {

  }

  &__checklist-worker, &__checklist-stat {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.41);
  }

  &__content{
    display: block;
  }

  &__item {
    color: white;
    padding: 9px 19px;
    background: #191B1E;
    margin-bottom: 5px;
    width: 570px;
    position: relative;

    .checklist-upcoming {
      position: absolute;
      left: calc(100% + 20px);
      top: 0;
      opacity: 0;
      transform: translate3d(0, -10px, 0);
      transition: all 0.3s;
    }

    &:hover {
      .checklist-upcoming {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &__checklist-item {
    display: grid;
    grid-template-columns: 1fr 150px 150px;
    grid-gap: 10px;
  }

  &__checklist-stat {
    text-align: right;
  }

  &__group {
    margin-bottom: 20px;
  }

  &__group-name {
    margin-bottom: 20px;
    color: #fff;
    font-size: 18px;
  }

  &__nav {
    margin-top: 40px;
  }
}
</style>