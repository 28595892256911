<template>
    <li class="statuses-list__item">
        <div class="status">
            <span class="status__icon" :style="{background: tag.color}"></span>
            <span class="status__name" :style="{color: tag.color}">{{tag.name}}</span>
        </div>
    </li>
</template>

<script>
  export default {
    name: "TaskTagsItem",
    props: {
      tag: Object
    }
  }
</script>

<style>

</style>