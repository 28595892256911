<template>
    <div class="log panel">
        <div class="panel__head">
            <div class="panel__head-content">
                <div class="panel__caption">
                    <div class="panel__title">
                        Лог изменений
                    </div>
                </div>
                <div class="panel__filter">
                    <a href="javascript:void(0);" class="panel__filter-link" @click.prevent="toggleFilter">
                        Фильтр ({{filtered.length}})
                    </a>
                    <div class="panel__filter-info" v-if="openedFilter">
                        <div class="panel__filter-item" v-for="(text, status) in names">
                            <input
                                type="checkbox"
                                v-model="filtered"
                                :id="'status_' + status"
                                :value="status"
                                class="panel__filter-checkbox"
                                @change="changedFilter"/>

                            <label :for="'status_' + status" class="panel__filter-checkbox-label">
                                {{text}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel__body">
            <vue-simplebar class="panel__scroll">
                <div class="panel__container">
                    <div class="log__date" v-for="date in byDates" :key="date.name">
                        <div class="log__date-name">
                            {{date.name}}
                        </div>
                        <div class="log__date-items">
                            <LogItem
                                v-for="event in date.events"
                                :event="event"
                                class="log__item"
                                :key="event.id"
                            />
                        </div>
                    </div>

                    <div class="log__load-more-wrapper">
                        <a href="javascript:void(0);" class="log__load-more button" @click.prevent="loadMore">
                            Загрузить еще &darr;
                        </a>
                    </div>
                </div>
            </vue-simplebar>
        </div>
    </div>
</template>

<script>
  import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
  import LogItem from "../../parts/LogItem.vue";

  export default {
    name: "TheLog",
    components: { LogItem },
    props: {
      projectId: {
        type: Number,
        required: false
      },
    },
    computed: {
      ...mapGetters({
        byDates: 'log/byDates'
      }),
      ...mapState('log', [
        'filterTypes',
        'names'
      ]),
    },
    data() {
      return {
        openedFilter: false,
        filtered: [],
      }
    },
    mounted() {
      this.fetchLoading(this.projectId);
      this.fetchWorkers();
      this.filtered = this.filterTypes;
    },
    methods: {
      ...mapActions({
        fetchLoading: 'log/fetchLoading',
        refreshLoading: 'log/refreshLoading',
        more: 'log/more',
        fetchWorkers: 'worker/fetchWorkersIfNotLoaded',
      }),
      ...mapMutations('log', [
        'setFilter'
      ]),
      toggleFilter() {
        this.openedFilter = !this.openedFilter;
      },
      changedFilter() {
        this.setFilter(this.filtered);
        this.fetchLoading(this.projectId);
      },
      loadMore() {
        this.more();
      }
    }
  }
</script>

<style>

</style>