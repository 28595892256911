<template>
    <div class="pipeline-item">
        <div class="pipeline-item__head">
            <div class="pipeline-item__num">
                #{{ item.id }}
            </div>
            <div class="pipeline-item__text">

                <textarea-autosize
                    v-model="item.name"
                    class="pipeline-item__textarea"
                    placeholder="Введите заголовок задачи..."
                    @keydown.native="onInput"
                    @input.native="$emit('change')"
                    rows="1"
                    :minHeight="36"
                >
                </textarea-autosize>
            </div>
            <div class="pipeline-item__options">
                <select v-model="item.performer_id" class="pipeline-item__performer" @input="$emit('change')">
                    <option :value="null">---</option>
                    <option v-for="worker in activeWorkers" :value="worker.id" :key="worker.id">
                        {{ worker.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="pipeline-item__next" @click="$refs.nextInput.focus()">
            <div class="pipeline-item__next-items">
                <span v-for="(nextItem, key) in item.next" class="pipeline-item__next-item" @click.prevent="removeNextItem(key)">{{ nextItem }}</span>
                <input type="text" list="allowed-next-item" ref="nextInput" class="pipeline-item__next-input" v-model="nextItem" @keydown.enter="tryAddNextItem">
                <datalist id="allowed-next-item">
                    <option v-for="allowedNextItem in allowedNextItems" :value="allowedNextItem"/>
                </datalist>
            </div>
        </div>
        <div class="pipeline-item__actions">
            <button class="pipeline-item__add pipeline-item__action" @click="$emit('addNext')">
                &plus;
            </button>

            <button class="pipeline-item__delete pipeline-item__action" @click="remove" v-if="canDelete">
                &times;
            </button>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";

export default {
  name: "Item",
  props: {
    item: Object,
    allowedNextItems: Array,
    canDelete: Boolean
  },
  data() {
    return {
      nextItem: ''
    }
  },
  computed: {
    ...mapState('membership', [
      'types'
    ]),
    ...mapGetters('worker', [
      'activeWorkers'
    ]),
  },
  methods: {
    onInput(e) {
      if (e.ctrlKey && e.keyCode === 13) {
        this.$emit('addNext');
      }
    },
    remove() {
      if (confirm('Вы действительно хотите удалить')) {
        this.$emit('delete');
      }
    },
    removeNextItem(key) {
      if (confirm('Вы действительно хотите удалить связь задач?')) {
        this.item.next.splice(key, 1);
        this.$emit('changeNext');
      }
    },
    tryAddNextItem() {
      const id = this.nextItem.startsWith('n') ? this.nextItem : parseInt(this.nextItem, 10);

      if (this.allowedNextItems.indexOf(id) === -1) {
        this.$notify({
          group: 'foo',
          title: 'Ало, э, чо за задача?'
        })
        return;
      }
      this.item.next.push(id);
      this.nextItem = '';
      this.$emit('changeNext');
    }
  }
}
</script>

<style lang="scss">
.pipeline-item {
  background-color: #fff;
  border-bottom: 1px solid #e1e1e1;
  display: grid;
  grid-template-columns: 1fr 120px 120px;

  &__head {
    display: grid;
    align-items: center;
    grid-template-columns: 55px 1fr 200px;
  }

  &__textarea {
    font-family: inherit;
    border: none;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    display: block;
    padding: 10px 0 10px 0;
    height: 36px;
    min-height: 36px;
    border-right: 1px solid #e1e1e1;
    resize: vertical;
  }

  &__num {
    font-size: 14px;
    padding-left: 8px;
    color: #ababab;
  }

  &__performer {
    height: 36px;
    border: none;
  }

  &__options {
    padding-left: 10px;
  }

  &__actions {
    border-left: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__next {
    border-left: 1px solid #e1e1e1;
  }

  &__next-item {
    background-color: #FF234C;
    color: #fff;
    padding: 0 4px;
    border-radius: 10px;
    margin-right: 4px;
    margin-bottom: 4px;
  }

  &__next-items {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    padding: 4px;
  }

  &__next-input {
    width: 40px;
    padding: 0;
    border: none;
    background-color: transparent;

    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

  &__action {
    display: block;
    padding: 5px 9px;
    border: none;
    color: #fff;
    border-radius: 25px;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__add {
    background-color: #30851f;
  }

  &__delete {
    background-color: #ac111e;
  }
}
</style>