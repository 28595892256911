<template>
  <div class="color-circle">
    <a href="javascript:void(0);"
       class="color-circle__link"
       :style="{background: color}"
       @click.prevent="handleClick"
    >
      <span class="color-circle__selected" v-if="isSelectedData">
        <IconCheckWhite/>
      </span>
    </a>
  </div>
  
</template>

<script>
  import IconCheckWhite from "../../../icons/IconCheckWhite.vue";
  export default {
    name: "TagsCreatorColorItem",
    components: {IconCheckWhite},
    props: {
      color: String,
      selectedColor: String,
    },
    computed: {
      isSelectedData() {
        this.isSelected = false;
        if (this.color === this.selectedColor) {
          this.isSelected = true;
        }
        return this.isSelected;
      }
    },
    data() {
      return {
        isSelected: false,
      }
    },
    methods: {
      handleClick() {
        this.$store.dispatch('tag/selectColor', this.color);
      }
    }
  }
</script>

<style lang="scss">
  .color-circle {
    &__link {
      width: 25px;
      height: 25px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__selected {
      position: relative;
      top: -1px;
    }
  }
</style>