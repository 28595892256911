<template>
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
    <title>noun_send_2324003</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Все-задачи-Copy-6" transform="translate(-904.000000, -1910.000000)" fill="#C1C4D3" fill-rule="nonzero">
        <g id="Group-38" transform="translate(401.000000, 1897.000000)">
          <g id="Group-36" transform="translate(57.000000, 0.000000)">
            <g id="noun_send_2324003" transform="translate(446.000000, 13.000000)">
              <g id="Group">
                <polygon id="Path" points="0.18281118 0.8994975 3.2936026 9.03529 12.5520821 10.3583425 3.2936026 11.681395 0.18281118 19.8171875 19.1005196 10.3583425"></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IconSend"
  }
</script>

<style>

</style>