<template>
    <div class="loading-task">
        <div class="task" @click="show">
            <div class="task__main">
                <div class="task__project" v-if="project">
                    {{project.name}}
                </div>
                <p class="task__description">{{task.name}}</p>
                <p class="task__spent" v-if="task.spent_time > 0" title="Часов:Минут">🕒 Потрачено - {{ spentFormatted }}</p>
                <TaskBottom :task="task" :hidePerformer="true"></TaskBottom>
            </div>
        </div>
    </div>
</template>

<script>
  import {mapGetters, mapActions, mapState} from 'vuex';
  import TaskBottom from "../../parts/TaskBottom.vue";

  export default {
    name: "LoadingGroupWorkerStatusTask",
    props: {
      task: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        spent: this.task.spent_time
      }
    },
    components: {
      TaskBottom
    },
    computed: {
      ...mapGetters({
        getProjectById: 'project/getProjectById'
      }),
      project() {
        if (this.task.project_id) {
          return this.getProjectById(this.task.project_id);
        }
        return null;
      },
      spentFormatted() {
        let hours = Math.floor(this.task.spent_time / 3600);
        let minutes = Math.floor((this.task.spent_time - (hours * 3600)) / 60);
        return String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0')
      }
    },
    methods: {
      show() {
        const routeData = this.$router.resolve({name: 'task', params: {taskId: this.task.id}});
        window.open(routeData.href, '_blank');
      }
    },
  }
</script>

<style>

</style>