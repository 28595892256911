<template>
  <ul class="task-index__options-list option-list">
    <li class="option__item">
      <div class="option">
        <a href="javascript:void(0)" class="option__link" @click.prevent="handlePerformerClick">
          <span class="option__link-icon"><IconParticipants/></span>
          <span class="option__link-label">Изменить исполнителя</span>
        </a>
      </div>
      <TaskOptionsPerformer
              v-if="options.isShowPerformer"
              @close="handlePerformerCrossClick"/>
    </li>
    <li class="option__item">
      <div class="option">
        <a href="javascript:void(0)" class="option__link" @click.prevent="handleWorkersClick">
          <span class="option__link-icon"><IconParticipants/></span>
          <span class="option__link-label">Добавить ответственных</span>
        </a>
      </div>
      <TaskOptionsWorkers
        v-if="options.isShowWorkers"
        @crossClicked="handleWorkersCrossClick"
        @executorAdded = "onExecutorAdded"
        @executorRemoved = "onExecutorRemoved"/>
    </li>
      <li class="option__item">
          <div class="option">
              <a href="javascript:void(0)" class="option__link" @click.prevent="handleTagsClick">
                        <span class="option__link-icon">
                            <IconTags/></span>
                  <span class="option__link-label">Изменить метки</span>
              </a>
          </div>
          <TaskOptionsTags v-if="options.isShowTags" @crossClicked="handleTagsCrossClick" @buttonClicked="handleTagsButtonClick"/>
          <TagsCreator v-if="options.isShowTagsAdd" @crossClicked="handleTagsAddCrossClick" @backClicked="handleTagsAddBackClick"/>
      </li>
    <li class="option__item">
      <div class="option">
        <a href="#" class="option__link upload-button-new">
                        <span class="option__link-icon">
                            <IconClip/></span>
          <span class="option__link-label">Добавить вложения</span>
        </a>
      </div>
    </li>
    <li class="option__item">
      <div class="option">
        <a href="#" class="option__link" @click.prevent="handleStatusesClick">
                        <span class="option__link-icon">
                            <IconMove/></span>
          <span href="#" class="option__link-label">Переместить</span>
        </a>
      </div>
      <TaskOptionsStatus v-if="options.isShowStatuses" @crossClicked="handleStatusCrossClick"/>
    </li>
    <li class="option__item">
      <div class="option">
        <a href="#" class="option__link" @click.prevent="onArchiveCrossClick">
                        <span class="option__link-icon">
                            <IconArchive/></span>
          <span class="option__link-label" v-if="!task.isArchive">Архивировать</span>
          <span class="option__link-label" v-if="task.isArchive">Разархивировать</span>
        </a>
      </div>
    </li>
  </ul>
</template>

<script>
  import IconParticipants from "../../../icons/IconParticipants.vue";
  import IconTags from "../../../icons/IconTags.vue";
  import IconClip from "../../../icons/IconClip.vue";
  import IconMove from "../../../icons/IconMove.vue";
  import IconArchive from "../../../icons/IconArchive.vue";
  import TaskOptions from "./TaskOptions.vue";
  import TaskOptionsWorkers from "./TaskOptionsWorkers.vue";
  import TaskOptionsPerformer from "./TaskOptionsPerformer.vue";
  import TaskOptionsTags from "./TaskOptionsTags.vue";
  import TaskSupport from "./TaskSupport.vue";
  import TagsCreator from "./TagsCreator.vue";
  import TaskOptionsStatus from "./TaskOptionsStatus.vue";
  import { mapGetters, mapState } from "vuex";
  import TaskEditModal from "../../../pages/TheTask.vue";



  export default {
    name: "TaskOptionsList",
    components: {
      TaskOptionsStatus,
      TagsCreator,
      TaskOptionsTags,
      TaskOptionsWorkers,
      TaskOptionsPerformer,
      TaskSupport,
      TaskOptions, IconArchive, IconMove, IconClip, IconTags, IconParticipants},
    computed: {
      ...mapGetters('task', [
        'task'
      ]),
      ...mapGetters('worker', [
        'selectedWorkersIds',
      ])
    },
    data() {
      return {
        options: {
          isShowPerformer: false,
          isShowWorkers: false,
          isShowTags: false,
          isShowTagsAdd: false,
          isShowStatuses: false,
        }
      }
    },
    methods: {
      handleWorkersClick() {
        for (let prop in this.options) {
          this.options[prop] = false;
        }
        this.options.isShowWorkers = true;
      },
      handleWorkersCrossClick() {
        this.options.isShowWorkers = false;
      },
      handlePerformerClick() {
        for (let prop in this.options) {
          this.options[prop] = false;
        }
        this.options.isShowPerformer = true;
      },
      handlePerformerCrossClick() {
        this.options.isShowPerformer = false;
      },
      handleTagsClick() {
        for (let prop in this.options) {
          this.options[prop] = false;
        }
        this.options.isShowTags = true;
      },
      handleTagsCrossClick() {
        this.options.isShowTags = false;
      },
      handleTagsButtonClick() {
        this.options.isShowTagsAdd = true;
        this.options.isShowTags = false;
      },
      handleTagsAddCrossClick() {
        this.options.isShowTagsAdd = false;
      },
      handleTagsAddBackClick() {
        this.options.isShowTagsAdd = false;
        this.options.isShowTags = true;
      },
      handleStatusesClick() {
        for (let prop in this.options) {
          this.options[prop] = false;
        }
        this.options.isShowStatuses = true;
      },
      handleStatusCrossClick() {
        this.options.isShowStatuses = false;
      },
      async onArchiveCrossClick() {
        if (confirm('Вы действительно хотите архивировать эту задачу?')) {
          const payload = {projectId: this.$route.params.projectId, workerIds: this.selectedWorkersIds};
          await this.$store.dispatch('task/updateIsArchive', this.task.id);
          this.$store.dispatch('project/fetchTasks', payload);
          this.$emit('close');
        }
      },
      async onExecutorAdded(worker) {
        await this.$store.commit('task/addExecutor', worker);
        this.$store.dispatch('task/storeExecutors', this.task.executors);
      },
      async onExecutorRemoved(worker) {
        await this.$store.commit('task/removeExecutor', worker);
        this.$store.dispatch('task/storeExecutors', this.task.executors);
      },
    }
  }
</script>

<style lang="scss">

</style>