<template>
    <div class="membership-value" :class="['membership-value__type_' + type.toString()]">
        <select name="" id="" v-model="type" @change="changed" class="membership-value__select">
            <option value="0" :key="0"></option>
            <option v-for="type in types" :value="type.value" :key="type.value">
                {{ type.name }}
            </option>
        </select>
    </div>
</template>

<script>
  export default {
    name: "MembershipValue",
    props: {
      projectId: Number,
      workerId: Number,
      types: Array,
      membership: {
        type: Object,
        required: false
      }
    },
    data() {
      let type = 0;
      if (this.membership) {
        type = this.membership.type;
      }
      return {
        type
      };
    },
    methods: {
      changed() {
        if (this.membership) {
          if (this.type) {
            this.$emit('updatedType', {
              type: this.type,
              id: this.membership.id
            })
          } else {
            this.$emit('deletedMembership', {
              id: this.membership.id
            })
          }
        } else {
          this.$emit('created', {
            type: this.type,
            projectId: this.projectId,
            workerId: this.workerId,
          })
        }
      },
      bindMembershipType() {
        if (this.membership) {
          this.type = this.membership.type;
        }
      }
    },
    mounted() {
      if (this.membership) {
        this.type = this.membership.type;
      }
    },
    watch: {
      membership() {
        this.bindMembershipType()
      }
    }
  }
</script>