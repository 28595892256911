<template>
  <div class="wiki">
    <div class="wiki__inner">
      <button class="button button_alert" v-if="editing === false && description == ''" @click="toggleEditing()">
        Добавить
      </button>
      <wysiwyg
              class="wiki__editor"
              v-model="description"
              v-if="editing"
              v-on:change="saveDescription"
              autofocus
      >
      </wysiwyg>
      <div class="wiki__text" v-html="description" v-else @dblclick="toggleEditing()"></div>
      <button class="button button_alert" v-if="editing === true" @click="toggleEditing()">
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
  import {mapActions} from "vuex";

  export default {
    name: "ProjectWiki",
    inheritAttrs: false,
    props: {
      initialDescription: String,
      projectId: Number
    },
    data() {
      return {
        description: this.initialDescription,
        editing: false
      }
    },
    methods: {
      ...mapActions({
        updateDescription: 'project/updateDescription',
      }),
      toggleEditing() {
        this.editing = !this.editing;
      },
      saveDescription() {
        this.updateDescription({
          projectId: this.projectId,
          description: this.description
        })
      },
    },
    watch: {
      initialDescription: function () {
        this.description = this.initialDescription;
      }
    }
  }
</script>

<style lang="scss">
  .wiki {
    .editr--content {
      background-color: #fff;
    }
  }
</style>