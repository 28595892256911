<template>
    <div class="pipeline-view">
        <div class="pipeline-view__layers">

            <div class="pipeline-view__layer" v-for="layer in layers" :style="{'margin-left': getLayerOffset(layer)}">
                <div class="pipeline-view__task" v-for="item in layer" :data-task-id="item.node.id">
                    <PipelineViewTask :task="item.node"/>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import PipeService from "../../../services/pipe.service";
import PipelineViewTask from "./PipelineViewTask";
import arrowCreate, { DIRECTION, HEAD } from 'arrows-svg';

export default {
  name: "PipelineView",
  components: {PipelineViewTask},
  props: {
    pipeline: Object,
    tasks: Array
  },
  data() {
    return {
      arrows: []
    };
  },
  methods: {
    getLayerOffset(layer) {
      const firstItem = layer[0];
      const step = firstItem.step;
      return (step * 270 + step * 30) + 'px';
    },
    clearArrows() {
      this.arrows.forEach((arrow) => {
        arrow.clear();
      })
    },
    renderArrows() {
      this.clearArrows();
      this.layers.forEach((layer) => {
        layer.forEach((item) => {
          item.node.next.forEach((nextId) => {
            this.renderArrow(item.node.id, nextId);
          })
        })
      });
    },
    renderArrow(from, to) {
      const fromElement = document.querySelector(`[data-task-id="${from}"]`);
      const toElement = document.querySelector(`[data-task-id="${to}"]`);

      const fromPosition = this.getLayerNumById(from);
      const toPosition = this.getLayerNumById(to);

      // Если слой from элемента меньше чем слой to элемента, то from стрелка должна быть вниз, а стрелка to элемента должна быть влево
      // Если слой from элемента больше чем слой to элемента, то from стрелка должна быть вверх, а стрелка to элемента должна быть влево
      // Если слой совпадает, но step from больше чем на 1 больше чем step to from стрелка должна быть вверх, to стрелка должна быть вверх

      let fromDirection = DIRECTION.RIGHT;
      let toDirection = DIRECTION.LEFT;
      let fromTranslation = undefined;
      let toTranslation = undefined;

      if (fromPosition.layer < toPosition.layer) {
        fromDirection = DIRECTION.BOTTOM;
        fromTranslation = [0,1];
      }
      if (fromPosition.layer > toPosition.layer) {
        toDirection = DIRECTION.BOTTOM;
        fromDirection = DIRECTION.RIGHT;
        toTranslation = [0,1];
      }

      const arrow = arrowCreate({
        from: {
          direction: fromDirection,
          node: fromElement,
          translation: fromTranslation
        },
        to: {
          direction: toDirection,
          node: toElement,
          translation: toTranslation
        },
        head: {
          func: HEAD.THIN,
          size: 5
        }
      })
      arrow.node.firstElementChild.setAttribute('data-arrow-pipeline-id', this.pipeline.id);
      this.arrows.push(arrow);
      document.body.appendChild(arrow.node);

    },
    getLayerNumById(id) {
      let layerNum = 0;
      let stepNum = 0;
      this.layers.forEach((layer, num) => {
        layer.forEach((item) => {
          if (item.node.id === id) {
            layerNum = num;
            stepNum = item.step;
          }
        })
      });
      return {
        layer: layerNum,
        step: stepNum
      };
    }
  },
  computed: {
    layers() {
      return PipeService.makeLayers(this.tasks);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.renderArrows()
    })
  },
  beforeDestroy() {
    this.clearArrows();
  },
  watch: {
    tasks: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.renderArrows()
        })
      }
    }
  }
}
</script>

<style lang="scss">
.pipeline-view-container {
  background-color: #191B1E;
  margin-bottom: 15px;
  padding: 20px;
  overflow: scroll;
}

.pipeline-view {
  &__task {
    flex: 0 0 270px;
    width: 270px;
    margin-right: 30px;
    position: relative;

    .task, .task__main {
      margin-bottom: 0;
    }

    &::after {
      display: block;
      position: absolute;
      width: 30px;
      height: 1px;
      content: '';
      right: -30px;
    }
  }

  &__layer {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
}

.arrow {
  pointer-events: none;
}

.arrow__path {
  stroke: #fff;
  fill: transparent;
  stroke-dasharray: 4 2;
}

.arrow__head line {
  stroke: #fff;
  stroke-width: 2px;
}
</style>