import ApiService from './api.service';

const TodoesService = {
  async fetch() {
    const requestData = {
      url: '/api/todoes/fetch',
      method: 'get',
    };
    return ApiService.request(requestData);
  },

  async store(todo) {
    const requestData = {
      url: '/api/todoes/store',
      method: 'post',
      data: {
        todo,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async update(todo) {
    const requestData = {
      url: '/api/todoes/update',
      method: 'post',
      data: {
        todo,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async delete(id) {
    const requestData = {
      url: `/api/todoes/delete/${id}`,
      method: 'delete',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};

export default TodoesService;