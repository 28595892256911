<template>
    <div class="option__item">
        <div class="option">
            <a href="javascript:void(0)" class="option__link" @click.prevent="toggle">
                <span class="option__link-icon">
                    <IconTimes/>
                </span>
                <span class="option__link-label">
                    Повторение задачи
                </span>
            </a>
            <div class="task-repeater" v-if="opened">
                <div class="task-repeater__header">
                    <div class="task-repeater__title">
                        Повторение задачи
                    </div>
                    <a href="javascript:void(0);" @click.prevent="close" class="task-repeater__close">
                        <IconCrossGrey/>
                    </a>
                </div>

                <div class="task-repeater__base task-repeater__row">
                    <div class="task-repeater__label">
                        Базовая дата:
                    </div>

                    <datetime
                            type="datetime"
                            placeholder="Введите дату"
                            format="d LLL HH:mm"
                            class="theme-grey"
                            input-class="datepicker"
                            v-model="repeater.base"
                            :phrases="{ cancel: 'Отменить', ok: 'Выбрать'}"
                            @input="onDateInput"
                    >
                    </datetime>
                </div>

                <div class="task-repeater__period task-repeater__row">
                    <div class="task-repeater__period-label task-repeater__label">
                        Повторять каждые
                    </div>
                    <div class="task-repeater__period-inputs">
                        <div class="task-repeater__period-value">
                            <input type="text" v-model="repeater.value" class="task-repeater__period-value-input">
                        </div>
                        <div class="task-repeater__period-period">
                            <select name="" id="" v-model="repeater.period" class="task-repeater__period-period-input">
                                <option v-for="option in periods" :value="option.value">
                                    {{ option.text }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="task-repeater__deadline task-repeater__row">
                    <div class="task-repeater__deadline-label task-repeater__label">
                        Дедлайн спустя
                    </div>
                    <div class="task-repeater__deadline-inputs">
                        <div class="task-repeater__deadline-deadline">
                            <input type="text" v-model="repeater.deadline_days" class="task-repeater__deadline-input">
                        </div>
                        <div class="task-repeater__deadline-postfix">
                            дней
                        </div>
                    </div>
                </div>
                <div class="task-repeater__actions">
                    <button class="button button_alert task-repeater__button" @click.prevent="save">
                        Сохранить
                    </button>
                    <button class="button task-repeater__button" v-if="task.repeater_id" @click.prevent="remove">
                        Удалить
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import IconTimes from '../../../icons/IconTimes.vue';
  import IconCrossGrey from '../../../icons/IconCrossGrey.vue';
  import { mapState, mapActions } from 'vuex';

  export default {
    name: "TaskRepeater",
    computed: {
      ...mapState('task', [
        'task'
      ]),
    },
    data() {
      return {
        opened: false,
        periods: [
          {
            value: 10,
            text: 'дней',
          },
          {
            value: 20,
            text: 'недель',
          },
          {
            value: 30,
            text: 'месяцев',
          }
        ],
        repeater: {
          base: this.$moment().format(),
          period: 10,
          value: 1,
          deadline_days: null,
        }
      }
    },
    components: {
      IconTimes,
      IconCrossGrey,
    },
    methods: {
      ...mapActions('task', [
        'createRepeater',
        'deleteRepeater',
        'refreshTask',
      ]),
      change() {

      },
      onDateInput() {

      },
      toggle() {
        this.opened = !this.opened;
      },
      open() {
        this.opened = true;
      },
      close() {
        this.opened = false;
      },
      async save() {
        await this.createRepeater(this.repeater);
        this.refreshTask(this.task.id);
      },
      async remove() {
        await this.deleteRepeater();
        this.refreshTask();
      },
      fetch() {
        if (this.task.repeater_id) {
          this.repeater.base = this.$moment(this.task.repeater.base).format();
          this.repeater.period  = this.task.repeater.period;
          this.repeater.value  = this.task.repeater.value;
          this.repeater.deadline_days  = this.task.repeater.deadline_days;
        }
      }
    },
    mounted() {
      this.fetch();
    },
    beforeUpdate() {
      this.fetch();
    }
  }
</script>

<style lang="scss">

</style>