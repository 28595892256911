<template>
    <ProjectContainer v-slot="{project}">
        <ProjectLayout :project="project">
            <div slot="kanban" v-if="project.id">
                <TheProjectChecklistList :id="project.id"/>
            </div>
        </ProjectLayout>
    </ProjectContainer>
</template>

<script>
import ProjectContainer from "../containers/ProjectContainer.vue";
import ProjectLayout from "../layouts/ProjectLayout.vue";
import TheProjectChecklistList from "../components/app/checklist/project/TheProjectChecklistList";

export default {
  name: "TheProjectPipelines",
  components: {
    TheProjectChecklistList,
    ProjectContainer,
    ProjectLayout
  },
}
</script>

<style>

</style>