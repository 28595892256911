<template>
  <svg width="11px" height="9px" viewBox="0 0 11 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
    <title>Line</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="123" transform="translate(-417.000000, -141.000000)" fill-rule="nonzero" stroke="#FFFFFF" stroke-width="2">
        <g id="Group-21-Copy-2" transform="translate(345.000000, 34.000000)">
          <polyline id="Line" points="73 111 76.5201986 114 82 108"></polyline>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IconCheckWhite"
  }
</script>

<style>

</style>