<template>
    <router-link class="timeline-worker__task timeline-task" :to="{name: 'task', params: {taskId: task.task.id}}" :style="style" :data-start-time="task.start_time" :data-end-time="task.end_time" target="_blank">
        <span class="timeline-task__verbose">
            <span class="timeline-task__project" v-if="project">
                {{ project.name }}
            </span>
            <span class="timeline-task__name">{{ task.task.name }}</span>
            <span class="timeline-task__owner" v-if="owner">
                Постановщик: {{ owner.name }}
            </span>
        </span>
    </router-link>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: "TimelineWorkerDayTask",
    props: {
      task: Object,
      offset: Number,
      width: Number
    },
    computed: {
      ...mapGetters({
        getProjectById: 'project/getProjectById',
        getWorkerById: 'worker/getWorkerById',
      }),
      project() {
        return this.getProjectById(this.task.task.project_id);
      },
      owner() {
        return this.getWorkerById(this.task.task.owner_id);
      },
      style() {
        return {
          'width': `calc(var(--timeline-hour) * ${this.width.toString()})`,
          'left': `calc(var(--timeline-hour) * ${this.offset.toString()})`,
        }
      }
    }
  }
</script>

<style>

</style>