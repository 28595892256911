<template>
    <AuthLayout>
        <div class="login">
            <div class="row">
                <div class="column">
                    <div class="login__container">
                        <h1 class="login__title">Вход</h1>
                        <form class="login__form" @submit.prevent="handleSubmit">
                            <div class="login__data">
                                <input type="text" placeholder="Логин" v-model="email">
                                <input type="password" placeholder="Пароль" v-model="password">
                                <p class="login__error">
                                    {{message}}
                                </p>
                            </div>
                            <div class="login__buttons">
                                <Button :primary="true" @click="handleSubmit">
                                    Войти
                                </Button>
                                <!--<button type="submit" class="login__button button round default">-->
                                    <!--Вoйти-->
                                <!--</button>-->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </AuthLayout>
</template>

<script>
  import AppLayout from "../../layouts/AppLayout.vue";
  import AuthLayout from "../../layouts/AuthLayout.vue";
  import {mapState, mapActions} from 'vuex';
  import Button from "../ui/Button.vue";

  export default {
    name: "AuthLogin",
    components: {Button, AuthLayout, AppLayout},
    data() {
      return {
        email: '',
        password: ''
      }
    },
    computed: {
      ...mapState('auth', {
          message: 'authenticationMessage'
      })
    },
    methods: {
        ...mapActions('auth', [
          'login'
        ]),
      handleSubmit() {
        if (this.email !== '' && this.password !== '') {
          this.login({email: this.email, password: this.password});
          this.password = "";
        }
      }
    }
  }
</script>

<style>

</style>