import ApiService from './api.service';

const MyChecklistService = {
  async all() {
    const requestData = {
      url: '/api/checklist/my',
      method: 'get',
    };
    return ApiService.request(requestData);
  },
};

export default MyChecklistService;