<template>
    <li class="files-list__item">
        <div class="files-list__wrapper-label">
            <a class="files-list__link-download" :href="path"  target="_blank">
                <span class="files-list__icon">
                    <IconSketch v-if="isSketch"/>
                    <IconWord v-if="isDoc"/>
                    <IconZip v-if="isZip"/>
                    <IconFile v-if="isFile"/>
                </span>
            </a>
            <a class="files-list__link-name" :href="path" target="_blank">
                <span class="files-list__name-file">{{file.name}}</span>
                <span class="files-list__name-size">{{fileSize}} МБ</span>
            </a>
            <a href="javascript:void(0)" class="files-list__delete" @click.prevent="handleClick">
                <IconCrossSmall/>
            </a>
        </div>
    </li>
</template>

<script>
  import IconSketch from "../../../icons/IconSketch.vue";
  import IconWord from "../../../icons/IconWord.vue";
  import IconZip from "../../../icons/IconZip.vue";
  import IconCrossSmall from "../../../icons/IconCrossSmall.vue";
  import {mapState} from "vuex";
  import IconFile from "../../../icons/IconFile.vue";

  export default {
    name: "TaskDocsItem",
    components: {IconFile, IconCrossSmall, IconZip, IconWord, IconSketch},
    props: {
      file: Object
    },
    computed: {
      path() {
        return `/media/${this.file.file.path}`
      },
      fileSize() {
        return Math.ceil(this.file.size / 1000000);
      },
      isSketch() {
        if (this.file.name.match(/\.sketch$/) !== null) {
          return true;
        }
        return false;
      },
      isPsd() {
        if (this.file.name.match(/\.psd$/) !== null) {
          return true;
        }
        return false;
      },
      isDoc() {
        if (this.file.name.match(/\.(doc|docx)$/) !== null) {
          return true;
        }
        return false;
      },
      isZip() {
        if (this.file.name.match(/\.zip$/) !== null) {
          return true;
        }
        return false;
      },
      isFile() {
        if (this.file.name.match(/\.(zip|doc|docx|psd|sketch)$/) === null) {
          return true;
        }
        return false;
      },
      ...mapState('task', [
        'task'
      ])
    },
    methods: {
      handleClick() {
        if (confirm("Вы действительно хотите удалить файл " + this.file.name)) {
            const payload = {projectId: this.task.id, fileId: this.file.id};
            this.$store.dispatch('task/deleteFile', payload);
        }
      }
    }
  }
</script>

<style>

</style>