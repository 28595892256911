<template>
  <div class="task-index__do-comments">
    <div class="task-index__do-comments-avatar">
      <TheAvatar
        :worker="userWorker"
      />
    </div>
    <textarea
      v-autosize="commentText"
      class="task-index__do-comments-input"
      placeholder="Оставить комментарий..."
      v-model="commentText"
    >
    </textarea>
    <a href="javascript:void(0)"
       class="task-index__do-comments-icon"
       @click.prevent="handleClick"
    >
      <IconSend/>
    </a>
  </div>
</template>

<script>
  import IconSend from "../../../icons/IconSend.vue";
  import {mapState} from "vuex";
  import TheAvatar from "../../ui/TheAvatar.vue";

  export default {
    name: "TaskCommentsAdd",
    components: {TheAvatar, IconSend},
    computed: {
      ...mapState('task', [
        'task',
      ]),
      ...mapState('worker', [
        'userWorker'
      ])
    },
    data() {
      return {
        commentText: ''
      }
    },
    methods: {
      handleClick() {
        if (this.commentText === '') {
          return false;
        }
        const payload = {taskId: this.task.id, workerId: this.userWorker.id, comment: this.commentText};
        this.$store.dispatch('task/storeComment', payload);
        this.commentText = '';
      }
    }
  }
</script>

<style lang="scss">
  .task-index{
    &__do-comments-input {
      padding: 10px 20px 15px 14px;
      line-height: 1.6em;
      font-size: 15px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

    }
  }
</style>