import moment from 'moment';
import LogService from '../../services/log.service';
import LocalService from '../../services/local.service.js';

moment.locale('ru');

const logTaskModule = {
  namespaced: true,
  state: {
    log: [],
    taskId: null,
    page: 1,
    total: 0,
    loading: false,
    texts: {
      10: ['изменил(а) статус задачи', 'установил(а) статус задачи'],
      30: ['изменил(а) исполнителя по задаче', 'назначил(а) исполнителя по задаче'],
      40: ['прокомментировал(а) задачу'],
      50: ['прикрепил(а) вложение к задаче'],
      60: ['изменил(а) проект по задаче', 'назначил(а) проект по задаче'],
    },
    names: {
      10: 'Статусы',
      30: 'Исполнители',
      40: 'Комментарии',
      50: 'Файлы',
      60: 'Проекты',
    },
    filterTypes: LocalService.getObject('LOG_TASK_FILTER_STATUS', [10, 30, 40, 50, 60]),
  },
  getters: {
    byDates(state) {
      const dates = [];
      state.log.forEach((logItem) => {
        const momentDate = moment(logItem.created_at).calendar(null, {
          sameDay: '[Сегодня]',
          lastDay: '[Вчера]',
          lastWeek: 'D MMMM',
          nextDay: 'D MMMM',
          nextWeek: 'D MMMM',
          sameElse: 'D MMMM',
        });
        let foundKey = dates.findIndex(date => date.name === momentDate);
        if (foundKey === -1) {
          foundKey = dates.push({
            name: momentDate,
            events: [],
          }) - 1;
        }
        logItem.time = moment(logItem.created_at).format('HH:mm');
        dates[foundKey].events.push(logItem);
      });
      return dates;
    },
    text(state) {
      return (type, variant) => {
        const v = variant || 0;
        return state.texts[type][v];
      };
    },
    hasNext(state) {
      return state.total > state.log.length;
    },
  },
  actions: {
    async fetchLoading({ commit, dispatch }, taskId) {
      commit('setTaskId', taskId);
      commit('clearPage');
      commit('clearLog');
      await dispatch('fetch');
    },

    async fetch({ commit, state }) {
      commit('setLoading', true);
      try {
        const response = await LogService.getTaskLog(state.page, state.taskId, state.filterTypes);
        commit('appendLog', response.data.data);
      } catch (e) {
        console.log(e);
      }
      commit('setLoading', false);
    },

    async more({ commit, dispatch }) {
      commit('nextPage');
      await dispatch('fetch');
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setFilter(state, filter) {
      state.filterTypes = filter;
      LocalService.setObject('LOG_TASK_FILTER_STATUS', filter);
    },
    clearLog(state) {
      state.log = [];
    },
    appendLog(state, data) {
      if (data.data) {
        state.log.push(...data.data);
      }
      if (data.total) {
        state.total = data.total;
      }
    },
    setTaskId(state, taskId) {
      state.taskId = taskId;
    },
    clearPage(state) {
      state.page = 1;
    },
    nextPage(state) {
      state.page += 1;
    },
  },
};

export default logTaskModule;