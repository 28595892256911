<template>
	<ul class="avatar-list" v-if="workers">
		<li class="avatar-list__item" v-for="worker in workers" :key="worker.id">
			<TheAvatar :worker="worker"
								 :small="small"
			/>
		</li>
	</ul>
</template>

<script>
  import TheAvatar from "./TheAvatar.vue";


  export default {
    name: "TheAvatarList",
    components: {TheAvatar},
    props: {
      workers: Array,
      small: Boolean,
    },
	}
</script>

<style lang="scss">
	.avatar-list {
		display: flex;
		align-items: center;

		&__item {
			margin-right: 6px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
</style>