<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 2.19375L11.0852 6.71447L11.2024 6.96862L11.4804 7.00157L16.4242 7.58774L12.7691 10.9679L12.5636 11.1579L12.6182 11.4324L13.5884 16.3154L9.24422 13.8837L9 13.747L8.75578 13.8837L4.4116 16.3154L5.38185 11.4324L5.43639 11.1579L5.23091 10.9679L1.57582 7.58774L6.51964 7.00157L6.79757 6.96862L6.9148 6.71447L9 2.19375Z" fill="" stroke="#ffffff87"/>
  </svg>
</template>

<script>
  export default {
    name: "star"
  }
</script>

<style scoped>

</style>