<template>
    <router-link class="time-worker__task" :to="{name: 'task', params: {taskId: task.task_id}}" target="_blank">
        <span class="time-worker__task-name">
            {{ task.name }}
        </span>
        <span class="time-worker__task-spent">
            {{ spentTime }}
        </span>
    </router-link>
</template>

<script>
  import SpentService from '../../../services/spent.service';

  export default {
    name: "TimeWorkerProjectTask",
    props: {
      task: Object
    },
    computed: {
      spentTime() {
        return SpentService.spent(this.task.spent);
      }
    }
  }
</script>

<style>

</style>