<template>
  <li class="comments-list__item">
    <div class="task-index__comments-user">
                                <span class="task-index__comments-avatar">
                                    <TheAvatar
                                      :worker="comment.worker"
                                    />
                                </span>
      <div class="task-index__comments-head">
        <div class="task-index__comments-user">
          <span class="task-index__comments-worker">{{comment.worker.name}}</span>
          <span class="task-index__comments-date" v-if="isEditable">Редактирование</span>
          <span class="task-index__comments-date" v-else>{{dateTime}}</span>
        </div>
        <div class="task-index__comments-do" v-if="isCurrentWorker">
          <a href="javascript:void(0)" class="task-index__comments-icon" @click="onPenClick">
            <IconPen/>
          </a>
          <a href="javascript:void(0)" class="task-index__comments-delete" @click.prevent="handleClick">
            <IconCrossSmall/>
          </a>
        </div>
        <span class="task-index__comments-subhead">
          <span class="task-index__edit-comment" v-if="isEditable">
            <textarea-autosize type="text"
                               class="task-index__do-comments-input task-index__do-comments-input_edit"
                               v-model="commentText"
                               :min-height="10"

            ></textarea-autosize>
          <span class="task-index__comments-edit-buttons">
            <span class="task-index__comments-edit-button">
              <Button :primary="true" @click="onSaveClick">Сохранить</Button>
            </span>
            <span class="task-index__comments-edit-button">
               <Button @click="onCancelClick">Отменить</Button>
            </span>
          </span>
          </span>
          <p class="task-index__comments-text" v-else v-html="commentHtml">{{comment.text}}</p>
        </span>
        <div class="task-index__comments-answer" v-if="!isEditable">
          <a href="#" class="task-index__answer">Ответить</a>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
  import IconPen from "../../../icons/IconPen.vue";
  import IconCrossSmall from "../../../icons/IconCrossSmall.vue";
  import TheAvatar from "../../ui/TheAvatar.vue";
  import {mapState} from "vuex";
  import Button from "../../ui/Button.vue";
  import TextService from "../../../services/text.service.js";

  export default {
    name: "TaskCommentsItem",
    components: {Button, TheAvatar, IconCrossSmall, IconPen},
    props: {
      comment: Object
    },
    computed: {
      dateTime() {
        return this.$moment(this.comment.created_at).calendar(null, {
          sameDay: '[Сегодня] HH:mm',
          lastDay: '[Вчера] HH:mm',
          sameElse: 'DD.MM.YYYY'
        });
      },
      ...mapState('worker', [
        'userWorker'
      ]),
      ...mapState('task', [
        'task'
      ]),
      commentHtml() {
        return TextService.prepareText(this.comment.text);
      }
    },
    data() {
      return {
        isCurrentWorker: false,
        isEditable: false,
        commentText: '',
      }
    },
    methods: {
      handleClick() {
        const payload = {commentId: this.comment.id, taskId: this.task.id};
        this.$store.dispatch('task/deleteComment', payload);
      },
      onPenClick() {
        this.isEditable = true;
      },
      onCancelClick() {
        this.isEditable = false;
      },
      async onSaveClick() {
        const payload = {taskId: this.task.id, commentId: this.comment.id, comment: this.commentText};
        await this.$store.dispatch('task/updateComment', payload);
        this.isEditable = false;
      }
    },
    mounted() {
      if (this.userWorker.id === this.comment.worker.id) {
        this.isCurrentWorker = true;
      }
      this.commentText = this.comment.text;
    }
  }
</script>

<style lang="scss">
  .task-index {
    &__comments-edit-buttons {
      display: flex;
      margin-bottom: 30px;
    }
  }
</style>