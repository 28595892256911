import ApiService from './api.service';

const FavoritesService = {
  async add(id) {
    const requestData = {
      url: '/api/favorites/add/',
      method: 'post',
      data: {
        id,
      },
    };
    return ApiService.request(requestData);
  },
  async remove(id) {
    const requestData = {
      url: '/api/favorites/remove/',
      method: 'post',
      data: {
        id,
      },
    };
    return ApiService.request(requestData);
  },
  async fetchData(userId) {
    const requestData = {
      url: '/api/favorites/fetch/',
      method: 'get',
      params: {
        userId,
      },
    };
    return ApiService.request(requestData);
  },
};

export default FavoritesService;