import Vue from 'vue';
import WorkerService from '../../services/worker.service';

const workerModule = {
  namespaced: true,
  state: {
    workers: [],
    selectedWorkers: [],
    userWorker: {},
  },
  getters: {
    selectedWorkersIds(state) {
      const workerIds = [];
      state.selectedWorkers.forEach((worker) => {
        workerIds.push(worker.id);
      });
      return workerIds;
    },
    getWorkerById(state) {
      return id => state.workers.find(worker => worker.id === id);
    },
    activeWorkers(state) {
      return state.workers.filter(worker => !worker.hidden);
    },
  },
  actions: {
    async fetchWorkers({ commit }) {
      try {
        const response = await WorkerService.getAllWorkers();
        commit('setWorkers', response.data.data);
      } catch (e) {
        console.log(e);
      }
    },
    async fetchWorkersIfNotLoaded({ state, dispatch }) {
      if (state.workers.length === 0) {
        dispatch('fetchWorkers');
      }
    },
    async fetchWorker({ commit }, userId) {
      try {
        const response = await WorkerService.getUserWorker(userId);
        commit('setUserWorker', response.data.data[0]);
      } catch (e) {
        console.log(e);
      }
    },
    async addSelectedWorker({ commit }, worker) {
      commit('addSelectedWorkers', worker);
    },
    async removeSelectedWorker({ commit }, worker) {
      commit('removeSelectedWorkers', worker);
    },
    async storeColor({ dispatch, rootState }, { workerId, color }) {
      try {
        await WorkerService.storeColor({ workerId, color });
        dispatch('fetchWorker', rootState.user.user.id);
        dispatch('fetchWorkers');
      } catch (e) {
        console.error(e);
      }
    },
  },
  mutations: {
    setWorkers(state, workers) {
      state.workers = workers;
      state.workers.forEach((worker) => {
        Vue.set(worker, 'isSelected', false);
      });
    },
    setUserWorker(state, worker) {
      state.userWorker = worker;
    },
    addSelectedWorkers(state, worker) {
      state.selectedWorkers.push(worker);
    },
    removeSelectedWorkers(state, worker) {
      state.selectedWorkers = state.selectedWorkers.filter(selectedWorker => selectedWorker.id !== worker.id);
    },
  },
};

export default workerModule;