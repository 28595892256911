<template>
	<ul class="task-index__statuses-list statuses-list">
		<TaskTagsItem v-for="tag in tags"
									:key="tag.id"
									:tag="tag"
		/>
	</ul>
</template>

<script>
  import TaskTagsItem from "./TaskTagsItem.vue";


  export default {
    name: "TaskTagsList",
    components: {TaskTagsItem},
    props: {
      tags: Array,
    }
  }
</script>

<style lang="scss">

</style>