<template>
  <ProjectContainer v-slot="{project}">
    <ProjectLayout :project="project">

      <div slot="kanban">
        <div class="manager">
          <div class="manager__half">
             <TheLog
               v-if="project"
               :project-id="project.id"/>
          </div>
          <div class="manager__half">

          </div>
        </div>
      </div>

    </ProjectLayout>
  </ProjectContainer>
</template>

<script>
  import ProjectContainer from "../containers/ProjectContainer.vue";
  import ProjectLayout from "../layouts/ProjectLayout.vue";
  import TheLog from "../components/app/manager/TheLog.vue";

  export default {
    name: "TheProjectWiki",
    components: {
      TheLog,
      ProjectContainer,
      ProjectLayout,
    },
  }
</script>

<style scoped>

</style>