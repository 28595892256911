<template>
  <ProjectContainer v-slot="{project}">
    <ProjectLayout :project="project">
      <div slot="kanban">
        <TheForm :project="project" :id="parseInt($route.params.pipelineId, 10)"/>
      </div>
    </ProjectLayout>
  </ProjectContainer>
</template>

<script>
import ProjectContainer from "../containers/ProjectContainer.vue";
import ProjectLayout from "../layouts/ProjectLayout.vue";
import TheForm from "../components/app/pipeline/TheForm";
  export default {
    name: "TheProjectPipelineForm",
    components: {
      TheForm,
      ProjectContainer,
      ProjectLayout
    }
  }
</script>

<style lang="scss">

</style>