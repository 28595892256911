<template>
    <div class="pipelines-list project-subpage">
        <Loading v-if="loading"/>
        <div class="pipelines-list__content" v-else>
            <div class="pipelines-list__item" v-for="pipeline in pipelines">
                <div class="pipelines-list__pipeline">

                    <div class="pipeline-view-container">
                        <router-link :to="{name: 'project_pipeline_update', params: {projectId: $route.params.projectId, pipelineId: pipeline.id}}" class="pipelines-list__pipeline-name">
                            {{ pipeline.name }} ✎
                        </router-link>

                        <PipelineView :pipeline="pipeline" :tasks="pipeline.tasks"/>
                    </div>
                </div>
            </div>

            <router-link :to="{name: 'project_pipeline_create', params: {projectId: $route.params.projectId}}" class="button button_alert">
                Добавить пайплайн
            </router-link>
        </div>
    </div>
</template>

<script>
import PipelineService from "../../../services/pipeline.service";
import Loading from "../../ui/Loading";
import PipelineView from "./PipelineView";

export default {
  name: "TheList",
  components: { PipelineView, Loading },
  props: {
    id: Number
  },
  data() {
    return {
      loading: false,
      pipelines: []
    }
  },
  methods: {
    async fetch() {
      this.loading = true;
      try {
        const response = await PipelineService.all(this.id);
        this.pipelines = response.data.data;
        console.log(this.pipelines);
      } catch (e) {
        console.log(e);
        this.$notify({
          group: 'foo',
          title: 'Не удалось получить пайплайны'
        })
      }
      this.loading = false;
    },
  },
  mounted() {
    console.log(this.id);
    this.fetch();
  }
}
</script>

<style lang="scss">
.pipelines-list {
  &__item {

  }

  &__pipeline {
    position: relative;
  }

  &__pipeline-name {
    color: #fff;
    font-size: 16px;
    margin-bottom: 20px;
    display: block;
  }

  .pipeline-view {
    position: relative;
  }
}
</style>