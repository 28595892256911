<template>
    <div class="project-head__search">
        <BaseSearch
            placeholder="Найти задачу..."
            :value="searchQuery"
            @input="handleInput"
            transparent
        />
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import BaseSearch from '../../ui/multiselect/BaseSearch.vue';

  export default {
    name: "ProjectSearchTask",
    components: {BaseSearch},
    computed: {
      ...mapState('filter', [
        'searchQuery'
      ]),
      ...mapGetters('filter', [
        'filters'
      ])
    },
    methods: {
      handleInput(value) {
        console.log(value);
        this.$store.dispatch('filter/setSearchQuery', value);
        this.$store.dispatch('project/fetchTasks', {id: this.$route.params.projectId, filters: this.filters});
      },
    }
  }
</script>

<style>

</style>