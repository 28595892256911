import ApiService from './api.service';

const MembershipService = {
  async all() {
    const requestData = {
      url: '/api/membership',
      method: 'get',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async create(type, workerId, projectId) {
    const requestData = {
      url: '/api/membership',
      method: 'post',
      data: {
        type,
        worker_id: workerId,
        project_id: projectId,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async updateType(id, type) {
    const requestData = {
      url: '/api/membership/change_type',
      method: 'put',
      data: {
        id,
        type,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async deleteMembership(id) {
    const requestData = {
      url: '/api/membership',
      method: 'delete',
      data: {
        id,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};

export default MembershipService;