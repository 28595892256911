<template>
    <div class="checklist-form project-subpage" v-if="!loading">
        <div class="project-subpage__container">
            <div class="checklist-form__head">
                <router-link :to="{name: 'checklists'}" class="checklist-form__back">
                    &larr;
                </router-link>
                <div class="checklist-form__head-info">
                    <h1 class="checklist-form__title">
                        <span v-if="id">
                            Редактирование плана
                        </span>
                        <span v-else>
                            Создание плана
                        </span>
                    </h1>
                </div>
            </div>

            <div class="checklist-form__name">
                <input type="text" v-model="checklist.name" class="checklist-form__name-input" placeholder="Наименование">
            </div>

            <ul class="checklist-form__items">
              <TheChecklistItem
                  v-for="(item, key) in items"
                  :data-key="key"
                  :key="key"
                  :item="item"
                  @delete="removeItem(key)"
                  @addNext="addAfter(key)"
              />
            </ul>

            <div class="checklist-form__actions" v-if="!goForm">
                <button type="submit" class="checklist-form__submit" @click="save">
                    Сохранить
                </button>
                <button type="submit" class="checklist-form__go" @click="openGo">
                    Запустить в работу на проекте 🚀
                </button>
                <a href="javascript: void(0);" @click="remove" v-if="id">
                    Удалить
                </a>
            </div>

            <div class="checklist-form__go-form" v-else>

                <div class="checklist-form__go-line">
                    <label for="worker_id" class="checklist-form__go-line-label">
                        Сотрудник, к которому применится план
                    </label>
                    <select v-model="go.worker_id" id="worker_id">
                        <option v-for="worker in activeWorkers" :value="worker.id">{{ worker.name }}</option>
                    </select>
                </div>

                <div class="checklist-form__go-line">
                    <label for="project_id" class="checklist-form__go-line-label">
                        Проект, в который применится план
                    </label>
                    <select v-model="go.project_id" id="project_id">
                        <option v-for="project in projects" :value="project.id">{{ project.name }}</option>
                    </select>
                </div>

                <div class="checklist-form__go-submit checklist-form__actions">
                    <button type="submit" class="checklist-form__go-submit-button" @click="processGo">
                        Запустить в работу на проекте 🚀
                    </button>
                </div>

            </div>
        </div>
    </div>
    <Loading v-else/>
</template>

<script>
import Loading from "../../../ui/Loading";
import TheChecklistItem from "../item/ChecklistItem";
import ChecklistTemplateService from "../../../../services/checklisttemplate.service";
import PipelineService from "../../../../services/pipeline.service";
import {mapGetters} from "vuex";
import ProjectChecklistService from "../../../../services/projectchecklist.service";

export default {
  name: "TheChecklistTemplateForm",
  components: {
    TheChecklistItem,
    Loading
  },
  props: {
    id: {
      required: false,
      type: Number
    },
  },
  data() {
    return {
      loading: true,
      goForm: false,
      checklist: {
        id: 0,
        name: ''
      },
      go: {
        name: '',
        worker_id: 1,
        project_id: 1
      },
      items: [],
    }
  },
  computed: {
    ...mapGetters('worker', [
      'activeWorkers'
    ]),
    ...mapGetters('project', [
      'projects'
    ]),
  },
  methods: {
    async save() {
      try {
        if (this.id) {
          const response = await ChecklistTemplateService.update(this.id, {
            items: this.items,
            checklist: this.checklist
          });
        } else {
          const response = await ChecklistTemplateService.create({
            items: this.items,
            checklist: this.checklist
          });
        }
        this.$router.push({name: 'checklists'});
      } catch (e) {
        console.log(e);
        this.$notify({
          group: 'foo',
          title: 'Ошибка сохранения чеклиста'
        })
      }
    },

    async remove() {
      if (confirm('Ты чиво собрался наделать? Ты хочешь план удалить?')) {
        try {
          if (this.id) {
            const response = await ChecklistTemplateService.delete(this.id);
            this.$router.push({name: 'checklists'});
          }
        } catch (e) {
          console.log(e);
          this.$notify({
            group: 'foo',
            title: 'Ошибка сохранения чеклиста'
          })
        }
      }
    },
    async processGo() {
      try {
        const response = await ProjectChecklistService.create(this.go.project_id, {
          items: this.items,
          checklist: this.go
        });
        console.log(response.data.data);
        this.$router.push({name: 'project_checklist_update', params: { projectId: this.go.project_id, id: response.data.data }});
      } catch (e) {
        this.$notify({
          group: 'foo',
          title: 'Ошибка применения чеклиста'
        })
      }
    },
    openGo() {
      this.go.name = this.checklist.name;
      this.goForm = true;
    },
    removeItem(index) {
      this.items.splice(index, 1);
      if (index > 0) {
        this.$nextTick(() => {
          console.log(index - 1);
          document.querySelector(`.checklist-item[data-key="${index - 1}"] textarea`).focus()
        });
      }
    },

    getDefaultItem() {
      return {
        text: '',
        status: 0
      }
    },
    addAfter(index) {
      const nextItem = this.getDefaultItem();
      this.items.splice(index + 1, 0, nextItem);
      this.$nextTick(() => {
        document.querySelector(`.checklist-item[data-key="${index + 1}"] textarea`).focus()
      });
    },
    async init() {
      this.loading = true;
      if (this.id) {
        try {
          const response = await ChecklistTemplateService.get(this.id);
          this.checklist = response.data.data.checklist;
          this.items = response.data.data.items;
          if (this.items.length === 0) {
            this.items.push(this.getDefaultItem());
          }
        } catch (e) {
          console.log(e);
          this.$notify({
            group: 'foo',
            title: 'Ошибка получения чеклиста'
          })
        }
      } else {
        this.items.push(this.getDefaultItem());
      }
      this.loading = false;
    }
  },
  mounted() {
    this.init();
  },
}
</script>

<style lang="scss">
.checklist-form {
  &__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    margin-bottom: 30px;
  }

  &__top {
    margin-bottom: 34px;
  }

  &__back {
    padding: 10px;
    margin: -10px;
    margin-right: 10px;
  }

  &__name {
    input {
      background-color: rgba(25, 27, 30, .6);
      padding: 9px 19px;
      color: white;
      border: none;
      font-size: 16px;
      margin-bottom: 30px;
      min-width: 570px;
    }
  }

  &__go, &__submit, &__go-submit-button {
    border-radius: 30px;
    color: white;

    background-color: #FF234C;
    border-color: #FF234C;
    font-size: 15px;
    height: 40px;
    padding: 0 20px;
  }

  &__go {
    background-color: #2340ff;
    border-color: #2340ff;
  }

  //need to be redone
  &__go {
    .checklist-form {
      &__button-text{
        padding: 5px 19px 8px;
      }
    }
  }

  &__button-text{
    display: flex;
    padding: 9px 19px 11px;
    align-items: center;
  }

  &__go-form {
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    width: 570px;
  }

  &__go-line {
    margin-bottom: 25px;

    select {
      width: 400px;
      height: 30px;
      font-size: 14px;
    }
  }

  &__go-line-label {
    display: block;
    margin-bottom: 10px;
  }

  &__head-worker {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: rgba(255, 255, 255, 0.41);
  }

  &__actions {
    position: relative;

    a {
      position: absolute;
      right: 0;
    }
  }
}
</style>