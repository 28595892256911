import TagService from '../../services/tag.service';

const tagModule = {
  namespaced: true,
  state: {
    tags: [],
    colors: [
      '#377DFF',
      '#FF742E',
      '#0EBD6A',
      '#FF234C',
      '#C923FF',
      '#00D0BE',
    ],
    tag: {
      tagName: '',
      selectedColor: '#377DFF',
    },
    searchQuery: '',
  },
  getters: {
    filteredTags(state) {
      const query = state.searchQuery.toLowerCase();
      return state.tags.filter(tag => tag.name.toLowerCase().includes(query));
    },
  },
  actions: {
    async fetchTags({ commit }) {
      try {
        const response = await TagService.getAll();
        commit('setTags', response.data.data);
      } catch (e) {
        console.log(e);
      }
    },
    async storeTag({ dispatch }, tag) {
      await TagService.store(tag);
      dispatch('fetchTags');
    },
    selectColor({ commit }, color) {
      commit('setColor', color);
    },
  },
  mutations: {
    setTags(state, tags) {
      state.tags = tags;
    },
    updateName(state, value) {
      state.tag.tagName = value;
    },
    clearName(state) {
      state.tag.tagName = '';
    },
    setColor(state, color) {
      state.tag.selectedColor = color;
    },
    clearColor(state) {
      state.tag.selectedColor = '#377DFF';
    },
  },
};

export default tagModule;