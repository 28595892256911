import UserService from '../../services/user.service';
import router from '../../router/index';

const settings = UserService.getSettings();

const userModule = {
  namespaced: true,
  state: {
    user: {},
    settings,
  },
  getters: {

  },
  actions: {
    async getUser({ commit }) {
      try {
        const response = await UserService.getUser();
        commit('setUser', response.data.data);
        // router.push({ name: 'projects' });
        // router.push('/projects/1');
      } catch (e) {
        router.push({ name: 'login' });
      }
    },
    setSettingShowAllColumns({ commit }, value) {
      commit('setShowAllColumns', value);
      commit('saveSettings');
    },
    saveSettings({ state }) {
      UserService.storeSettings(state.settings);
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setShowAllColumns(state, value) {
      state.settings.showAllColumns = value;
    },
  },
};

export default userModule;