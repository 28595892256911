import TodoesService from '../../services/mytodoes.service';

const todoesModule = {
  namespaced: true,
  state: {
    todoes: [],
  },
  actions: {
    async fetch({ commit }) {
      try {
        await TodoesService.fetch().then((r) => {
          if (r.data.status === 'success') {
            commit('setTodoes', r.data.data);
          }
        });
      } catch (e) {
        console.log('Ошибка получения todoes');
      }
    },
    async store({ commit }, payload) {
      try {
        await TodoesService.store(payload)
          .then((r) => {
            if (r.data.status === 'success') {
              commit('addTodo', r.data.data[0]);
            }
          });
      } catch (e) {
        console.log('Ошибка добавления todo, payload:', payload.todo);
      }
    },
    async update({ commit }, todo) {
      try {
        await TodoesService.update(todo)
          .then((r) => {
            if (r.data.status === 'success') {
              commit('updateTodo', r.data.data[0]);
            }
          });
      } catch (e) {
        console.log('Ошибка обновления todo, payload:', todo);
      }
    },
    async delete({ commit }, id) {
      try {
        await TodoesService.delete(id);
        commit('deleteTodo', id);
      } catch (e) {
        console.log(e);
      }
    },
  },
  mutations: {
    setTodoes(state, data) {
      state.todoes = data;
    },
    addTodo(state, data) {
      state.todoes.push(data);
    },
    updateTodo(state, data) {
      const todo = state.todoes.find(item => item.id === data.id);
      if (todo) {
        todo.title = data.title;
      }
    },
    deleteTodo(state, id) {
      const todo = state.todoes.find(item => item.id === id);
      if (todo) {
        state.todoes.splice(state.todoes.indexOf(todo), 1);
      }
    },
  },
};

export default todoesModule;