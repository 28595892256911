import Vue from 'vue';
import VueRouter from 'vue-router';
import AuthLogin from '../components/auth/AuthLogin.vue';
import TokenService from '../services/token.service';
import TaskModal from '../pages/TheTask.vue';
import TheTasks from '../pages/TheTasks.vue';
import TheTime from '../pages/TheTime.vue';
import TheManager from '../pages/TheManager.vue';
import TheProjectTasks from '../pages/TheProjectTasks.vue';
import TheProjectWiki from '../pages/TheProjectWiki.vue';
import TheProjectManager from '../pages/TheProjectManager.vue';
import TheTimeline from '../pages/TheTimeline.vue';
import TheMembership from '../pages/TheMembership.vue';
import TheProjectPipelines from "../pages/TheProjectPipelines";
import TheProjectPipelineForm from "../pages/TheProjectPipelineForm";
import TheProjectChecklists from "../pages/TheProjectChecklists";
import TheProjectChecklist from "../pages/TheProjectChecklist";
import TheChecklistTemplates from "../pages/TheChecklistTemplates";
import TheChecklistTemplate from "../pages/TheChecklistTemplate";
import TheMyChecklists from "../pages/TheMyChecklists";
import TheMyToDoes from '../pages/TheMyToDoes.vue';
import TheTasksProject from "../pages/TheTasksProject.vue";
import TheTasksSupport from "../pages/TheTasksSupport.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'app',
    },
    {
      path: '/login',
      name: 'login',
      component: AuthLogin,
      meta: {
        public: true,
        onlyWhenLoggedOut: true,
      },
    },
    {
      path: '/projects',
      name: 'projects',
      component: TheTasks,
      children: [
        {
          path: 'tasks/:taskId',
          name: 'task',
          components: {
            task: TaskModal,
          },
          meta: {
            showModal: true,
          },
        },
      ],
    },
    {
      path: '/projects_project',
      name: 'projects_project',
      component: TheTasksProject,
      children: [
        {
          path: 'tasks/:taskId',
          name: 'projects_project_task',
          components: {
            task: TaskModal,
          },
          meta: {
            showModal: true,
          },
        },
      ],
    },
    {
      path: '/projects_support',
      name: 'projects_support',
      component: TheTasksSupport,
      children: [
        {
          path: 'tasks/:taskId',
          name: 'projects_support_task',
          components: {
            task: TaskModal,
          },
          meta: {
            showModal: true,
          },
        },
      ],
    },
    {
      path: '/manager',
      name: 'manager',
      component: TheManager,
      children: [
        {
          path: 'tasks/:taskId',
          name: 'task',
          components: {
            task: TaskModal,
          },
          meta: {
            showModal: true,
          },
        },
      ],
    },
    {
      path: '/time',
      name: 'time',
      component: TheTime,
    },
    {
      path: '/checklists',
      name: 'checklists',
      component: TheChecklistTemplates,
    },
    {
      path: '/checklist/create',
      name: 'checklist_create',
      component: TheChecklistTemplate,
    },
    {
      path: '/checklists/my',
      name: 'checklists_my',
      component: TheMyChecklists,
    },
    {
      path: '/todoes/my',
      name: 'todoes_my',
      component: TheMyToDoes,
    },
    {
      path: '/checklist/update/:id',
      name: 'checklist_update',
      component: TheChecklistTemplate,
    },
    {
      path: '/timeline',
      name: 'timeline',
      component: TheTimeline,
    },
    {
      path: '/membership',
      name: 'membership',
      component: TheMembership,
    },
    {
      path: '/projects/:projectId/tasks',
      name: 'project_tasks',
      component: TheProjectTasks,
      props: true,
      children: [
        {
          path: ':taskId',
          name: 'project_task',
          components: {
            task: TaskModal,
          },
          meta: {
            showModal: true,
          },
        },
      ],
    },
    {
      path: '/projects/:projectId/wiki',
      name: 'project_wiki',
      component: TheProjectWiki,
    },
    {
      path: '/projects/:projectId/manager',
      name: 'project_manager',
      component: TheProjectManager,
    },
    {
      path: '/projects/:projectId/pipelines',
      name: 'project_pipelines',
      component: TheProjectPipelines,
    },
    {
      path: '/projects/:projectId/pipeline-create',
      name: 'project_pipeline_create',
      component: TheProjectPipelineForm,
    },
    {
      path: '/projects/:projectId/pipeline-update/:pipelineId',
      name: 'project_pipeline_update',
      component: TheProjectPipelineForm,
    },
    {
      path: '/projects/:projectId/checklists',
      name: 'project_checklists',
      component: TheProjectChecklists,
    },
    {
      path: '/projects/:projectId/checklist-update/:checklistId',
      name: 'project_checklist_update',
      component: TheProjectChecklist,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut);
  const loggedIn = !!TokenService.getToken();

  if (!isPublic && !loggedIn) {
    return next('/login');
  }

  if (to.path === '/') {
    return next('/projects');
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return next('/');
  }
  return next();
});

export default router;