<template>
    <div class="loading-status">
        <div class="loading-status__name">
            <div class="loading-status__name-text">
                {{name}}
            </div>
            <div class="loading-status__counter">
                {{tasks.length}}
            </div>
        </div>
        <div class="loading-status__tasks">
            <div class="loading-status__tasks-item loading-status__tasks-item_empty" v-if="tasks.length === 0">
                Нет задач
            </div>
            <template v-else>
                <LoadingGroupWorkerStatusTask
                        class="loading-status__tasks-item"
                        v-for="task in tasks"
                        :key="task.id"
                        :task="task"
                ></LoadingGroupWorkerStatusTask>
            </template>
        </div>
    </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import LoadingGroupWorkerStatusTask from './LoadingGroupWorkerStatusTask.vue';

  export default {
    name: "LoadingGroupWorkerStatus",
    props: {
      name: {
        type: String,
        required: true
      },
      tasks: {
        type: Array,
        required: true
      }
    },
    components: {
      LoadingGroupWorkerStatusTask
    }
  }
</script>

<style>

</style>