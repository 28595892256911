<template>
  <div class="task-index__options">
    <TaskOptionsList @close="onClose"/>
  </div>
</template>

<script>
  import TaskOptionsList from "./TaskOptionsList.vue";
  export default {
    name: "TaskOptions",
    components: {TaskOptionsList},
    methods: {
      onClose() {
        this.$emit('close');
      }
    }
  }
</script>

<style>

</style>